@import "../../assets/theme/vars";

.blog {
  &-gallery {
    &-main {
      width: 100%;
      height: 450px;
      border-radius: 1rem;
      position: relative;
      .btns {
        width: 100%;
        background: red;
        position: relative;
        .btn-show-more {
          right: -80px;
          top: -40px;
          position: absolute;
          z-index: 2;
          background: $black;
          border: .1rem solid $white;
          &::before {
            position: absolute;
            border-radius: 50px;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
        position: absolute;
      }
      .content {
        width: 100%;
        max-width: 400px;
        border-radius: 1rem;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        background: rgba(0,0,0,.8);
        padding: 1rem;
        h4 {
          color: $white;
        }
        span {
          color: $white;
          text-transform: capitalize;
        }
      }
    }
    &-content {
      margin-top: 4rem;
      .cards-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;
      }
    }
    &-lightbox {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
      padding: 1rem 0 !important;
      &-item {
        width: 200px;
        height: 120px !important;
        border-radius: 1rem;
        object-fit: cover;
        position: relative;
        opacity: .7;
        transition: .2s ease-in-out;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &-company {
    &-container {
      width: 100%;
    }
    &-main-card {
      width: 100%;
      border-radius: 1rem;
      padding: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $white;
      transition: .25s ease-in-out;
      .thumb {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1rem;
        padding: 0 1rem;
        img {
          width: 300px;
          height: 300px;
          object-fit: cover;
          box-shadow: 1rem 1rem 0 $light-gray;
          border-radius: 1rem;
          transition: .25s ease-in-out;
        }
      }
      .content {
        width: 70%;
        padding: 1rem 2rem;
        h6 {
          text-transform: capitalize;
          font-family: "is_book", sans-serif;
          margin-bottom: .5rem;
        }
        h3 {
          font-family: "is_black", sans-serif;
        }
        p {
          font-size: 1rem;
          line-height: 1.3rem;
          padding: 1rem 0 2rem 0;
        }
        .btn-show-more {
          background: rgba(0,0,0,0);
          border: .1rem solid $black;
          color: $black;
          &:hover {
            background: rgba(0,0,0,0);
            border: .1rem solid $black;
            color: $black;
          }
        }
      }
      &:hover {
        cursor: pointer;
        box-shadow: .3rem .5rem .5rem rgba(0,0,0,.1);
        .thumb {
          img {
            box-shadow: 1.25rem 1.25rem 0 $light-gray;
          }
        }
      }
    }
    &-cards {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 5rem;
      .card-empty {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        p {
          width: 100%;
          text-align: center;
          padding: 1rem;
          font-size: 1rem;
        }
      }
      .card-post {
        width: 100%;
        background: rgba(0,0,0,0);
        border-radius: 1rem;
        padding: 2rem 1rem;
        display: flex;
        margin-bottom: 2rem;
        justify-content: space-between;
        align-items: center;
        transition: .25s ease-in-out;
        .thumb {
          width: 30%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 1rem;
          padding: 0 0 1rem 0;
          img {
            width: 250px;
            height: 250px;
            object-fit: cover;
            border-radius: 1rem;
            transition: .25s ease-in-out;
          }
        }
        .content {
          width: 70%;
          padding: 1rem 2rem;
          h6 {
            text-transform: capitalize;
            font-family: "is_book", sans-serif;
            margin-bottom: .25rem;
            font-size: 1rem;
          }
          h4 {
            font-family: "is_black", sans-serif;
          }
          p {
            font-size: 1rem;
            line-height: 1.3rem;
            padding: 1rem 0 2rem 0;
          }
          .btn-show-more {
            background: rgba(0,0,0,0);
            border: .1rem solid $black;
            color: $black;
            &:hover {
              background: rgba(0,0,0,0);
              border: .1rem solid $black;
              color: $black;
            }
          }
        }
        &:hover {
          cursor: pointer;
          .thumb {
            img {
              box-shadow: 1rem 1rem 0 $light-gray;
            }
          }
        }
      }
    }
  }
  &-secondary {
    &-container {}
    &-main-card {
        width: 100%;
        background: $white;
        border-radius: 1rem;
        padding: 2rem;
        position: relative;
        display: flex;
        text-decoration: none;
        justify-content: flex-end;
        box-shadow: 0 0 .1rem rgba(0,0,0,.1);
      transition: .25s ease-in-out;
      .thumb {
          width: 630px;
          height: 400px;
          border-radius: 1rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          overflow: hidden;
          transition: .25s ease-in-out;
          img {
            width: 630px;
            height: 100%;
            object-fit: cover;
            border-radius: 1rem;
            transition: .25s ease-in-out;
          }
        }
        .content {
          width: 520px;
          max-width: 520px;
          min-height: 150px;
          max-height: max-content;
          background: $light;
          position: absolute;
          left: 2rem;
          bottom: 0;
          top: 0;
          margin: auto 0;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          transition: .25s ease-in-out;
          h6 {
            font-size: 1rem;
            color: $gray;
            padding: 1rem 0;
            font-family: "is_book", sans-serif;
          }
          h3 {
            font-size: 1.45rem;
          }
          .btn-next {
            color: $black;
            svg {
              margin-left: .5rem;
              transition: .2s ease-in-out;
            }
            &:hover {
              svg {
                margin-left: 1rem;
              }
            }
          }
        }
      &:hover {
        .thumb {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
    &-cards {
      margin-top: 4rem;
      .card-posts {
        &-secondary {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 1.5rem;
          padding: 1.5rem 0;
        }
      }
    }
    &-post {
      &-container {
        .post-single {
          &-header {
            width: 100%;
            height: 100%;
            padding: 2rem 0 2rem 2em;
            border-radius: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          &-title {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            padding: 4rem 1rem 2rem 1rem;
            p {
              color: $dark;
            }
            h3 {
              width: 60%;
              color: $dark;
              text-transform: capitalize;
              text-align: right;
              line-height: 2rem;
            }
          }
          &-thumb {
            width: 100%;
            height: 450px;
            border-radius: 1.5rem 0 0 1.5rem;
            display: flex;
            justify-content: flex-end;
            background: #00AD42;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 1.5rem 0 0 1.5rem;
            }
          }
          &-body {}
          &-content {
            width: 100%;
            padding: 2rem;
            background: $white;
            border-radius: 2rem;
            margin: -6rem 0 0 0;
            .post-content {
              .read {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .views_post {
                  line-height: 1rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  background: $white;
                  border: .1rem solid $light;
                  border-radius: .5rem;
                  padding: .25rem .5rem;
                  width: 50px;
                  height: 50px;
                  .number {
                    font-size: .8rem;
                    font-family: "is_medium", sans-serif;
                  }
                  span {
                    font-size: .75rem;
                  }
                }
              }
            }
            .comments  {
              width: 100%;
              padding: 1rem;
              border-radius: 1rem;
              background: $white;
              &-container {
                &-content {
                  .items_comment_mnj {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding: 0 1rem 1rem 1rem;
                    background: rgba(0,0,0,.03);
                    border-radius: 1rem;
                    margin-top: 1rem;
                    .user {
                      width: 100%;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      margin-top: 1rem;
                      margin-bottom: .5rem;
                      .bg-flag {
                        width: 48px;
                        height: 48px;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .flag {
                          width: 48px;
                          height: 48px;
                          position: absolute;
                          border-radius: 50px;
                        }
                        .avatar {
                          width: 48px;
                          height: 48px;
                          position: absolute;
                          border-radius: 50px;
                          img {
                            border-radius: 50px;
                            width: 48px;
                            height: 48px;
                            padding: 3px;
                          }
                        }
                      }
                      .name {
                        font-size: .9rem;
                        font-family: "is_medium", sans-serif;
                        margin-left: .5rem;
                      }
                    }
                    .drawer_comment_mnj {
                      width: 100%;
                      background: white;
                      border-radius: 1rem;
                      padding: 0.5rem 1rem;
                      .options_comment_mnj {
                        width: 100%;
                        padding-top: .5rem;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        .spandate {
                          font-size: .8rem;
                        }
                      }
                    }
                  }
                }
                &-new {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .user {
                    width: 75px;
                    height: 55px;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .contain_avatar {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      position: relative;
                      .bgflag {
                        width: 41px;
                        min-width: 41px;
                        height: 41px;
                        position: absolute;
                      }
                      .box_avatar {
                        width: 35px;
                        min-width: 35px;
                        height: 35px;
                        position: absolute;
                      }
                    }
                  }
                  .flag {
                    width: 55px;
                    height: 55px;
                    position: absolute;
                    border-radius: 100%;
                  }
                  img {
                    width: 49px;
                    height: 49px;
                    border-radius: 100%;
                    padding: .1rem;
                    background: $white;
                    position: absolute;
                  }
                }
                &-box {
                  width: 100%;
                  height: 70px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  position: relative;
                }
                &-toolbar {
                  width: 100%;
                  height: 50px;
                  display: flex;
                  justify-content: flex-end;
                  background: $white;
                  border-radius: .5rem;
                  margin-bottom: 0;
                  border: .1rem solid $light-gray;
                  position: absolute;
                  .rdw-emoji-wrapper {
                    position: unset;
                    width: 35px;
                    height: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                    img {
                      padding: 0;
                      width: 30px;
                      height: 30px;
                    }
                  }
                }
                &-editor {
                  width: 100%;
                  height: 40px;
                  line-height: .5rem;
                  margin-left: .5rem;
                  margin-right: 40px;
                  position: relative;
                  z-index: 99;
                  border-radius: .5rem;
                }
              }
              &-header {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                h5 {
                  margin-bottom: 1rem;
                }
                .user {
                  width: max-content;
                  height: 55px;
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  p {
                    margin-left: .5rem;
                    font-family: "is_medium", sans-serif;
                  }
                  .contain_avatar {
                    width: 48px;
                    min-width: 48px;
                    height: 48px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    .bgflag {
                      width: 48px;
                      min-width: 48px;
                      height: 48px;
                      position: absolute;
                      border-radius: 50px;
                    }
                    .box_avatar {
                      width: 48px;
                      min-width: 48px;
                      height: 48px;
                      border-radius: 50px;
                      position: absolute;
                      img {
                        width: 48px;
                        min-width: 48px;
                        height: 48px;
                        padding: 3px;
                        border-radius: 50px;
                        object-fit: cover;
                      }
                    }
                  }
                }
                .dropdown {
                  .btn-dots {
                    width: 50px !important;
                    min-width: 50px !important;
                  }
                  &.show {

                  }
                  &-menu {
                    transform: translate(-165px, 60px) !important;
                    border-radius: .5rem;
                    border-color: $light-gray;
                  }
                }
              }
              &-content {}
              &-container {
                &-content {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  gap: 1rem;
                  .comments {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    background: $white;
                    border-radius: 1rem;
                    &-item {
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                    }
                    &-user-flag {
                      width: 48px;
                      height: 48px;
                      position: relative;
                      border-radius: 50px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-right: 1rem;
                      .flag {
                        width: 48px;
                        height: 48px;
                        position: absolute;
                        border-radius: 50px;
                      }
                      .avatar {
                        width: 48px;
                        height: 48px;
                        position: absolute;
                        border-radius: 50px;
                        img {
                          width: 48px;
                          height: 48px;
                          padding: 3px;
                          border-radius: 50px;
                          object-fit: cover;
                        }
                      }
                    }
                    &-msgs {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding-bottom: 1rem;
                      .user {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        .author {
                          font-size: .9rem;
                          font-family: "is_medium", sans-serif;
                        }
                      }
                      .options {
                        .btn-answer {}
                        .date {
                          font-size: .9rem;
                        }
                      }
                    }
                    &-message {
                      width: 100%;
                      padding: .5rem 1rem;
                      background: $white;
                      border-radius: .5rem;
                      display: flex;
                      flex-direction: column;
                      p {
                        font-size: .95rem;
                      }
                      .date {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        font-size: .8rem;
                        padding-top: .25rem;
                      }
                    }
                  }
                }
                form {
                  .comments-container-new {

                  }
                }
              }
              &-footer {
                .likes-container {
                  width: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  padding: .1rem 0;
                  .likes-total {
                    padding-left: 1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.card-post {
  &-secondary {
    width: 270px;
    height: 390px;
    padding: 1rem;
    display: flex;
    text-decoration: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: .2s ease-in-out;
    &:hover {
      background: $white;
      border-radius: 1.5rem;
    }
  }
  &-thumb {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    img {
      width: 100%;
      height: 200px;
      border-radius: 1rem;
      object-fit: cover;
    }
  }
  &-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 0;
    h3 {
      font-size: 1.3rem;
    }
    h6 {
      font-size: .9rem;
      font-family: "is_book", sans-serif;
    }
    .btn-next {
      color: $black;
      svg {
        margin-left: .5rem;
        transition: .2s ease-in-out;
      }
      &:hover {
        svg {
          margin-left: 1rem;
        }
      }
    }
  }
}

.blog-secondary {
  &-aside {
    margin-bottom: 2rem;
    .publications-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-left: .35rem solid $black;
      border-radius: .5rem;
      padding: .5rem;
      background: $white;
      span {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-family: "is_bold", sans-serif;
        font-size: 1.2rem;
        .text {
          display: flex;
          p {
            width: 100%;
            display: flex;
            margin-left: .5rem;
            align-items: center;
            justify-content: flex-start;
            span {
              height: max-content;
              margin-left: 1rem;
              background: $light;
              border-radius: .5rem;
              padding: 0 .3rem;
              font-size: .9rem;
            }
          }
        }
      }
      .box_collapse {
        ul {
          li {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .btn-publication-white {
              width: 95% !important;
              p {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                span {
                  height: max-content;
                  margin-left: 1rem;
                  background: $light;
                  border-radius: .5rem;
                  padding: 0 .3rem;
                  font-size: .9rem;
                }
              }
              &.active {
                background: $dark;
                p {
                  color: $white;
                  span {
                    color: $dark;
                    background: $white;
                  }
                }
              }
            }
          }
        }
        &.show {}
      }
      .btn-publication-secondary { margin-bottom: .5rem; margin-top: .5rem;}
      ul {
        list-style: none;
        li {
          margin-bottom: .3rem;
          &:last-child { margin-bottom: 0}
        }
      }
    }
  }
}

.card {
  &-gallery {
    width: 300px;
    height: 180px;
    border-radius: 1rem;
    padding: 1rem;
    background: white;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 1rem;
    }
    .info {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      z-index: 3;
      color: white;
      opacity: 0;
      transform: translateY(30px);
      transition: 0.5s;
    }
  }
}

.card-gallery:hover:before {
  opacity: 1;
}
.card-gallery:hover .info {
  opacity: 1;
  transform: translateY(0px);
}
.card-gallery:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: .7;
}

.card-gallery .info h1 {
  margin: 0;
}

.card-gallery button {margin-top: .5rem}

.bg-gradient-pride {
  background: linear-gradient(180deg, #f5b84a, #ad4386, #3fa7d9, #44a59c, #f5b84a);
  background-size: 1000% 1000%;
  width: 100%;
  height: 100%;
  -webkit-animation: bg-worldpride 30s ease infinite;
  -moz-animation: bg-worldpride 30s ease infinite;
  animation: bg-worldpride 30s ease infinite;
}

@-webkit-keyframes bg-worldpride {
  0%{background-position:49% 0%}
  50%{background-position:52% 100%}
  100%{background-position:49% 0%}
}
@-moz-keyframes bg-worldpride {
  0%{background-position:49% 0%}
  50%{background-position:52% 100%}
  100%{background-position:49% 0%}
}
@keyframes bg-worldpride {
  0%{background-position:49% 0%}
  50%{background-position:52% 100%}
  100%{background-position:49% 0%}
}

.empty {
  width: 100%;
  background: $white;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  p {
    font-size: .9rem;
    font-family: "is_medium", sans-serif;
  }
}
