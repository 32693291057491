@import "../../assets/theme/vars";

.dashboard {
  padding: 1rem;
  &-footer {
    width: 200px;
    height: 50px;
    border-radius: .5rem;
    margin: 0 auto;
    background: $white;
    display: none;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem .5rem;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99;
    transition: .3s ease-in-out;
    &-menu {
     
      transition: .3s ease-in-out;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          margin-right: 1rem;
          &:last-child { margin-right:  0; }
          .rs-btn-toolbar.user {
            .rs-dropdown {
              &.btn-menu.userLink{
                .rs-dropdown-toggle {
                  background: $gradient-orange !important;
                  -webkit-background-clip: text !important;
                  -webkit-text-fill-color: transparent !important;
                }
              }
              &-toggle {
                width: 50px;
                height: 50px;
                min-width: 50px !important;
                color: $gray;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0,0,0,0);
                padding: 0;
                cursor: pointer;
                border-radius: .8rem;
                font-size: 1.75rem;
                transition: all .2s ease-in-out;
                &:hover {
                  &.user {
                    background: $gradient-orange;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
                .circle-notification {
                  width: 9px;
                  height: 9px;
                  background: $orange;
                  position: absolute;
                  display: flex;
                  border-radius: 50px;
                  top: 4px;
                  right: 8px;
                }
                @media(max-width: 768px) {
                  overflow: unset;
                  .circle-notification {
                    top: 0;
                    right: 0;
                  }
                }
              }
              &-toggle-caret {
                display: none;
              }
              &-open {
                &.btn-menu.user {
                  .rs-dropdown-toggle {
                    background: $gradient-green;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
                &-toggle {
                  color: $dark;
                }
              }
              &-menu {
                top: -180px;
                padding: .5rem;
                box-shadow: 0 0 .3rem rgba(0,0,0,.1);
                .rs-dropdown-item {
                  margin: 0;
                  .rs-dropdown-item-content{
                    width: 100%;
                    min-width: 180px;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    padding: .5rem 1rem;
                    align-items: center;
                    background: $white;
                    color: $dark;
                    border: none;
                    border-radius: .5rem;
                    text-decoration: none;
                    transition: all .2s ease-in-out;
                    .sublinks {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      color: $dark;
                      text-decoration: none;
                      font-size: 14px;
                      transition: all .2s ease-in-out;
                      i {
                        width: 30px;
                        height: 30px;
                        opacity: 1;
                        display: flex;
                        transform: unset;
                        justify-content: space-evenly;
                        align-items: center;
                        background: $light;
                        border-radius: 25px;
                        margin-right: .5rem;
                        transition: .2s ease-in-out;
                      }
                      p {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: $dark;
                        font-size: .9rem;
                        text-decoration: none;
                        @media(max-width: 575px) {
                          font-size: .75rem;
                        }
                        .notification {
                          width: 20px;
                          height: max-content;
                          font-family: "is_medium", sans-serif;
                          background: $light;
                          border-radius: 50px;
                          margin-left: .5rem;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        }
                      }
                    }
                    i {
                      opacity: 0;
                      transform: translate(-.5rem,0);
                      transition: .1s ease-in-out;
                    }
                  }
                  &.user {
                    &:hover {
                      border-radius: .5rem;
                      background: $light;
                      .rs-dropdown-item-content{
                        background: $light;
                        .sublinks {
                          p {
                            color: $dark;
                            font-size: .9rem;
                            @media(max-width: 575px) {
                              font-size: .75rem;
                            }
                          }
                          i {
                            color: $white;
                            transform: unset;
                            background: $gradient-orange;
                          }
                        }
                        i {
                          opacity: 1;
                          transform: translate(0,0);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .rs-btn-toolbar.categories {
            .rs-dropdown {
              &.btn-menu.categoriesLink{
                .rs-dropdown-toggle {
                  background: $gradient-red !important;
                  -webkit-background-clip: text !important;
                  -webkit-text-fill-color: transparent !important;
                }
              }
              &-toggle {
                width: 50px;
                height: 50px;
                min-width: 50px !important;
                color: $gray;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0,0,0,0);
                padding: 0;
                cursor: pointer;
                border-radius: .8rem;
                font-size: 1.75rem;
                transition: all .2s ease-in-out;
                &:hover {
                  &.categories {
                    background: $gradient-red;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
                .circle-notification {
                  width: 9px;
                  height: 9px;
                  background: $red;
                  position: absolute;
                  display: flex;
                  border-radius: 50px;
                  top: 6px;
                  right: 6px;
                }
                @media(max-width: 768px) {
                  overflow: unset;
                  .circle-notification {
                    top: 0;
                    right: 0;
                  }
                }
              }
              &-toggle-caret {
                display: none;
              }
              &-open {
                &.btn-menu.categories {
                  .rs-dropdown-toggle {
                    background: $gradient-red;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                  &:hover {
                    color: $dark;
                  }
                }
                &-toggle {
                  color: $dark;
                  &:hover {
                    color: $dark;
                  }
                }
              }
              &-menu {
                top: -180px;
                padding: .5rem;
                box-shadow: 0 0 .3rem rgba(0,0,0,.1);
                .rs-dropdown-item {
                  margin: 0;
                  .rs-dropdown-item-content{
                    width: 100%;
                    min-width: 180px;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    padding: .5rem 1rem;
                    align-items: center;
                    background: $white;
                    color: $dark;
                    border: none;
                    border-radius: .5rem;
                    text-decoration: none;
                    transition: all .2s ease-in-out;
                    .sublinks {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      color: $dark;
                      text-decoration: none;
                      font-size: 14px;
                      transition: all .2s ease-in-out;
                      i {
                        width: 30px;
                        height: 30px;
                        opacity: 1;
                        display: flex;
                        transform: unset;
                        justify-content: space-evenly;
                        align-items: center;
                        background: $light;
                        border-radius: 25px;
                        margin-right: .5rem;
                        transition: .2s ease-in-out;
                      }
                      p {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: $dark;
                        font-size: .9rem;
                        text-decoration: none;
                        @media(max-width: 575px) {
                          font-size: .75rem;
                        }
                        .notification {
                          width: 20px;
                          height: max-content;
                          font-family: "is_medium", sans-serif;
                          background: $light;
                          border-radius: 50px;
                          margin-left: .5rem;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        }
                      }
                    }
                    i {
                      opacity: 0;
                      transform: translate(-.5rem,0);
                      transition: .1s ease-in-out;
                    }
                  }
                  &.categories {
                    &:hover {
                      border-radius: .5rem;
                      background: $light;
                      .rs-dropdown-item-content{
                        background: $light;
                        .sublinks {
                          p {
                            color: $dark;
                            font-size: .9rem;
                            text-decoration: none;
                            @media(max-width: 575px) {
                              font-size: .75rem;
                            }
                          }
                          i {
                            color: $white;
                            transform: unset;
                            background: $gradient-red;
                          }
                        }
                        i {
                          opacity: 1;
                          transform: translate(0,0);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .rs-btn-toolbar.community {
            .rs-dropdown {
              transition: .2s ease-in-out;
              &.btn-menu.communityLink{
                .rs-dropdown-toggle {
                  background: $gradient-green !important;
                  -webkit-background-clip: text !important;
                  -webkit-text-fill-color: transparent !important;
                }
              }
              &-toggle {
                width: 50px;
                height: 50px;
                min-width: 50px !important;
                color: $gray;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0,0,0,0);
                padding: 0;
                cursor: pointer;
                border-radius: .8rem;
                font-size: 1.75rem;
                transition: all .2s ease-in-out;
                &:hover {
                  &.community{
                    background: $gradient-green;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
              }
              &-toggle-caret {
                display: none;
              }
              &-open {
                transition: .2s ease-in-out;
                .rs-dropdown-menu {
                  width: 200px;
                  top: -130px;
                  transition: .2s ease-in-out;
                }
                &.btn-menu.community {
                  .rs-dropdown-toggle {
                    background: $gradient-green;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                  &:hover {
                    color: $dark;
                  }
                }
              }
              &-menu {
                width: 0;
                top: 0;
                padding: .5rem;
                box-shadow: 0 0 .3rem rgba(0,0,0,.1);
                transition: all .2s ease-in-out;
                .rs-dropdown-item {
                  margin: 0;
                  .rs-dropdown-item-content{
                    width: 100%;
                    min-width: 180px;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    padding: .5rem 1rem;
                    align-items: center;
                    background: $white;
                    color: $dark;
                    border: none;
                    border-radius: .5rem;
                    text-decoration: none;
                    transition: all .2s ease-in-out;
                    .sublinks {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      color: $dark;
                      text-decoration: none;
                      font-size: 14px;
                      transition: all .2s ease-in-out;
                      i {
                        width: 30px;
                        height: 30px;
                        opacity: 1;
                        display: flex;
                        transform: unset;
                        justify-content: space-evenly;
                        align-items: center;
                        background: $light;
                        border-radius: 25px;
                        margin-right: .5rem;
                        transition: .2s ease-in-out;
                      }
                      p {
                        color: $dark;
                        font-size: .9rem;
                        text-decoration: none;
                        @media(max-width: 575px) {
                          font-size: .75rem;
                        }
                      }
                    }
                    i {
                      opacity: 0;
                      transform: translate(-.5rem,0);
                      transition: .1s ease-in-out;
                    }
                  }
                  &.community {
                    &:hover {
                      border-radius: .5rem;
                      background: $light;
                      .rs-dropdown-item-content{
                        background: $light;
                        .sublinks {
                          p {
                            color: $dark;
                            font-size: .9rem;
                            text-decoration: none;
                            @media(max-width: 575px) {
                              font-size: .75rem;
                            }
                          }
                          i {
                            color: $white;
                            transform: unset;
                            background: $gradient-green;
                          }
                        }
                        i {
                          opacity: 1;
                          transform: translate(0,0);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .rs-btn-toolbar.dashboard {
            width: 50px;
            height: 50px;
            min-width: 50px !important;
            color: $gray;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0,0,0,0);
            padding: 0;
            cursor: pointer;
            border-radius: .8rem;
            font-size: 1.75rem;
            transition: all .2s ease-in-out;
            &:hover {
              &.dashboard {
                color: $gradient-blue;
              }
            }
          }
          .rs-btn-toolbar.btn-menu.dashboardLink {
            color: $black;
          }
          .rs-btn-toolbar.admin {
            width: 50px;
            height: 50px;
            min-width: 50px !important;
            color: $gray;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0,0,0,0);
            padding: 0;
            cursor: pointer;
            border-radius: .8rem;
            font-size: 1.75rem;
            transition: all .2s ease-in-out;
            &:hover {
              &.dashboard {
                color: $gradient-blue;
              }
            }
          }
          .rs-btn-toolbar.adminLink {
            color: $blue;
          }
          .rs-dropdown {
            &-menu {
              left: -75px !important;
            }
          }
        }
      }
      .btn-menu {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 50px;
        text-align: center;
        height: 40px;
        border-radius: .5rem;
        background: $light !important;
        color: $dark;
        font-size: 2rem;
        border: 0;
        margin: 0 auto;
        .line-1 {
          width: 100%;
          height: 2px;
          background: $dark;
          margin-bottom: .5rem;
          border-radius: 25px;
        }
        .line-2 {
          width: 100%;
          height: 2px;
          background: $dark;
          margin-bottom: .5rem;
          border-radius: 25px;
        }
        .line-3 {
          width: 100%;
          height: 2px;
          background: $dark;
          border-radius: 25px;
        }
      }
    }
    &-user {
      width: 20%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      transition: .3s ease-in-out;
      .button-user {
          width: 100px;
          min-width: 100px;
          height: 50px;
          display: flex;
          position: relative;
          border-radius: 50px;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          background: $white;
          border: none;
          padding: 0;
          box-shadow: 0 0 .2rem rgba(0,0,0,.1);
          transition: .3s ease-in-out;
          .quantity {
            position: absolute;
            z-index: 2;
            color: $dark;
            background: $light;
            border-radius: 0.25rem;
            width: 25px;
            max-width: 30px;
            top: 3px;
            font-size: 0.8rem;
            line-height: 0;
            right: -16px;
            left: unset;
            height: 15px;
            font-family: "is_bold", sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .avatar {
            border-radius: 50px;
            padding: .2rem;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            .rotating {
              width: 40px;
              height: 40px;
              border-radius: 100%;
              position: absolute;
            }
            img {
              width: 36px;
              height: 36px;
              border-radius: 100%;
              background: $white;
              padding: .1rem;
              object-fit: cover;
              position: absolute;
            }
          }
          &:focus, &:hover {
            background: $light !important;
          }
        .rs-dropdown-toggle {
          width: 100%;
          height: 50px;
          overflow: unset;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          border-radius: 25px;
          .rs-dropdown-toggle-caret {
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin: 0 0 0 2rem;
            font-size: 1.5rem;
            position: relative;
            margin-bottom: .8rem;
          }
        }
        .rs-dropdown-menu {
            width: 200px;
            right: unset;
            top: -150px;
            left: 0;
            padding: .2rem;
            .rs-dropdown-item {
              text-decoration: none;
              .rs-dropdown-item-content {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background: $white;
                color: $gray;
                border-radius: .5rem;
                transition: .2s ease-in-out;
                i {
                  width: 25px;
                  height: 25px;
                  border-radius: 50px;
                  margin-right: .5rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                p {
                  font-size: 1rem;
                  transition: .2s ease-in-out;
                  @media (max-width: 575px) {
                    font-size: .9rem;
                  }
                }
                &:hover {
                  background: $light;
                  p {
                    transform: translate(.3rem,0);
                  }
                }
              }
            }
          }
        }
      .rs-dropdown.dropdown-notifications {
        width: 100%;
        .rs-dropdown-toggle {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background: $white;
          border-radius: 0.5rem;
          padding: 8px 12px;
          clear: both;
          line-height: 1.42857143;
          color: $gray;
          white-space: nowrap;
          cursor: pointer;
          transition: 0.2s ease-in-out;
          .rs-dropdown-toggle-caret {
            display: none;
          }
          i {
            width: 25px;
            height: 25px;
            border-radius: 50px;
            margin-right: .5rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          p {
            font-size: 1rem;
            transition: .2s ease-in-out;
            @media (max-width: 575px) {
              font-size: .9rem;
            }
          }
          &:hover {
            background: $light;
            p {
              transform: translate(.3rem,0);
            }
          }
        }
        .rs-dropdown-menu {
          right: unset;
          left: -20rem;
          top: 0;
          z-index: 999;
          width: 300px;
          padding: 0;
          .rs-dropdown-item-submenu {
            .rs-dropdown-item-content {
              padding: 0;
              .rs-dropdown-menu-toggle {
                span {
                  .notifications-body  {
                    background: $white;
                    box-shadow: 0 0 .5rem rgba(0,0,0,.1);
                    width: 300px;
                    position: relative;
                    padding: 0;
                    border-radius: 1rem;
                    .notifications-title {
                      width: 100%;
                      height: 50px;
                      font-family: "is_bold", sans-serif;
                      font-size: 1.2rem;
                      background: $light;
                      border-radius: 1rem 1rem 0 0;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      padding: .5rem 1rem 1rem 1rem;
                    }
                    .notifications-content {
                      width: 100%;
                      height: 400px;
                      overflow-y: auto;
                      padding: .5rem;
                      border-radius: 1rem;
                      background: $white;
                      margin-top: -10px;
                      display: flex;
                      margin-left: 0;
                      list-style: none;
                      flex-direction: column;
                      &::-webkit-scrollbar  {
                        width: .2rem;
                        border-radius: 50px;
                      }
                      &::-webkit-scrollbar-thumb {
                        box-shadow: inset 0 0 6px $dark;
                        border-radius: 50px;
                      }
                      .notifications-content-item {
                        margin-bottom: 0.5rem;
                        background: $light;
                        border: .1rem solid $light;
                        padding: .5rem;
                        border-radius: 0.5rem;
                        transition: .2s ease-in-out;
                        &:hover {
                          background: $light-gray;
                        }
                        a {
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: flex-start;
                          text-decoration: none;
                          color: $dark;
                          p {
                            font-size: .9rem;
                            line-height: .9rem;
                          }
                          span {
                            width: unset;
                            height: unset;
                            font-size: .8rem;
                            font-family: "is_medium", sans-serif;
                            line-height: .8rem;
                            padding: .1rem .1rem .3rem .1rem;
                            background: rgba(0,0,0,0);
                          }
                        }
                        &:first-child {
                          margin-top: 1rem;
                        }
                        &:last-child {
                          margin-bottom: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &-footer-home {
    width: 100%;
    background: rgba(0,0,0,0);
    ul {
      width: 100%;
      min-height: 50px;
      display: flex;
      align-items: center;
      list-style: none;
      justify-content: flex-start;
      margin-top: 62px;
      li {
        margin-right: 1rem;
        &:last-child { margin-right: 0}
        a {
          text-decoration: none;
          color: $dark;
          font-size: .9rem;
          transition: .2s ease-in-out;
          &:hover {
            color: $gray;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
