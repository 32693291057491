@media (max-width: 1560px) {
  .dashboard {
    &-side {
      &-center {
        width: calc(100% - 700px);
        margin: 0 auto;
        padding-left: 0.5rem;
      }
    }
  }
}

@media (max-width: 1280px) {
  .course-view-title {
    margin-top: 4rem;
  }
  /*reards*/
  .rewards {
    &-account {
      padding: 1rem;
      &-content {
        justify-content: center;
        flex-direction: column;
      }
      &-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      &-cards {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      &-btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
      }
      &-card {
        margin: 0 auto;
      
      }
    }
  }
  /*rewards*/

  /*login*/
  .form-register {
    width: 95%;
    &-title {
      padding: 0 1rem;
    }
    .form-dashboard {
      padding-right: 0;
      padding-left: 0;
    }
    h5 {
      padding-bottom: 1rem;
    }
    &-form {
      padding: 0;
      margin: 0 auto;
    }
 
  }
  /**login/

  /*calendar*/
  .diary {
    &-slider {
      margin-bottom: 2rem;
    }
  }
  /*ends calendar*/

  /*direcory*/
  .card-directory-detail {
    .thumb {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .info {
      .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tags {
          width: max-content;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .category {
            background: $green;
            border-radius: 0.5rem;
            padding: 0.2rem 0.3rem;
            color: $white;
            font-size: 0.9rem;
          }
          .subcategory {
            background: $light;
            font-size: 0.8rem;
            padding: 0.2rem 0.3rem;
            border-radius: 0.5rem;
            margin-top: -0.2rem;
          }
        }
      }
      .content {
        .data {
          flex-direction: column;
        }
      }
    }
  }
  /*en direcotye*/

  /*start interest grup*/
  .interest-group {
 
    &-header {
      height: 250px;
      .imgs {
        height: 300px;
        img {
          height: 250px;
          position: absolute;
        }
      }
      .title {
        padding: 0.5rem;
       
      }
    }
    &-cards {
      justify-content: space-evenly;
      gap: 1rem;
    }
  }
  /*end interest group*/

  /*start pride music*/
  .card-pride-music {
    width: 45%;
  }

  /*start pridetalks*/
  .card-audience {
    max-width: 250px;
    height: 350px;
    position: relative;
    padding: 0 .5rem .5rem .5rem;
 
    .info {
      width: 100%;
      height: auto;
      padding: 0.5rem 0 0 0;
      flex-direction: column;
      h5 {
        font-family: "is_bold", sans-serif;
        white-space: pre-wrap;
        font-size: 18px;
      }
      p {
        padding: 0.3rem 0;
        font-size: 0.8rem;
        &.badge-audience {
          font-size: 0.8rem;
        }
      }
  
    }
    .thumbnail {
      width: 100%;
      height: 150px;
      border-radius: 1rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
        transition: 0.2s ease-out;
      }
    }
  }
  /*end pridetalks*/

  /*START DASHBOARD*/
  .dashboard {
    &-container {
      &-page {
        padding-top: 3rem;
        padding-left: .5rem;
        padding-right: .5rem;
      }
    }
    &-side {
      &-center {
        padding-top: 6rem;
        width: calc(100% - 500px);
      }
      &-left {
        padding-top: 5rem;
        max-width: 210px;
      }
      &-right {
        padding-top: 4rem;
        max-width: 210px;
      }
    }
  }
  /*END DASHBOARD*/

  /*START HEADER DASHBOARD*/
  .dashboard {
    &-header {
      height: 60px;
      padding: 1rem 1rem;
      &-brand {
        a {
          img {
            width: 150px;
          }
        }
        .doodle {
          &-info {
            p {
              font-size: 0.8rem;
            }
          }
        }
      }
      &-user {
        .btn-menu {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          min-width: unset !important;
          width: 30px !important;
          text-align: center;
          height: 50px !important;
          border-radius: 0.5rem !important;
          background: $light !important;
          color: $dark;
          font-size: 2rem;
          border: 0;
          .line-1 {
            width: 100%;
            height: 2px;
            background: $dark;
            margin-bottom: 0.5rem;
            border-radius: 25px;
          }
          .line-2 {
            width: 90%;
            height: 2px;
            background: $dark;
            margin-bottom: 0.5rem;
            border-radius: 25px;
          }
          .line-3 {
            width: 80%;
            height: 2px;
            background: $dark;
            border-radius: 25px;
          }
          &:hover {
            box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
          }
        }
  

      }
    }
  }
  .drawer-particles {
    width: 100px;
    height: 60px;
    left: 9px;
  }
  /* END HEADER DASHBOARD*/

  /* START MUNDO PRIDE */
  .blog {
    &-gallery {
      &-main {
        width: 100%;
        height: 450px;
        border-radius: 1rem;
        position: relative;
        .btns {
          width: 100%;
          background: red;
          position: relative;
          .btn-show-more {
            right: -80px;
            top: -40px;
            position: absolute;
            z-index: 2;
            background: $black;
            border: 0.1rem solid $white;
            &::before {
              position: absolute;
              border-radius: 50px;
            }
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 1rem;
          position: absolute;
        }
        .content {
          border-radius: 1rem;
          position: absolute;
          bottom: 1rem;
          left: 1rem;
          background: rgba(0, 0, 0, 0.8);
          padding: 1rem;
          h4 {
            color: $white;
          }
          span {
            color: $white;
          }
        }
      }
      &-content {
        margin-top: 4rem;
        .cards-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          gap: 2rem;
        }
      }
    }
    &-company {
    
      &-main-card {
        flex-direction: column;
        .thumb {
          width: 300px;
          margin-bottom: 1rem;
        }
        .content {
          width: 100%;
        }
      }
    }
    &-secondary {
      &-main-card {
        width: 100%;
        background: $white;
        border-radius: 1rem;
        padding: 2rem;
        position: relative;
        box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
        .thumb {
          width: 100%;
          height: 100%;
          border-radius: 1rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          img {
            width: 630px;
            height: 100%;
            border-radius: 1rem;
            object-fit: cover;
          }
        }
        .content {
          width: 420px;
          max-width: 420px;
          height: 330px;
          background: $light;
          position: absolute;
          left: 2rem;
          bottom: 0;
          top: 0;
          margin: auto 0;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          h6 {
            font-size: 1rem;
            color: $gray;
          }
          h3 {
            font-size: 1.45rem;
          }
          p {
            padding: 1rem 0;
          }
          .btn-next {
            color: $black;
            svg {
              margin-left: 0.5rem;
              transition: 0.2s ease-in-out;
            }
            &:hover {
              svg {
                margin-left: 1rem;
              }
            }
          }
        }
      }
      &-cards {
        margin-top: 4rem;
        .card-posts {
          &-secondary {
            justify-content: space-evenly;
            flex-wrap: wrap;
          }
        }
        .card-post {
          &-secondary {
            width: 250px;
          }
        }
      }
      &-post {
        &-container {
          .post-single {
            &-header {
              width: 100%;
              height: 100%;
              padding: 2rem 0 2rem 2em;
              border-radius: 1rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            &-title {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: flex-start;
              padding: 1rem 1rem 2rem 1rem;
              p {
                color: $dark;
              }
              h3 {
                width: 100%;
                color: $dark;
                text-transform: capitalize;
                text-align: right;
                line-height: 2rem;
              }
            }
            &-thumb {
              width: 1200px;
              height: 350px;
              border-radius: 1.5rem 0 0 1.5rem;
              display: flex;
              justify-content: flex-end;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 1.5rem 0 0 1.5rem;
              }
            }
          
            &-content {
              width: 100%;
              padding: 2rem;
              background: $white;
              border-radius: 2rem;
              margin: -6rem 0 0 0;
            }
          }
        }
      }
    }
  }
  /* END MUDNO PRIDE*/
}

@media (max-width: 1180px) {
  .dashboard-side-left {
    display: none;
  }
  .dashboard-side-center {
    width: calc(100% - 245px);
    margin: 0;
  }
  .dashboard-side-right {
  }
}

@media (max-width: 992px) {
  .form-dashboard {
    padding: 0.5rem;
  }
  /*provider*/
  .item-promo {
    width: 250px;
    max-width: 250px;
  }

  .dashboard-side-left {
    max-width: 400px;
  }

  /*reards*/
  .rewards {
    &-account {
      margin-bottom: 2rem;
      &-content {
        flex-direction: row;
        justify-content: space-between;
      }

      &-cards {
        justify-content: center;
      }
      &-btns {
        margin-top: 0;
      }
      &-card {
        margin: unset;
      }
    }
  }
  /*rewards*/

  /*login*/
  /*login*/
  .register {
    &-container {
      flex-direction: column;
      &-img {
        width: 100%;
        min-height: 200px;
        border-radius: 0 0 3rem 3rem;
        .bg-gradients {
          width: 100%;
          height: 200px;
          border-radius: 0 0 3rem 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          h1 {
            margin: unset;
            position: unset;
          }
          .login-footer-legals {
            display: none;
          }
        }
      }
      &-form {
        width: 100%;
        &-header {
          justify-content: center;
        }
        &-social-media {
          flex-direction: column;
          height: max-content;
          margin-top: 2rem;
          .login-footer-legals {
            width: 100%;
            display: flex !important;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
            margin-top: 1rem;
            & ul {
              width: max-content;
              margin: 0 auto;
              display: flex;
              list-style: none;
              li {
                margin-right: 1rem;
                &:last-child {
                  margin-right: 0;
                }
                a {
                  width: max-content;
                  height: max-content;
                  text-decoration: none;
                  border-radius: unset;
                  background: rgba(0, 0, 0, 0);
                  color: $dark;
                  font-size: 0.9rem;
                  transition: 0.25s ease-in-out;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /*login*/

  /*direcory*/
  .card-directory-detail {
    flex-direction: column;
    .thumb {
      max-width: 100%;
      // .data-detail {}
      // .img-detail {}
    }
    .info {
      .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tags {
          width: max-content;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .category {
            background: $green;
            border-radius: 0.5rem;
            padding: 0.2rem 0.3rem;
            color: $white;
            font-size: 0.9rem;
          }
          .subcategory {
            background: $light;
            font-size: 0.8rem;
            padding: 0.2rem 0.3rem;
            border-radius: 0.5rem;
            margin-top: -0.2rem;
          }
        }
      }
    }
  }
  /*en direcotye*/

  /*start job bank*/
  .job-bank {

    &-header {
      height: auto;
      justify-content: center;
      flex-direction: column-reverse;
      border-radius: 1rem;
      position: relative;
      &-info {
        width: 100%;
        flex-direction: column;
        justify-content: center;
      }
      &-thumb {
        position: relative;
      }
      .footer-btn {
        margin-top: 0;
        justify-content: flex-end;
      }
    }
    &-btns {
      width: 100%;
      margin-bottom: 2rem;
    }
    &-cards {
      justify-content: space-evenly;
      gap: 1rem;
    }
    &-options {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .counter {
        width: 100%;
        span {
          background: $green;
          color: $white;
          padding: 0.3rem 0.5rem;
          border-radius: 0.3rem;
          font-family: "is_medium", sans-serif;
        }
        p {
          font-family: "is_medium", sans-serif;
        }
      }
      .filters {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        label {
          width: 120px;
          font-family: "is_medium", sans-serif;
        }
        select {
          width: 100%;
          min-width: 150px;
          height: 40px;
          border-radius: 0.5rem;
          border: 0.1rem solid $light-gray;
          padding: 0.3rem 1rem;
          font-size: 1rem;
          color: $dark;
          transition: 0.2s ease-in-out;
          &:hover {
            border-color: $light-gray;
            box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.1);
          }
          &:focus {
            border-color: $light-gray;
            outline: 0;
          }
        }
      }
    }
    &-categories {
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      &-content {
        width: 100%;
        flex-wrap: wrap;
        li {
          margin-bottom: 0.5rem;
          
        }
      }
    }
  }
  /*end deatils job*/

  /*start interest grup*/
  .interest-group {
   
    &-header {
      height: 250px;
      .imgs {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        height: 100%;
        img {
          bottom: 0;
          width: auto;
          height: 180px;
          position: relative;
        }
      }
      .title {
        padding: 0.5rem;
      
      }
    }
    &-cards {
      justify-content: space-evenly;
      gap: 1rem;
    }
  }
  /*end interest group*/

  /*start emprendores*/
  .register-services {
    &-aside {
      width: 100%;
      background: $white;
      height: max-content;
      padding: 2rem;
      border-radius: 1rem;
      margin-bottom: 2rem;
    }
  }
  /*end emprendedores*/

  /*starts pride music*/
  .cards-pride-music {
    width: 100%;
    .card-pride-music {
      width: 100%;
      max-width: 450px;
      margin: 0 auto;
    }
  }
  .pride-music {
    &-header {
      height: auto;
    }
    &-content {
      background-position: center;
      flex-direction: column;
    }
    &-info {
      flex-direction: column;
     
    }
    &-img {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 200px;
        position: relative;
      }
    }
  }
  /*end pride music*/

  /* START MUNDO PRIDE */
  .blog {
    &-gallery {
      &-main {
        width: 100%;
        height: 450px;
        border-radius: 1rem;
        position: relative;
        .btns {
          width: 100%;
          background: red;
          position: relative;
          .btn-show-more {
            right: -80px;
            top: -40px;
            position: absolute;
            z-index: 2;
            background: $black;
            border: 0.1rem solid $white;
            &::before {
              position: absolute;
              border-radius: 50px;
            }
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 1rem;
          position: absolute;
        }
        .content {
          border-radius: 1rem;
          position: absolute;
          bottom: 1rem;
          left: 1rem;
          background: rgba(0, 0, 0, 0.8);
          padding: 1rem;
          h4 {
            color: $white;
          }
          span {
            color: $white;
          }
        }
      }
      &-content {
        margin-top: 4rem;
        .cards-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          gap: 2rem;
          .card-gallery {
            width: 250px;
          }
        }
      }
    }
    &-company {
    
      &-main-card {
        .thumb {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .content {
          width: 100%;
          p {
          }
        }
      }
      &-cards {
        .card-post {
          padding: 1rem;
          .thumb {
            width: 50%;
            img {
            }
          }
          .content {
            padding: 0.5rem 1.5rem;
            h6 {
              font-size: 1rem;
            }
            h4 {
              font-size: 1.3rem;
              margin-bottom: 1rem;
            }
            p {
              display: none;
            }
          }
        }
      }
    }
    &-secondary {
   
      &-main-card {
        
        .content {
          width: 320px;
          max-width: 320px;
          height: 320px;
          padding: 1rem;
          h6 {
          }
          h3 {
          }
          p {
          }
          .btn-next {
            height: 35px !important;
          }
        }
      }

      &-post {
        &-container {
          .post-single {
            &-header {
              flex-direction: column;
            }
         
            &-thumb {
              width: 100%;
              border-radius: 1.5rem 1.5rem 0 0;
              img {
                border-radius: 1.5rem 1.5rem 0 0;
              }
            }
        
          }
        }
      }
    }
  }
  .blog-worldpride {
  
    &-content {
      &-post {
        .comments-container {
          margin-top: 2rem;
        }
        .comments {
          &-container {
            &-box {
              width: 100%;
              height: 70px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: relative;
            }
            &-toolbar {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: flex-end;
              background: $white;
              border-radius: 0.5rem;
              margin-bottom: 0;
              border: 0.1rem solid $light-gray;
              position: absolute;
              .rdw-emoji-wrapper {
                position: unset;
                width: 35px;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                img {
                  padding: 0;
                  width: 30px;
                  height: 30px;
                }
              }
            }
            &-editor {
              width: 100%;
              height: 40px;
              line-height: 0.5rem;
              margin-left: 0.5rem;
              margin-right: 40px;
              position: relative;
              z-index: 99;
              border-radius: 0.5rem;
            }
          }
        }
      }
  
    }
    &-aside {
      margin-top: 2rem;
    }
  }
  /* END MUDNO PRIDE*/

  /*START orgullo lgbtq*/
  .blog-secondary {
    &-post-container {
     
      .post-single-content {
        margin: -3rem 0 0 0;
      }
    }
  }
  /*END ORGULLO LGBTQ+*/

  /*START DASHBOARD*/
  .dashboard {
    &-side {
      &-center {
        width: 90%;
        margin: 0 auto;
        padding: 6rem 0 0 0;
      }
      &-center.viewing {
        display: flex;
        .dashboard-side-left {
          &.show {
            display: flex;
            flex-direction: column;
            .interests-group-container {
              width: 400px;
            }
          }
          &.hide {
            display: none;
          }
          // display: none;
        }
        .dashboard-side-right {
          &.show {
            display: flex !important;
            flex-direction: column;
          }
          &.hide {
            display: none;
          }
        }
      }
      &-right {
        display: none !important;
      }
    }
  }
  /*END DASHBOARD*/

  /*START HEADER DASHBOARD*/
  .dashboard {
    &-header {
      padding: 0.5rem;
    }
  }
  .drawer-particles {
    left: 12px;
  }
  /* END HEADER DASHBOARD*/

  /*START FOOTER DASHBOARD*/
  .container-chat {
    width: 100px;
    position: absolute;
    top: 5px;
    right: 10px;
  }
  .dashboard {
    &-footer {
      width: 99%;
      left: 0;
      right: 0;
      bottom: 0.5rem;
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
      justify-content: space-between;
      &-menu {
        width: 1000%;
        ul {
          li {
            .rs-btn-toolbar.dashboard {
              height: 40px;
            }
            .btn-menu {
              height: 40px;
              font-size: 1.5rem !important;
              background: rgba(0, 0, 0, 0) !important;
              .btn-menu {
                height: 40px;
                .rs-dropdown-toggle {
                  font-size: 1.5rem !important;
                }
              }
            }
          }
        }
      }
      &-user {
        .button-user {
          height: 40px;
          .rs-dropdown-toggle {
            height: 40px;
          }
        }
        .rs-dropdown.dropdown-notifications {
          width: 100%;
          .rs-dropdown-toggle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background: $white;
            border-radius: 0.5rem;
            padding: 8px 12px;
            clear: both;
            line-height: 1.42857143;
            color: $gray;
            white-space: nowrap;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            .rs-dropdown-toggle-caret {
              display: none;
            }
            i {
              width: 25px;
              height: 25px;
              border-radius: 50px;
              margin-right: 0.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            p {
              font-size: 1rem;
              transition: 0.2s ease-in-out;
              @media (max-width: 575px) {
                font-size: 0.9rem;
              }
            }
            &:hover {
              background: $light;
              p {
                transform: translate(0.3rem, 0);
              }
            }
          }
          .rs-dropdown-menu {
            left: 200px;
            top: -400px;
          }
        }
     
      }
      .chat-container {
        &-header {
          .button-chat {
            max-width: 90px;
            box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
            .btn-expand {
              display: none;
            }
          }
        }
        &.open {
          bottom: 45px;
          .chat-container-header {
            .button-chat {
              max-width: unset;
              .btn-expand {
                display: flex;
              }
            }
          }
        }
      }
    }
    &-footer-home {
      padding-bottom: 60px;
      ul {
        justify-content: center;
      }
    }
  }
  /* END FOOTER DASHBOARD*/

  /*start profile */
  .tabs-user {
    ul.nav-tabs {
      li.nav-item {
        .nav-link {
          height: 100px;
          p {
            font-size: 0.9rem;
            line-height: 0.9rem;
            margin-top: 0.5rem;
          }
          span {
            width: 40px;
            height: 40px;
            font-size: 1.4rem;
          }
        }
      }
    }

  }
  /* end profile */

  /*cartelera*/
  .course-view {
 
    &-header {
      height: auto;
      flex-direction: column;
      justify-content: center;
      .titles {
        text-align: center;
      }
      .info {
        margin-top: 1rem;
        p {
          font-size: 0.85rem;
        }
      }
    }
    &-alternative {
      margin-bottom: 2rem;
      height: 300px;
    }
    &-scroll {
      justify-content: space-evenly;
      .card-billboard-btn {
        .card-billboard {
          width: 200px;
          height: 250px;
        }
      }
    }
   
  }
  .card-billboard {
    cursor: pointer;
    &-body {
      cursor: default;
      padding: 1rem;
    }
 
    &-title {
      h3 {
        font-size: 1.2rem;
        line-height: 1.2rem;
      }
    }
    &-description {
      padding: 0.5rem 0;
      p {
        font-size: 0.9rem;
      }
    }
  }
  /*end cartelera*/

  .profile {
    padding: 1rem;
  
    &-info {
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      h4 {
        text-align: center;
      }
  
      &-extra {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
  /* end profile */

  /*pridetalks*/
  .audience {
    &-container {
      .dots {
        width: 30px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        transition: 0.2s ease-in-out;
        &:hover,
        &:focus {
          background: $dark;
          color: $white;
        }
      }
      .aside-lateral {
        position: fixed;
        width: 250px;
        right: -100%;
        top: 72px;
        bottom: 60px;
        padding: 0.5rem;
        background: $white;
        overflow-y: auto;
        border-radius: 1rem;
        transition: 0.2s ease-in-out;
        &.is-show {
          display: flex;
          right: 8px;
          transition: 0.2s ease-in-out;
        }
        .btn-close {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          border-radius: 50px;
          background: $dark;
          color: $white;
          position: absolute;
          top: 0.5rem;
          left: 0.5rem;
          z-index: 1;
          opacity: 1;
          font-size: 1.25rem;
          transition: 0.2s ease-in-out;
          &:hover {
            background: $white;
            color: $dark;
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
          }
        }
       
      }
    }
    &-header {
      &-info {
        h4 {
          font-size: 20px !important;
        }
        .content {
          h3 {
            font-size: 1.5rem !important;
          }
        }
      }
    }
  }
  /*pridetalks*/
}

@media (max-width: 768px) {

  /*card credit*/
  .card-credit {
    width: 300px;
    height: 200px;
    label {
      font-size: .9rem;
    }
    &-number {
      p {
        margin-right: 0;
        font-size: 1.1rem;
      }
    }
    &-date {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: .9rem;
      }
      span {
        font-size: 1rem;
      }
    }
    &-footer {
      img {
        height: 25px;
      }
    }
    &-front {
      height: 200px;
      padding: 1rem 1rem;
    }
    &-back {
      height: 200px;
      padding: 1rem 0;
      .line-card {
        height: 30px;
      }
      .cvv-container {
        height: 30px;
        margin: 2rem auto 0 auto;
        padding: 0 1rem 0 0;
        span {
          font-size: 1rem;
          padding: .2rem .3rem;
        }
      }
    }
  }
  /*end card credit*/

  .course-view {
    &-alternative {
      margin-bottom: 2rem;
      height: max-content;
      flex-direction: column;
      justify-content: center;
      &-imgs {
        justify-content: center;
        margin-top: 1rem;
        img {
          width: 280px;
        }
      }
    }
  }

  /*register*/
  .register {
    &-container {
      &-form {
        &-successfull {
          width: 96%;
          margin: 0 auto;
          padding: 1rem 1rem;
          .content {
            .icon {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
  /*end register*/

  /*stars dashabord */
  .splide-dashboard {
    border-radius: 1.5rem;
      &-video {
      border-radius: 1.5rem;
      &-play {
        width: 100px;
        height: 100px;
        font-size: 4rem;
        &:hover {
          font-size: 5rem;
        }
      }
    
    }
    .splide {
      border-radius: 2rem;
      &__slide {
        border-radius: 1.5rem;
        img {
          border-radius: 1.5rem;
        }
        a {
          .splide-dashboard-slider {
            border-radius: 1.5rem;
          }
        }
      }
    }
  }
  .billboard {
    &-content {
      p {
        font-size: 0.9rem;
      }
    }
  }
  .entrepreneurs {
    &-content {
      p {
        font-size: 0.9rem;
      }
    }
  }
  /*end dashboard*/

  /*gallery*/
  .blog {
    &-gallery {
      &-lightbox {
        &-item {
          width: 30%;
          border-radius: 0.5rem;
        }
      }
    }
  }
  /*end gallery*/

  .details-job-content {
    .header {
      height: auto;
      flex-direction: column;
      padding: 1rem;
      .thumbnail {
        width: 50%;
        height: 200px;
        margin-top: 1rem;
        border-radius: 1rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  /*general settings*/
  .btn-back {
    min-width: 40px !important;
    width: 40px !important;
    padding: 0 !important;
    span {
      display: none;
    }
    i {
      margin-right: 0;
    }
    p {
      display: none;
    }
    font-size: 1.3rem;
    justify-content: center;
  }
  /*end gneral setting*/

  /*direcory*/
  .card-directory-detail {
    flex-direction: column;
    .thumb {
      margin-bottom: 1rem;
      .img-detail {
        height: 100%;
      }
    }
    .info {
      .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tags {
          width: max-content;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .category {
            background: $green;
            border-radius: 0.5rem;
            padding: 0.2rem 0.3rem;
            color: $white;
            font-size: 0.9rem;
          }
          .subcategory {
            background: $light;
            font-size: 0.8rem;
            padding: 0.2rem 0.3rem;
            border-radius: 0.5rem;
            margin-top: -0.2rem;
          }
        }
      }
      .content {
        margin-bottom: 1rem;
      }
    }
  }
  /*en direcotye*/

  /*starts caring community*/
  .caring-community {
    &-header {
      flex-direction: column;
      margin-bottom: 1rem;
    }
  }
  .cards-caring {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  /*end caring community*/

  /*starts benefits*/
  .discount {
    &-header {
      width: 100%;
      height: auto;
      flex-direction: column;
      &-info {
        text-align: center;
      }
      &-thumbnail {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        img {
          width: auto;
          height: 150px;
          position: relative;
          object-fit: contain;
        }
      }
    }
    &-btns {
      gap: 1rem;
      justify-content: flex-end;
      .btn-square {
        width: 50px;
        height: 50px;
        p {
          display: none;
        }
      }
    }
    &-cards {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
    }
    &-card {
      width: 300px;
      height: 300px;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      color: $white;
      box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
      transition: 0.3s ease-in-out;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        display: flex;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        position: relative;
        z-index: 1;
        transition: 0.3s ease-in-out;
      }
      img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.9;
        transition: opacity 0.2s ease-out;
      }
      a {
        position: absolute;
        opacity: 0;
        max-width: 80%;
        transition: opacity 0.3s ease-out;
      }
      a {
        inset: auto auto 40px 30px;
        color: inherit;
        text-decoration: none;
      }
      &:hover img {
        transition: opacity 0.8s ease-in;
        opacity: 1;
      }
      &:hover:before {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
  /*end befefits*/

  /*start regiter serivces*/
  .card-credit {
    width: 100%;
    height: 250px;
    margin: 1rem auto 0 auto;
  }
  /*end register-services*/

  /*start emprendores*/
  .register-services {
    &-content {
      &-steps {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .steps-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
      }
      &-form {
        padding: 1rem;
      }
    }
  }
  /*end emprendedores*/

  /*start interest grup*/
  .interest-group {
    &-header {
      height: auto;
      justify-content: center;
      flex-direction: column;
      .imgs {
        justify-content: center;
        align-items: center;
        img {
          height: 150px;
        }
      }
      .title {
        padding: 0.5rem;
        width: 100%;
      }
    }
    &-cards {
      justify-content: space-evenly;
      gap: 1rem;
    }
  }
  /*end interest group*/

  /*starts directory*/
  .directory {
    .form-dashboard {
      display: flex;
      gap: 0.5rem;
    }
  }
  .card-directory {
    &-container {
      flex-direction: row;
      flex-wrap: wrap;
    }
    width: 100%;
    max-width: 300px;
    height: 420px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 auto 1rem auto;
    &-content {
      height: 220px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 0.5rem;
      &-info {
        width: 100%;
        height: 220px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 1rem;
        border-right: 0;
        p {
          padding-top: 0.5rem;
          font-size: 0.9rem;
        }
      }
      &-extra {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .category {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          .badge-directory {
            font-size: 0.8rem;
          }
        }
        .location {
          width: 100%;
          text-align: center;
        }
      }
    }
    &-thumbnail {
      width: 300px;
      height: 180px;
      border-radius: 1rem;
      overflow: hidden;
      img {
        height: 100%;
        object-fit: contain;
        border-radius: 1rem;
      }
    }
  }
  /**end direcotry/

  /*star pride music*/
  .pride-music {
    &-img {
      img {
        height: 150px;
      }
    }
  }
  /*end pride music*/

  /*START PTRIDETALKS*/
  .audience {
    &-container {
      .accordion {
        width: 100%;
        border-radius: 1rem;
        &-item {
          display: flex;
          border: 0;
          background: rgba(0, 0, 0, 0);
        }
        &-button {
          min-width: 220px;
          background: rgba(0, 0, 0, 0) !important;
          height: 50px;
          font-size: 1.5rem;
          color: $dark !important;
          padding: 0.5rem 1rem;
          &::after {
            content: "";
            display: none;
          }
          &.collapsed {
            background: $light;
            color: $pink !important;
            border: 0 !important;
            border-radius: 0.5rem !important;
            box-shadow: none;
          }
          &:focus {
            box-shadow: none;
            border: 0;
          }
        }
      }
    }
    &-header {
      height: auto;
      display: flex;
      flex-direction: column-reverse;
      padding: 1rem;
      &-info {
        padding: 1rem 1rem 0 1rem;
        .content {
          p {
            width: 100%;
            text-align: right;
          }
          h3 {
            font-size: 1.5rem;
            line-height: 2rem;
            color: $dark;
          }
        }
      }
      &-thumb {
        justify-content: center;
        .img {
          width: 100%;
          height: 350px;
        
        }
      }
    }

  }
  .aside_lateral {
    margin-top: 2rem;
  }
  .card-audience {
    width: 100%;
    max-width: 250px;
    height: 400px;
    padding: 0 .5rem .5rem .5rem;

    .info {
      height: 200px;

 
    }

  }
  /*END PRIDETALKS*/

  /*START DASHBOARD*/
  .dashboard {
    &-side {
      &-center {
        &-footer {
          flex-direction: column;
        }
      }
      &-center.viewing {
        .dashboard-side-left {
          &.show {
            .interests-group-container {
              width: 250px;
            }
          }
       
        }
        .dashboard-side-right {
        
          &.hide {
            display: none;
          }
        }
     
      }
    
    }
  }
  /*END DASHBOARD*/

  /*START HEADER DASHBOARD*/
  .dashboard {
    &-header {
      &-brand {
        width: 85%;
      }
      &-menu {
        display: none;
      }
      &-user {
        width: 15%;
  
        .dropdown {
          display: none;
        }
        &-dropdown {
          display: none;
      
        }
      }
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      &-user {
        .button-user {
          width: 40px;
          min-width: 40px;
          height: 40px;
          .rs-dropdown-toggle {
            .rs-dropdown-toggle-caret {
              display: none;
            }
          }
        }
      }
      &-menu {
        ul {
          li {
            .btn-menu {
              font-size: 1.25rem !important;
              .btn-menu {
                .rs-dropdown-toggle {
                  font-size: 1.25rem !important;
                }
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      &-user {
        width: 20%;
      }
    }
  }
  .drawer-particles {
    left: -25px;
  }
  /* END HEADER DASHBOARD*/



  /* START MUNDO PRIDE */
  .blog {
    &-gallery {
      &-main {
        width: 100%;
        height: 450px;
        border-radius: 1rem;
        position: relative;
        .btns {
          width: 100%;
          background: red;
          position: relative;
          .btn-show-more {
            right: -80px;
            top: -40px;
            position: absolute;
            z-index: 2;
            background: $black;
            border: 0.1rem solid $white;
            &::before {
              position: absolute;
              border-radius: 50px;
            }
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 1rem;
          position: absolute;
        }
        .content {
          border-radius: 1rem;
          position: absolute;
          bottom: 1rem;
          left: 1rem;
          background: rgba(0, 0, 0, 0.8);
          padding: 1rem;
          h4 {
            color: $white;
          }
          span {
            color: $white;
          }
        }
      }
      &-content {
        margin-top: 4rem;
        .cards-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          gap: 2rem;
        }
      }
    }
    &-company {
    
      &-main-card {
     
        .content {
          padding: 1rem;
          width: 100%;
          p {
          }
        }
      }
      &-cards {
        .card-post {
          padding: 1rem;
          .thumb {
            width: 50%;
          
          }
          .content {
            padding: 0.5rem 1.5rem;
            h4 {
              margin-bottom: 1.5rem;
            }
            p {
              display: none;
            }
          }
        }
      }
    }
    &-secondary {
   
      &-main-card {
       
        .content {
          width: 100%;
          max-width: 100%;
          height: 150px;
          padding: 1rem;
          bottom: -100px;
          left: 0;
          top: unset;
          right: 0;
          p {
            display: none;
          }
       
        }
      }
      &-cards {
        margin-top: 12rem;
   
      }
      &-post {
        &-container {
          .post-single {
            &-header {
              flex-direction: column;
              justify-content: center;
              padding: 0;
              height: auto;
            }
            &-title {
              padding: 0 1rem 1rem 1rem;
            }
            &-thumb {
              justify-content: center;
              img {
                border-radius: 1rem;
              }
            }
        
          }
        }
      }
    }
  }
  /* END MUDNO PRIDE*/

  /*services register*/
  .form-register-services {
    .info-upload {
      .extra {
        flex-direction: column;
      }
    }
  }
  .form-checkout {
    .flex-end {
      justify-content: center;
    }
    .header {
      flex-direction: column;
      margin: 1rem 0;
    }
  }
  .register-services {
 
    &-aside {
      width: 100%;
      background: $white;
      height: max-content;
      padding: 2rem;
      border-radius: 1rem;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          font-size: 3rem;
        }
      }
      .paragraph {
        width: 100%;
        height: max-content;
        padding: 1rem 1rem;
        text-align: left;
        background: rgba(155, 203, 70, 0.2);
        border-radius: 1rem;
        p {
          font-size: 1rem;
        }
        span {
          font-family: "is_bold", sans-serif;
        }
      }
      p {
        width: 100%;
        text-align: center;
        font-size: 0.9rem;
        padding: 1rem 0;
      }
    }
    &-content {
      &-steps {
        padding: 1rem;
        .steps-list {
          width: 100%;
          ul {
            li {
              margin: 0.5rem;
              input[type="radio"] {
                & + label {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
  /*end register-services*/
}

@media (max-width: 670px) {
  .dashboard-side-left,
  .dashboard-side-right {
    width: 50%;
  }
    /*carousel worldpride*/
    .prideWorld-container {
      .splide {
        &__slide {
          .card {
            &-worldpride {
              width: 97%;
              height: 260px;
              overflow: hidden;
              &-content {
                width: 250px;
                height: 260px;
                .overlay-img {
                  img {
                    width: 100%;
                    height: 280px;
                  }
                  .info {
                    bottom: 0.7rem;
                    left: 1rem;
                    h5 {
                      font-size: 1rem;
                    }
                    p {
                      font-size: 0.9rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    /**end carousel worlpride*/
}

@media (max-width: 575px) {

  /*entrepreneurs*/
  .card-credit {
    width: 250px;
    height: 200px;
    label {
      font-size: .9rem;
    }
    &-number {
      p {
        margin-right: 0;
        font-size: 1.1rem;
      }
    }
    &-date {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: .9rem;
      }
      span {
        font-size: 1rem;
      }
    }
    &-footer {
      img {
        height: 25px;
      }
    }
    &-front {
      height: 200px;
      padding: 1rem 1rem;
    }
    &-back {
      height: 200px;
      padding: 1rem 0;
      .line-card {
        height: 30px;
      }
      .cvv-container {
        height: 30px;
        margin: 2rem auto 0 auto;
        padding: 0 1rem 0 0;
        span {
          font-size: 1rem;
          padding: .2rem .3rem;
        }
      }
    }
  }
  /*end entrepreneurs*/

  /*provider*/
  .items-promo {
    justify-content: center;
  }
  .provider {
    &-header {
      margin-top: 2rem;
      margin-bottom: 0rem;
    }
  }
  /**provider/

  /*card audience */
  .cards-audience {
    justify-content: center;
  }
  .card-audience {
    height: 350px;
    padding-top: 0;
    margin: 0 auto 1rem auto;
    .info {
      height: max-content;
    }
  }

  /*reards*/
  .rewards {
    gap: 1rem;
    &-account {
      &-content {
        flex-direction: column;
        justify-content: center;
      }
   
      &-cards {
        justify-content: center;
      }
      &-btns {
        margin-top: 1rem;
      }
      &-card {
        margin: 0 auto;
      }
    }
    &-card {
      width: 45%;
      .coupon {
        &-thumb {
          width: 100%;
          max-width: 100%;
        }
        &-content {
          .category {
            font-size: 0.7rem;
            padding: 0 0.1rem;
          }
          .points {
            font-size: 1rem;
          }
          .footer {
            flex-direction: column;
            .title {
              h6 {
                width: 264px;
              }
            }
          }
          button {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
  .rewards-cards {
    justify-content: center;
  }
  /*rewards*/

  /*login*/
  .register {
    &-container {
      &-img {
        .bg-gradients {
          h1 {
            font-size: 32px;
          }
        }
      }
      &-form {
        .form-register {
          &-title {
            justify-content: center;
            h2 {
              text-align: center;
              font-size: 28px;
            }
          }
        }
        &-social-media {
          .login-footer-legals {
            width: 100%;
            display: flex !important;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
            margin-top: 1rem;
            & ul {
              flex-direction: column;
              justify-content: center;
              align-items: center;
              li {
                margin-right: 0;
                margin-bottom: 0.5rem;
                padding-right: 0;
                text-align: center;
                a {
                  margin-right: 0;
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  /*login*/

  /*carousel worldpride*/
  .prideWorld-container {
    position: relative;
    &-header {
      display: flex;
    }
    .splide {
      &__slide {
        .card {
          &-worldpride {
            width: 300px;
            height: 280px;
            overflow: hidden;
            &-content {
              width: 300px;
              height: 280px;
              .overlay-img {
               
                .info {
                  bottom: 0.7rem;
                  left: 1rem;
                  h5 {
                    font-size: 1rem;
                  }
                  p {
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /**end carousel worlpride/

  /*stars dashabord */
  .splide-dashboard {
    border-radius: 1.5rem;
    ¡ &-video {
      border-radius: 1.5rem;
      &-play {
        width: 80px;
        height: 80px;
        font-size: 3rem;
        &:hover {
          font-size: 4rem;
        }
      }
    }
    .splide {
      border-radius: 1.5rem;
      &__pagination {
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0;
        pointer-events: none;
        position: absolute;
        z-index: 10;
        top: 0;
        bottom: 0;
        right: 1rem;
        li {
          margin-bottom: 0.5rem;
          &:last-child {
            margin-bottom: 0;
          }
          button {
            width: 10px;
            height: 12px;
            border-radius: 100%;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
            &.is-active {
              background: $gray;
            }
          }
        }
      }
      &__arrows {
        position: absolute;
        z-index: 1;
        bottom: 1rem;
        left: 1rem;
      }
      &__arrow {
        opacity: 0;
        background: rgba(0, 0, 0, 0);
        width: 50px;
        height: 50px;
        border-radius: 50px;
        border: 0.1rem solid rgba(255, 255, 255, 0.8);
        transition: 0.2s ease-in-out;
        &:hover {
          background: rgba(0, 0, 0, 0.5);
        }
        svg {
          fill: rgba(255, 255, 255, 0.8);
          width: 30px;
          height: 30px;
        }
        &--prev {
          transform: rotate(180deg);
          margin-right: 1rem;
        }
      }
      &__slide {
        border-radius: 1.5rem;
        img {
          border-radius: 1.5rem;
        }
        a {
          .splide-dashboard-slider {
            border-radius: 1.5rem;
          }
        }
      }
    }
  }
  /*START DASHBOARD*/
  .dashboard-side-left,
  .dashboard-side-right {
    max-width: 100% !important;
    width: 100%;
  }
  .interests-group-container {
    h3 {
      text-align: center;
    }
  }
  .box-stories-title {
    text-align: center;
  }
  .dashboard {
    &-side {
      &-center {
        padding: 5rem 0 2rem 0 !important;
      }
      &-center.viewing {
        padding-top: 0 !important;
        flex-direction: column;
        max-height: unset;
        .dashboard-side-left {
          padding-bottom: 1rem;
          &.show {
            max-height: unset;
            position: relative;
            padding-right: 0;
            padding-left: 0;
            .interests-group-container {
              width: 280px;
              margin: 0 auto;
            }
          }
        
        }
        .dashboard-side-right {
          &.show {
            width: 100%;
            max-width: unset;
            max-height: unset;
            overflow-y: unset;
            position: relative;
            padding: 0;
            .box-stories-container {
              width: 100%;
              margin-bottom: 2rem;
              .card-box-stories {
                width: 280px;
                margin: 0 auto 0 auto;
              }
            }
            .dashboard-banner {
              margin: 0 auto;
            }
          }
         
        }
        &-footer {
          flex-direction: column;
        }
      }
  
    }
  }
  /*end dashboard*/

  /*cartelera*/
  .course-view {
    &-header {
      &-scroll {
        .card-billboard-btn {
          .card-billboard {
            width: 200px;
            height: 250px;
          }
        }
      }
    }
  }
  /*end cartelera*/

  /*starts benefits*/
  .discount {
 
    &-btns {
      li {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
    &-cards {
      justify-content: center;
    }
  }
  /*end befefits*/

  /*start card job bank*/
  .job-bank {
    &-header {
      .footer-btn {
        .btn-searcher {
          width: 100%;
        }
      }
    }
    &-btns {
      flex-direction: column;
      justify-content: center;
      .btn-gradient-green {
        margin-right: 0;
      }
    }
    &-options {
      flex-direction: column;
      .counter {
        margin-bottom: 1rem;
      }
    }
    &-card {
      padding: 0.5rem;
      &-header {
        flex-direction: row;
        .thumb {
          width: 100%;
          height: 120px;
          padding: .5rem .5rem;
          border-radius: .5rem;
          background: $light;
          img {
            width: 100%;
            height: 100%;
            border-radius: .5rem;
            object-fit: contain;
          }
        }
        .info {
          h5 {
            font-size: 1rem;
            margin: 0.5rem 0;
          }
          .content {
            flex-direction: column;
            p {font-size: .85rem}
            .company {
              margin-top: 0;
            }
          }
          .date {
            justify-content: flex-start;
            p {
              text-align: left;
              font-size: .85rem
            }
          }
        }
      }
      &-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        .price {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 0.5rem;
          p {
            display: flex;
            align-items: center;
            line-height: 1rem;
            font-size: 1.1rem;
            font-family: "is_bold", sans-serif;
            i {
              margin-right: 0.5rem;
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
  .details-job-content {
    .header {
      .thumbnail {
        width: 230px;
        height: 150px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  /*end card job banj*/

  /* START MUNDO PRIDE */
  .blog {
    &-gallery {
      &-main {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        .content {
          width: 90%;
          position: relative;
          bottom: unset;
          left: unset;
          margin: 0 auto 0.5rem auto;
          background: rgba(0, 0, 0, 0.8);
          padding: 1rem;
          h4 {
            color: $white;
          }
          span {
            color: $white;
          }
        }
      }
      &-content {
        margin-top: 4rem;
        .cards-container {
          justify-content: center;
          gap: 2rem;
        }
      }
    }
    &-company {
    
      &-main-card {
       
        .content {
          width: 100%;
         
        }
      }
      &-cards {
        .card-post {
          padding: 1rem;
          flex-direction: column;
          .thumb {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .content {
            width: 100%;
            padding: 0.5rem;
            h6 {
              font-size: 0.9rem;
            }
            h4 {
              font-size: 1.1rem;
              margin-bottom: 0.5rem;
            }
            p {
              display: none;
            }
          }
          &:hover {
            .thumb {
              img {
                box-shadow: 0rem 1rem 0 $light-gray;
              }
            }
          }
        }
      }
    }
    &-secondary {
   
      &-main-card {
        padding: 1rem;
      
        .content {
          h3 {
            font-size: 21px;
          }
          h6 {
            font-size: 0.8rem;
          }
          .btn-next {
            min-height: 30px !important;
            height: 3px !important;
          }
        }
      }
      &-cards {
    
        .card-post {
          &-secondary {
            width: 300px;
          }
        }
      }

      &-aside {
        margin-top: 2rem;
      }
    }
  }
  /* END MUDNO PRIDE*/

  /*start profile */
  .tabs-user {
    ul.nav-tabs {
      li.nav-item {
        .nav-link {
          height: 80px;
          p {
            display: none;
          }
        }
      }
    }

  }

  /*start profile*/
  .profile {
    padding: 1rem;
  }
  /* end profile */

  /*start chat*/
  .chat {
    &-container {
      &-header {
        .button-chat {
          width: 45px;
          max-width: 45px !important;
          justify-content: center !important;
      
          .part2 {
            display: flex;
            justify-content: center;
            p {
              span {
                display: none;
              }
            }
            .btn-expand {
              display: none;
            }
          }
        
        }
      }

    }
  }
  .chat-container.open {
    width: 300px;
    .button-chat {
      width: 100%;
      max-width: 100% !important;
      .part2 {
        p {
          span {
            display: unset;
          }
        }
        justify-content: space-between;
      }
    }
  }
  /*end char*/

  /*dashboard*/
  .dashboard-side-left,
  .dashboard-side-right {
    max-width: 100% !important;
    width: 100%;
  }
  .interests-group-container {
    h3 {
      text-align: center;
    }
  }
  .box-stories-title {
    text-align: center;
  }
  .dashboard {
    &-side {
      &-center {
        padding: 5rem 0 2rem 0 !important;
      }
      &-center.viewing {
        padding-top: 0 !important;
        flex-direction: column;
        max-height: unset;
        .dashboard-side-left {
          padding-bottom: 1rem;
          &.show {
            max-height: unset;
            position: relative;
            padding-right: 0;
            padding-left: 0;
            .interests-group-container {
              width: 280px;
              margin: 0 auto;
            }
          }
         
        }
        .dashboard-side-right {
          &.show {
            width: 100%;
            max-width: unset;
            max-height: unset;
            overflow-y: unset;
            position: relative;
            padding: 0;
            .box-stories-container {
              width: 100%;
              margin-bottom: 2rem;
              .card-box-stories {
                width: 280px;
                margin: 0 auto 0 auto;
              }
            }
            .dashboard-banner {
              margin: 0 auto;
            }
          }
         
        }
        &-footer {
          flex-direction: column;
        }
      }
    }
    &-header {
      &-brand {
       
        .doodle {
       
          &-info {
            display: none;
            h6 {
            }
            p {
            }
          }
        }
      }
      &-user {
        .btns-dropdowns {
          .rs-btn-toolbar {
            .rs-dropdown.share {
              .rs-dropdown-toggle {
                font-size: 1.3rem;
              }
            }
            .rs-dropdown.notifications {
              width: max-content;
              display: flex;
              gap: 1rem;
              position: relative;
              transition: 0.2s ease-in-out;
              .rs-dropdown-toggle-caret {
                display: none;
              }
              .rs-dropdown-menu {
                background: $white;
                box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
                border-radius: 1rem;
                width: max-content;
                position: absolute;
                padding: 0;
                left: unset;
                top: -0.5rem;
                right: 0;
                .notifications-body {
                  background: $white;
                  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
                  width: 300px;
                  position: relative;
                  padding: 0;
                  border-radius: 1rem;
                  .notifications-title {
                    width: 100%;
                    height: 50px;
                    font-family: "is_bold", sans-serif;
                    font-size: 1.2rem;
                    background: $light;
                    border-radius: 1rem 1rem 0 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0.5rem 1rem 1rem 1rem;
                  }
                  .notifications-content {
                    width: 100%;
                    height: 400px;
                    overflow-y: auto;
                    padding: 0.5rem;
                    border-radius: 1rem;
                    background: $white;
                    margin-top: -10px;
                    display: flex;
                    margin-left: 0;
                    list-style: none;
                    flex-direction: column;
                    &::-webkit-scrollbar {
                      width: 0.2rem;
                      border-radius: 50px;
                    }
                    &::-webkit-scrollbar-thumb {
                      box-shadow: inset 0 0 6px $dark;
                      border-radius: 50px;
                    }
                    &-item {
                      margin-bottom: 0.5rem;
                      background: $light;
                      border: 0.1rem solid $light;
                      padding: 0.3rem;
                      border-radius: 0.5rem;
                      a {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        text-decoration: none;
                        color: $dark;
                        p {
                          font-size: 0.9rem;
                          line-height: 0.9rem;
                        }
                        span {
                          width: unset;
                          height: unset;
                          font-size: 0.8rem;
                          font-family: "is_medium", sans-serif;
                          line-height: 0.8rem;
                          padding: 0.1rem 0.1rem 0.3rem 0.1rem;
                          background: rgba(0, 0, 0, 0);
                        }
                      }
                      &:first-child {
                        margin-top: 1rem;
                      }
                      &:last-child {
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
              &.rs-dropdown-open {
                .rs-dropdown-toggle {
                  color: $dark;
                }
              }
            }
          }
          .rs-btn-toolbar {
            .rs-dropdown.share {
              .rs-dropdown-menu {
                width: 250px;
                max-width: 250px;
                height: 250px;
              }
            }
          }
        }
      }
    }
    &-footer {
      display: flex;
      left: 0.15rem;
      padding: 0.5rem 0.25rem !important;
      &-menu {
        position: relative;
        ul {
          li {
            margin-right: 1rem;
            .btn-menu {
              width: 30px !important;
              min-width: 30px !important;
              height: 30px !important;
              font-size: 1.5rem !important;
              .btn-menu {
                .rs-dropdown-toggle {
                  width: 30px !important;
                  min-width: 30px !important;
                  height: 30px !important;
                  font-size: 1.5rem !important;
                }
              }
              &.dashboard,
              &.admin {
                width: max-content !important;
                min-width: max-content !important;
                font-size: 1.5rem !important;
              }
            }
            &:last-child {
              margin-right: 0;
            }
            .rs-btn-toolbar.user {
              .rs-dropdown {
                &-open {
                  .rs-dropdown-menu {
                    left: -12px !important;
                    right: unset !important;
                  }
                }
                &-menu {
                  left: 0px !important;
                  right: unset !important;
                }
              }
            }
            .rs-btn-toolbar.community {
              .rs-dropdown {
                &-open {
                  .rs-dropdown-menu {
                    left: unset !important;
                    right: -15px !important;
                  }
                }
                &-menu {
                  left: unset !important;
                  right: 0 !important;
                }
              }
            }
          }
        }
      }
      &-user {
        width: 40px !important;
        .rs-dropdown.dropdown-notifications {
          .rs-dropdown-menu {
            left: 0;
            top: -490px;
          }
        }
      }
      .container-chat {
        width: 40px !important;
      }
    }
    &-footer-home {
      padding-bottom: 55px;
      ul {
        flex-direction: column;
        li {
          margin-bottom: 0.5rem;
          margin-right: 0;
        }
      }
    }
  }
  .drawer-particles {
    width: 80px;
    height: 60px;
    left: 11px;
    right: unset;
  }
  /*end dashboard*/

  /*starts post pride*/
  .blog-worldpride {
   
    &-content {
      padding: 1rem 0.5rem;
      &-post {
        .comments-container {
          margin-top: 2rem;
        }
        .comments {
          padding: 0.5rem;
          &-container {
           
            &-new {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              flex-direction: column;
              .user {
                display: none;
              }
          
              .btn-send-comment {
                width: 100%;
                margin-bottom: 0.5rem;
              }
            }
            &-box {
              width: 100%;
              height: 70px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: relative;
            }
            &-toolbar {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: flex-end;
              background: $white;
              border-radius: 0.5rem;
              margin-bottom: 0;
              border: 0.1rem solid $light-gray;
              position: absolute;
              .rdw-emoji-wrapper {
                position: unset;
                width: 35px;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                img {
                  padding: 0;
                  width: 30px;
                  height: 30px;
                }
              }
            }
            &-editor {
              width: 100%;
              height: 40px;
              line-height: 0.5rem;
              margin-left: 0.5rem;
              margin-right: 40px;
              position: relative;
              z-index: 99;
              border-radius: 0.5rem;
            }
          }
          &-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .user {
              width: max-content;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              img {
                width: 50px;
                height: 50px;
                object-fit: cover;
                border-radius: 100%;
                padding: 0.15rem;
                background: $white;
                box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.3);
              }
              p {
                display: none;
              }
            }
            .dropdown {
              &-menu {
                transform: translate(-165px, 60px) !important;
                border-radius: 0.5rem;
                border-color: $light-gray;
              }
            }
          }
          &-footer {
            .likes-container {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 0.1rem 0;
              .likes-total {
                padding-left: 1rem;
              }
            }
          }
        }
      }
      .post-title {
        padding: 1rem;
        p {
          font-size: 0.8rem;
          text-align: center;
        }
        h3 {
          font-size: 1.4rem;
        }
      }
      .post-thumb {
      
        img {
          width: 250px;
          max-width: 250px;
          height: 250px;
          max-height: 250px;
        }
      }
     
    }
    &-aside {
      margin-top: 2rem;
      &-card {
        width: 100%;
        height: 200px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .bg-gradient-pride {
          position: absolute;
          border-radius: 1rem;
        }
        img {
          width: auto;
          height: 180px;
          position: absolute;
          z-index: 1;
          opacity: 0.8;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto 0;
        }
        h3 {
          position: absolute;
          z-index: 2;
          font-family: "is_medium", sans-serif;
          color: $white;
          padding: 2rem;
          line-height: 1.5rem;
          text-align: center;
        }
        button {
          position: absolute;
          z-index: 2;
          right: 1rem;
          bottom: 1rem;
        }
      }
      &-publications {
        .publications-list {
          width: 100%;
          display: flex;
          flex-direction: column;
          .box_collapse {
            &.show {
              ul {
                li {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  .btn-publication-white {
                    width: 95% !important;
                  }
                }
              }
            }
          }
          .btn-publication-white {
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;
          }
          ul {
            list-style: none;
            li {
              margin-bottom: 0.3rem;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  /*end pride*/

  /*START orgullo lgbtq*/
  .blog-secondary {
    &-main-card {
      .content {
        bottom: -110px;
        height: 155px;
      }
    }
    &-post-container {
      .post-single-title {
        h3 {
          width: 100%;
        }
      }
      .post-single-content {
        margin: -1rem 0 0 0;
        padding: 1rem;
      }
    }
  }
  /*END ORGULLO LGBTQ+*/

  /*START PTRIDETALKS*/
  .audience {
    &-container {
      .accordion {
        width: 100%;
        border-radius: 1rem;
        &-item {
          display: flex;
          border: 0;
          background: rgba(0, 0, 0, 0);
        }
        &-button {
          min-width: 220px;
          background: rgba(0, 0, 0, 0) !important;
          height: 50px;
          font-size: 1.5rem;
          color: $dark !important;
          padding: 0.5rem 1rem;
          &::after {
            content: "";
            display: none;
          }
          &.collapsed {
            background: $light;
            color: $pink !important;
            border: 0 !important;
            border-radius: 0.5rem !important;
            box-shadow: none;
          }
          &:focus {
            box-shadow: none;
            border: 0;
          }
        }
      }
    }
    &-header {
      height: auto;
      display: flex;
      flex-direction: column-reverse;
      padding: 1rem;
      &-info {
        padding: 1rem 0 0 0;
        h4 {
          font-size: 1rem;
          line-height: 1rem;
          margin-bottom: 1rem;
          text-align: center;
          width: 100%;
        }
        .content {
          p {
            width: 100%;
            text-align: right;
          }
          h3 {
            font-size: 1.3rem;
            line-height: 1.3rem;
          }
          .flex-start {
            width: 100%;
            margin-top: 1.5rem;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      &-thumb {
        justify-content: center;
        .img {
          width: 100%;
          height: 240px;
          
        }
      }
    }
  
  }
  .content_card_audience {
    overflow: auto;
    padding: 1rem 0;
    .accordion-body {
      padding: 1rem 0;
    }
  }
  /*END PRIDETALKS*/

  /*direcotry*/
  .directory {
    .form-dashboard {
      display: block;
    }
  }
  /*end direcotry*/
}

@media screen and (max-width: 490px) {
  .prideWorld-container {
    position: relative;
    &-header {
      display: flex;
    }
    .splide {
      &__slide {
        .card {
          &-worldpride {
            width: 300px;
            height: 300px;
            &-content {
              width: 300px;
              height: 300px;
              .overlay-img {
                img {
                  width: 300px;
                  height: 300px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {

  /*card credit*/
  .card-credit {
    height: 180px;
    &-number {
      p {
        font-size: 1.1rem;
      }
    }
    &-date {
      p {
        font-size: .9rem;
      }
      span {
        font-size: .9rem;
      }
    }
    &-footer {
      img {
        height: 20px;
      }
    }
    &-front {
      height: 180px;
    }
    &-back {
      height: 180px;
      .line-card {
        height: 30px;
      }
      .cvv-container {
        height: 30px;
        margin: 2rem auto 0 auto;
        padding: 0 1rem 0 0;
        span {
          padding: .2rem .3rem;
        }
      }
    }
  }
  /*end card credit*/

  /*start emprendores*/
  .register-services {
    &-content {
      &-form {
        padding: 0;
      }
    }
  }
  /*end emprendedores*/

  .dashboard-container .btn-dots-v {
    right: 100px;
  }

  .rs-dropdown.button-circle {
    margin-right: 0.4rem;
  }
  .dashboard-header-user {
    .rs-btn-toolbar .button-user {
      box-shadow: none;
      min-width: auto;
      .quantity {
        left: 9px;
      }
      .rs-dropdown-toggle {
        .rs-dropdown-toggle-caret {
          display: none;
        }
      }
    }
  }

  /*carousel worldpride*/
  .prideWorld-container {
    position: relative;
    &-header {
      display: flex;
    }

  }
  /**end carousel worlpride/
  /*header dashboard*/
  .dashboard {
    &-header {
      &-brand {
        a {
          img {
            width: 130px;
          }
        }
        .doodle {
         
          &-info {
         
            p {
              font-size: 0.8rem;
            }
          }
        }
      }
   
    }
  }
  /*end header*/

  /*stars dashabord */
  .splide-dashboard {
    border-radius: 1rem;
    &-video {
      border-radius: 1rem;
      &-play {
        width: 50px;
        height: 50px;
        font-size: 2rem;
        &:hover {
          font-size: 3rem;
        }
      }
    
    }
    .splide {
      border-radius: 1rem;
      &__pagination {
        right: 0.5rem;
        li {
          margin-bottom: 0.5rem;
          button {
            width: 12px;
            height: 12px;
          }
        }
      }
      &__arrows {
        bottom: 0.5rem;
        left: 0.5rem;
      }
      &__arrow {
        width: 30px;
        height: 30px;
        svg {
          width: 17px;
          height: 17px;
        }
      }
      &__slide {
        border-radius: 1rem;
        img {
          border-radius: 1rem;
        }
        a {
          .splide-dashboard-slider {
            border-radius: 1rem;
          }
        }
      }
    }
  }

  /*end dashboard*/

  /*cartelera*/
  .course-view {
  
    &-header {
      &-scroll {
        grid-gap: unset;
        gap: unset;
        .card-billboard-btn {
          .card-billboard {
            width: 150px;
            height: 200px;
          }
        }
      }
    }
  }
  .card-billboard {
    width: 150px !important;
    height: 200px !important;
    &.overlay {
      button {
        font-size: 3rem;
      }
      .btn-outline-white {
        min-width: 105px;
        width: 105px;
        font-size: 0.8rem;
      }
    }
    &-body {
      padding: 1rem;
    }
 
    &-title {
      h3 {
        font-size: 1.2rem;
        line-height: 1.2rem;
      }
    }
    &-description {
      padding: 0.5rem 0;
      p {
        font-size: 0.9rem;
      }
    }
  }
  /*end cartelera*/

  /*starts job bank*/
  .details-job-content {
    .header {
      .thumbnail {
        width: 150px;
        height: 110px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  /*start interest grup*/
  .interest-group {
 
    &-header {
      .imgs {
        img {
          height: 100px;
        }
      }
    
    }
  
  }
  /*end interest group*/

  .chat-container {
  
    &.open {
      height: 450px;
      .chat-container-content-navbar {
        .chat-item {
          width: 45px;
          height: 35px;
          border-radius: 0.5rem;
        }
      }
    }
  }

  .blog {
    &-gallery {
      &-main {
        width: 100%;
        height: 450px;
        border-radius: 1rem;
        position: relative;
        .btns {
          width: 100%;
          background: red;
          position: relative;
          .btn-show-more {
            right: -80px;
            top: -40px;
            position: absolute;
            z-index: 2;
            background: $black;
            border: 0.1rem solid $white;
            &::before {
              position: absolute;
              border-radius: 50px;
            }
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 1rem;
          position: absolute;
        }
        .content {
          border-radius: 1rem;
          position: absolute;
          bottom: 1rem;
          left: 1rem;
          background: rgba(0, 0, 0, 0.8);
          padding: 1rem;
          h4 {
            color: $white;
          }
          span {
            color: $white;
          }
        }
      }
      &-content {
        margin-top: 4rem;
        .cards-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          gap: 2rem;
        }
      }
      &-lightbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
        padding: 1rem 0 !important;
        &-item {
          width: 20%;
          border-radius: 1rem;
        }
      }
    }
    &-company {
   
      &-main-card {
        padding: 0.5rem;
        .thumb {
          padding: 0.5rem;
          img {
            height: 200px;
            box-shadow: 0 1rem 0 rgba(0, 0, 0, 0.1);
          }
        }
      }
      &-cards {
     
        .card-post {
          padding: 0.5rem;
          .thumb {
            img {
              width: 250px;
              height: 200px;
            }
          }
          
        }
      }
    }
  }

  .cards-pride-music {
    .card-pride-music {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  // .dashboard-container .btn-dots-v {
  //   right: 95px;
  // }
}

@media screen and (max-width: 380px) {
  .rewards-card {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
  .drawer_content_segment {
    justify-content: flex-start;
    gap: 5px;
    .item_segment_home {
      .bg_img_segment {
        width: 150px;
        height: 150px;
        img {
          max-height: 100px;
        }
      }
    }
  }
}
