@import "../../assets/theme/vars";

.discount {
  &-container { }
  &-header {
    width: 100%;
    height: 300px;
    background: $white;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem;
    }
    &-thumbnail {
      width: 100%;
      height: 300px;
      position: relative;
      img {
        width: auto;
        height: 340px;
        position: absolute;
        right: 0;
        bottom: 0;
        object-fit: contain;
      }
    }
  }
  &-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 0;
    list-style: none;
    li {
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &-cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  &-card {
      width: 255px;
      height: 255px;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      color: $white;
      box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
      transition: .3s ease-in-out;
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: flex;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.5);
        position: relative;
        z-index: 1;
        transition: .3s ease-in-out;
      }
      img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.9;
        transition: opacity .2s ease-out;
      }
      a {
        position: absolute;
        opacity: 0;
        max-width: 80%;
        transition: opacity .3s ease-out;
      }
      a {
        inset: auto auto 40px 30px;
        color: inherit;
        text-decoration: none;
      }
      &:hover img {
        transition: opacity .8s ease-in;
        opacity: 1;
      }
      &:hover:before {
        background: rgba(0,0,0,.1);
      }
  }
  &-categories {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 2rem 0;
    border-radius: 1rem;
    background: $white;
    padding: .5rem;
    &-item {
      min-width: 100px;
      height: 60px;
      background: $white;
      border-radius: .5rem;
      padding: .5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: .2s ease-in-out;
      &.active {
        background: $light;
        span {
          color: $orange;
          font-family: "is_medium", sans-serif;
        }
      }
      &:hover {
        cursor: pointer;
        background: $light;
      }
    }
  }
}

