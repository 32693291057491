@import "../../assets/theme/vars";

.box-schedule-container {
  border-radius: 0.7rem;
  width: 100%;
  margin:  2rem 0 3rem;
  position: relative;
  a {text-decoration: none; }
  &-item {
    width: 100%;
    // max-width: 240px;
    // height: 60px;
    text-decoration: none !important;
    display: flex;
    justify-content: flex-start;
    background: #ffffff;
    border-radius: 0.7rem;
    border: 1px solid #eeeeee;
    padding: 0.5rem 0.1rem;
    margin-right: 0.5rem;
    transition: 0.2s ease-in-out;
    gap: 6px;
    // @media (max-width: 1050px) {
    //   width: 300px;
    // }
    // @media (max-width: 360px) {
    //   width: 285px;
    // }
    .date {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 0.5rem;
      min-width: 50px;
      .day {
        font-family: "is_bold", sans-serif;
        font-size: 1.5rem;
        line-height: 1rem;
      }
      .month {
        text-decoration: none;
        text-transform: capitalize;
        line-height: 0.5rem;
        font-size: 0.9rem;
      }
    }
    .info {
      width: 100%;
  
      padding-right: .35rem;
      // padding: 0 0.5rem;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      .name {
        width: 100%;
        font-family: "is_medium", sans-serif;
        font-size: 0.9rem;
        white-space: break-spaces;
        line-height: 1rem;
      }
      .location {
        width: 100%;
        font-family: "is_book", sans-serif;
        font-size: 0.9rem;
        line-height: 1rem;
        text-align: right;
      }
    }
    &:hover{
      border: 1px solid #aaaaaa;
    }
  }
  .splide {
    border-radius: 0.7rem;

    &__arrows {
      width: max-content;
      position: absolute;
      top: -40px;
      right: 0;
      color: #999999;
    }
    &__arrow {
      width: 30px;
      height: 30px;
      border-radius: 50px;
      background: $white;
      box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.1);
      &:disabled {
        opacity: 0.5;
      }
      &--prev {
        margin-right: 0.25rem;
        transform: rotate(180deg);
        svg {
          width: 15px;
          height: 15px;
        }
      }
      &--next {
        svg {
          width: 15px;
          height: 15px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.schelude__slide__prdmx{
  .splide__list{
    gap: 10px;
  }
}