@import "../../assets/theme/vars";

.wrapper_users {
  @include bggrey;
  min-height: calc(100vh - 4rem);
  .box_actions {
    .btn {
      min-width: auto !important;
      padding: 0 1rem;
    }
    .dropdown-item a:hover {
      color: $white_text;
    }
    .btn-secondary {
      &:active,
      &:focus {
        background: var(--primary_color) !important;
      }
    }
  }
  .box_tool_user {
    .form_inline {
      display: flex;
      justify-content: flex-end;
      .form-control {
        border-radius: 5px 0 0 5px;
      }
    }
    .box_search_inp {
      .btn {
        background: #d1d6df;
        min-width: auto;
        height: calc(1.5em + 0.75rem + 2px);
        border-radius: 0 5px 5px 0;
        padding: 0.375rem 0.75rem;
      }
    }
  }
}

.dropzone {
  background: rgba(0,0,0,.05) !important;
  border-radius: .5rem;
  .box_dropzone {
    background: $white;
  }
}

.wrapper_users_details {
  @include bggrey;
  min-height: calc(100vh - 4rem);
}

.list-files {
  width: 100%;
  background: $white;
  list-style: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  li {
    width: 100%;
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    i {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background: 50px;
      border: .1rem solid $light-gray;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
    }
  }
}
