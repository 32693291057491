@import "react-big-calendar/lib/sass/styles";
@import "../../assets/theme/vars";

.diary {
	&-container {}
	&-title {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&-data {
		&-item {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding-bottom: .5rem;
			h6 {
				line-height: 0;
				margin: 0;
				padding-left: 1rem;
				font-family: "is_medium", sans-serif;
			}
		}
		&-calendar {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding-top: .5rem;
			h6 {
				line-height: 0;
				margin: 0;
				padding-left: 1rem;
				font-family: "is_medium", sans-serif;
			}
		}
	}
}

.circle {
	width: 30px;
	height: 30px;
	border-radius: 100%;
	color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	&.birthday {
		background: $green !important;
	}
	&.event {
		background: $blue !important;
	}
}

.rect {
	width: 100%;
	height: 30px;
	&.birthday {
		background: $green !important;
	}
	&.event {
		background: $blue !important;
	}
}

.rbc {
	&-calendar {
		min-width: 1000px;
		overflow: auto;
		display: block;
	}
	&-toolbar {
		&-label {
			font-family: "is_black", sans-serif;
			font-size: 1.5rem;
		}
	}
	&-btn-group {
		width: max-content;
		background: $white;
		padding: .5rem 1rem;
		border-radius: 1rem;
		button {
			width: max-content;
			height: 50px;
			border: 0;
			background: $white;
			position: relative;
			border-radius: .5rem !important;
			margin-right: .5rem;
			&:hover, &:focus {
				box-shadow: none;
				background: $light;
			}
			&.rbc-active {
				box-shadow: none;
				background: rgba(0,0,0,0);
				&:hover, &:focus {
					box-shadow: none;
					background: $light;
				}
			}
			&.rbc-active::before {
				content: '';
				display: block;
				position: absolute;
				bottom: 3px;
				left: 0;
				opacity: .5;
				height: 3px;
				width: 100%;
				background: linear-gradient(to right, $yellow, $purple, $darkblue, $skyblue, $green, $red, $yellow);
				transform-origin: right top;
				transform: scale(0, 1);
				transition: color 0.1s, transform 0.2s ease-out;
			}
			&.rbc-active:active::before {
				background-color: #000;
			}
			&.rbc-active::before, &:focus::before {
				transform-origin: left top;
				transform: scale(1, 1);
			}
		}
	}
	&-agenda {
		&-view {
			background: $white;
			border-radius: 1rem;
			padding: 1rem;
		}
		&-table {
			border: 0 !important;
			thead {
				tr {
					th.rbc-header {
						height: 50px;
						background: $white;
						border: 0;
						font-size: 1rem;
					}
				}
			}
			tbody {
				tr {}
			}
		}
		&-date {
			border:0;
			&-cell {
				border:0 !important;
				background: $white;
				border-radius: 0;
			}
		}
		&-time {
			border:0;
			&-cell {
				border:0 !important;
				background: $white;
				border-radius: 0;
			}
		}
		&-event {
			border:0;
			&-cell {
				border:0 !important;
				background: $white;
				border-radius: 0;
				a {
					background: $light;
					padding: .25rem .5rem;
					border-radius: .5rem;
					text-decoration: none !important;
					transition: .2s ease-in-out;
					&:visited {
						text-decoration: none;
					}
				}
			}
		}
		&-empty {
			text-align: center;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	&-month {
		&-view {
			min-height: 700px;
			text-transform: capitalize;
			background: $white;
			font-size: 1.1rem;
			border: 0;
			padding: 1rem;
			border-radius: 1rem;
		}
		&-header {

		}
		&-row {
			border: 0 !important;
		}
	}
	&-header {
		padding: .5rem 1rem;
		text-align: left;
		border: 0 !important;
	}
	&-time {
		&-view {
			text-transform: capitalize;
			background: $white;
			font-size: 1.1rem;
			border: 0;
			padding: 1rem;
			border-radius: 1rem;
		}
		&-header {
			&-content {
				border: 0;
			}
			&-cell {}
		}
		&-content {
			border: 0;
		}
		&-gutter {}
		&-column {}
		&-slot {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	&-label {
		padding: .3rem;
	}
	&-allday {
		&-cell {
		}
	}
	&-row {
		&-bg {
		}
		&-content {}
	}
	&-timeslot {
		&-group {
			border: 0;
			background: $light;
			margin: .1rem;
			border-radius: .5rem;
		}
	}
	&-day {
		&-slot {}
	}
	&-now {}
	&-today {
		background: $white;
	}
	&-events {
		&-container {
			border: 0;
		}
	}
}


.event-event {
	background-color: $blue !important;
}
.event-birthday {
	background-color: $green !important;
}

.agenda-event {
	color: $blue !important;
}
.agenda-birthday {
	color: $green !important;
}

.wrapper_diary {
	.content_ttl_diary {
		margin-bottom: 1.5rem;
	}
}

.rbc-agenda-event-cell a {
	text-decoration: underline;
}



