.wrapper_side_advertising {
  width: 85%;
  margin: 0 auto 1rem;
  position: relative;
  border-radius: 0.35rem;
  a {
    text-decoration: none;
  }
  &:hover {
    .t_pub {
      color: var(--primary_color);
    }
    img {
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
    }
  }
  img {
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
    // width: 80% !important;
    // margin: 0 auto;
  }
  .slick-dots {
    bottom: -10px;
    li {
      button.slick-active {
        border-radius: 50px;
      }
      button:before {
        border-radius: 50px;
        background: #3C3C3C !important;
      }
    }
  }
  /* Arrows */
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    background: rgba(0, 0, 0,0);
    position: absolute;
    top: 1.25rem;
    display: block;
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 50px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 9;
    cursor: pointer;
    color: transparent;
    border: .1rem solid white;
    outline: none;
    box-shadow: 1px 1px 12px rgba(0,0,0,.5);
    transition: .2s ease-in-out;
  }

  .slick-prev {
    right: 40px;
  }
  .slick-next {
    right: 5px;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    opacity: 1;
    outline: none;
    background: white;
  }
  .slick-prev:focus,
  .slick-prev:hover {
    &::before {
      border-right: 6px solid #3c3c3c;
    }
  }
  .slick-next:focus,
  .slick-next:hover {
    &::before {
      border-left: 6px solid #3c3c3c;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    content: "";
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 3px);
    z-index: 9;
  }

  .slick-prev:before {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-right: 6px solid white;
    border-bottom: 4px solid transparent;
  }

  .slick-next:before {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-left: 6px solid white;
    border-bottom: 4px solid transparent;
  }
}
