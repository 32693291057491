@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(.70, .70);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(.70, .70);
  }
} 

.dashboard_segment_home {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
  grid-gap: 20px;
  margin: 70px 0 35px;
  .drawer_ttls_segment {
    margin-bottom: 1rem;
    min-height: 60px;
    .ttls_in_muro {
      margin-bottom: 0.2rem;
    }
    .label_ttls_segment {
      span {
        background: green;
        color: #f5f5f5;
        padding: 0.1rem 0.5rem;
        border-radius: 0.4rem;
        font-size: 0.95rem;
      }
    }
  }
}

.drawer_content_segment {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  .item_segment_home {
    text-align: center;
    text-decoration: none;
    position: relative;
    margin-bottom: 3.5rem;
    width: 50%;
    .dot_alert{
      width: 25px;
      height: 25px;
      background: crimson;
      position: absolute;
      top: 10px;
      right: 25px;
      border-radius: 50%;
      border: 3px solid #f5f5f5;
      box-shadow: 1px 2px 10px rgba(0,0,0,.25);
      animation: zoom-in-zoom-out 1s ease infinite;
    }
    &:hover{
      .bg_img_segment{
        img{
          transform: scale(1.2);
        }
      }
    }
    .bg_img_segment{
      background: #f5f5f5;
      width: 180px;
      height: 180px;
      border-radius: 50%;
      margin-bottom: .5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      img{
        max-height: 130px;
      }
    }
  }
}