@import "../../assets/theme/vars";

.interest-group {
  &-container {}
  &-header {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: $white;
    border-radius: 1rem;
    position: relative;
    height: 250px;
    margin-bottom: 4rem;
    .imgs {
      width: 100%;
      height: 300px;
      position: relative;
      img {
        width: auto;
        height: 300px;
        position: absolute;
        right: 0;
        bottom: 1.5rem;
      }
    }
    .title {
      width: 40%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      h3 {
        text-align: center;
        background: $gradient-green;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 2rem;
      }
    }
  }
  &-cards {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
}

.card-interest-group {
  position: relative;
  >.front,
  >.back {
    display: block;
    transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
    transition-duration: .5s;
    transition-property: transform, opacity;
  }
  >.front {
    width: 300px;
    height: 220px;
    border-radius: 1rem;
    background: $white;
    transform: rotateY(0deg);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 100%;
      background: $white;
      padding: .2rem;
      box-shadow: 0 0 .5rem rgba(0,0,0,.2);
    }
    h4 {
      width: 250px;
      max-width: 250px;
      padding-top: .5rem;
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }
  >.back {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 310px;
    height: 230px;
    border-radius: 1.5rem;
    background: linear-gradient(
                  to right,
                  $skyblue, 
                  $blue, 
                  $darkblue
                );
    transform: rotateY(-180deg);
    .info {
      width: 295px;
      height: 215px;
      background: #ffffff;
      padding: 1rem;
      margin: 7px;
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      h4 {
        width: 240px;
        font-size: 1rem;
        line-height: 1rem;
        margin-bottom: 0;
        white-space: pre-wrap;
        padding-bottom: .5rem;
      }
      p {
        font-size: 1rem;
        line-height: 1rem;
        padding: .5rem 0;
        word-wrap: break-word;
        span {
          margin-right: .5rem;
        }
      }
      h6 {
        background: $dark;
        padding: .3rem .5rem .1rem .5rem;
        color: $white;
        margin-bottom: .5rem;
        border-radius: .5rem;
      }
      .social-media {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .link {
          width: 35px;
          height: 35px;
          margin-right: .5rem;
          border-radius: 100%;
          background: $dark;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $white;
          font-size: 1.5rem;
          transition: .2s ease-in-out;
          &:hover {
            color: $dark;
            background: $white;
            box-shadow: 0 0 .3rem rgba(0,0,0,.3);
          }
        }
      }
    }
  }
  &:hover {
    >.front {
      transform: rotateY(180deg);
    }
    >.back {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }
  &.flip-vertical {
    >.back {
      transform: rotateX(-180deg);
    }
    &:hover {
      >.front {
        transform: rotateX(180deg);
      }
      >.back {
        transform: rotateX(0deg);
      }
    }
  }
}
