.btn {
  width: max-content;
  height: max-content;
  min-width: 120px !important;
  &-primary {
    min-width: 150px;
    height: 40px;
    background: $light;
    border-radius: 50px;
    display: flex;
    border: .1rem solid $light;
    justify-content: space-between;
    align-content: center;
    padding: .3rem .25rem;
    transition: .2s ease-in-out;
    p {
      font-family: "is_book", sans-serif;
      font-size: 1rem;
      padding: 0 1rem;
      line-height: 2rem;
    }
    i {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0,0,0,0);
      border-radius: 50px;
      color: $dark;
      margin-right: .5rem;
    }
    &.sm {
      min-width: 100px;
      height: 35px;
      p {
        font-size: .9rem;
        padding: 0 .5rem;
        line-height: 1.5rem;
      }
      i {
        width: 25px;
        height: 25px;
      }
    }
    &.orange {
      background: $orange;
      border: .1rem solid $orange;
      p { color: $white; }
      i { color: $white; }
      &:hover {
        background: $white;
        box-shadow: 0 0 .3rem rgba(0,0,0,.2);
        p {color: $dark;}
        i {background: $orange;}
      }
    }
  }
  &-white {
    width: 35px !important;
    height: 35px !important;
    min-width: 35px !important;
    border: .15rem solid $dark;
    border-radius: .5rem !important;
    background: $white;
    color: $dark;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s ease-in-out;
    &:hover {
      background: $dark !important;
      color: $white !important;
    }
    &:before {
      border-radius: .5rem !important;
    }
    &.sm {
      width: max-content !important;
      min-width: max-content !important;
    }
  }
  &-close-modal {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: rgba(0,0,0,0);
    font-size: 1.5rem;
    color: $white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: -80px;
    transition: .3s ease-in-out;
    &:hover {
      background: $white;
      color: $dark;
    }
  }
  &-square {
    width: max-content;
    height: 80px;
    background: $white;
    border-radius: 0.5rem;
    display: flex;
    padding: .3rem .5rem;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    transition: .3s ease-in-out;
    &.green {
      background: $white;
      border: .1rem solid $light;
      i {
        font-size: 1.2rem;
        background: $gradient-green;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
      }
      p {
        text-align: center;
        font-size: .9rem;
        line-height: .9rem;
        font-family: "is_book", sans-serif;
        background: $gradient-green;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
      }
    }
    span {
      font-size: 1.5rem;
      color: $dark;
    }
    p {
      color: $dark;
      @media(max-width: 575px) {
        display: none;
      }
    }
    &:hover {
      text-decoration: none;
      box-shadow: 0 0 .3rem rgba(0,0,0,.1);
      transform: translate(0,-.2rem);
    }
    &:focus {
      text-decoration: none;
    }
  }
  &-send {
    width: max-content;
    min-width: 150px;
    padding: 0.5rem 1rem;
    height: 40px;
    color: $white;
    line-height: 1rem;
    background: $orange;
    border: 0.1rem solid $orange;
    border-radius: 50px;
    font-size: 1rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease-in-out;
    svg {
      margin-left: .5rem;
      right: 2rem;
      opacity: 0;
      position: absolute;
      transition: .1s ease-in-out;
    }
    &:hover, &:focus {
      background: $yellow;
      border-color: $yellow;
      color: $dark;
      svg {
        opacity: 1;
        right: 1rem;
      }
    }
  }
  &-dark {
    min-width: 180px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .3rem 1rem;
    background: $black;
    border-radius: .5rem;
    border: none;
    color: $white;
    text-decoration: none;
    transition: .2s ease-in-out;
    .circle {
      width: 30px;
      height: 30px;
      background: $white;
      display: flex;
      padding: 0;
      color: $white;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
    }
    i, svg {
      font-size: 1rem;
      color: $white;
    }
    p {
      font-size: .9rem;
      line-height: .9rem;
      padding-right: 1rem;
      font-weight: 400 !important;
      padding-left: 1rem;
      color: $white !important;
      margin-bottom: 0;
    }
    &:hover, &:focus {
      background: $black;
      color: $white;
      box-shadow: 0 0 .5rem rgba(0,0,0,.25);
      i {
        color: $white;
      }
      p {
        color: $white;
      }
    }
    @media (max-width: 992px) {
      max-width: max-content;
      min-width: unset;
      min-height: 40px;
      height: unset;
    }
  }
  &-gradients {
    min-width: 120px !important;
    height: 40px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: .3rem;
    background: $white;
    border-radius: 50px;
    border: none;
    text-decoration: none;
    transition: .2s ease-in-out;
    .circle {
      width: 30px;
      height: 30px;
      background: $dark;
      color: $white;
      display: flex;
      padding: 0;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
    }
    p {
      font-size: 1rem;
      line-height: 1rem;
      padding-right: 1rem;
      padding-left: 1rem;
      color: $dark;
      margin-bottom: 0;
    }
    &:hover, &:focus {
      background: $dark;
      color: $white;
      box-shadow: 0 0 .3rem rgba(0,0,0,.1);
      .circle {
        background: $white;
        color: $dark;
      }
      p {
        color: $white;
      }
    }
    &-green {
      background: $gradient-green;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 0;
      .circle {
        background: $white;
        color: $green;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p {
        color: $white;
        margin-right: .5rem;
      }
      @media (max-width: 992px) {
        min-width: 100px;
        height: 40px;
        .circle {
          width: 25px;
          height: 25px;
        }
        p {
          font-size: .9rem;
          line-height: .9rem;
        }
      }
    }
    &-black {
      background: $gradient-black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 0;
      .circle {
        background: $white;
        color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p {
        color: $white;
        margin-right: .5rem;
      }
      @media (max-width: 992px) {
        min-width: 100px;
        height: 40px;
        .circle {
          width: 25px;
          height: 25px;
        }
        p {
          font-size: .9rem;
          line-height: .9rem;
        }
      }
    }
    &-orange {
      background: $gradient-orange;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 0;
      border-radius: 50px;
      min-width: 120px;
      .circle {
        background: $white;
        color: $orange;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p {
        color: $white;
        margin-right: .5rem;
      }
      @media (max-width: 992px) {
        min-width: 100px;
        height: 40px;
        .circle {
          width: 25px;
          height: 25px;
        }
        p {
          font-size: .9rem;
          line-height: .9rem;
        }
      }
    }
  }
  &-show-more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 30px !important;
    font-size: .9rem;
    border: .1rem solid $dark;
    border-radius: 50px;
    padding: .3rem 1rem;
    text-decoration: none;
    span {
      cursor: pointer;
      display: inline-block;
      position: relative;
      transition: 0.5s;
      &:after {
        content: '\00bb';
        position: absolute;
        opacity: 0;
        font-size: 1.5rem;
        top: -19px;
        right: -20px;
        transition: 0.5s;
      }
    }
    &:hover span {
      padding-right: 25px;
    }
    &:hover span:after {
      opacity: 1;
      right: 0;
    }
  }
  &-back {
    min-width: 130px;
    height: 40px;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "is_book", sans-serif;
    border-radius: .5rem;
    text-decoration: none !important;
    font-size: .9rem;
    font-weight: 600;
    background: rgba(0, 0, 0, 0);
    border: .13rem solid $black;
    color: $black !important;;
    transition: .2s ease-in-out;
    i {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0,0,0,0);
      border-radius: 50px;
      color: $dark;
      margin-right: .5rem;
    }
    p {
      padding: 0 1rem;
    }
    &:hover, &:focus {
      height: 40px;
      background: $white;
      border: .13rem solid $dark;
      padding: 0 1rem;
      text-decoration: none;
      box-shadow: 0 0 .5rem rgba(0, 0, 0, .3);
      i, svg {
        background: $white;
        border: .1rem solid $light;
      }
    }
  }
  &-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 30px;
    font-size: .9rem;
    background: rgba(0,0,0,0);
    border: .1rem solid $dark;
    border-radius: 50px;
    padding: .3rem 1rem;
    text-decoration: none;
    transition: .2s ease-in-out;
    i {
      margin-left: .5rem;
      font-size: .8rem;
      transition: .2s ease-in-out;
    }
    &:hover {
      background: $white;
      border-color: $white;
      box-shadow: 0 0 .5rem rgba(0,0,0,.2);
    }
  }
  &-outline {
    &-dark {
      width: max-content !important;
      min-width: 100px !important;
      height: 40px;
      white-space: break-spaces;
      padding: .5rem .3rem !important;
      border-radius: 50px !important;
      background: rgba(0,0,0,0);
      text-decoration: none;
      display: flex;
      justify-content: space-between !important;
      align-items: center;
      border: .12rem solid $black !important;
      color: $black;
      font-size: .9rem;
      font-family: "is_book", sans-serif;
      transition: .2s ease-in-out;
      .circle {
        width: 30px;
        height: 30px;
        background: $white;
        color: $dark;
        display: flex;
        padding: 0;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
      }
      p {
        margin-right: .5rem;
        padding-left: .3rem;
      }
      &:hover {
        height: 40px;
        background: $dark;
        border-radius: 50px !important;
        box-shadow: 0 0 .5rem rgba(0,0,0,.2);
        color: $white;
        text-decoration: none;
        border: .1rem solid $black !important;
     }
      &.close {
        width: 50px;
        min-width: 50px !important;
        height: 50px;
        color: $white;
        font-size: 1.5rem;
        border: 0;
        padding: 0;
        background: rgba(0,0,0,0);
        position: absolute;
        top: -4rem;
        right: 0;
      }
    }
    &-white {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      min-width: 100px;
      height: 35px !important;
      background: rgba(0,0,0,0) !important;
      font-size: .9rem;
      color: $white !important;
      border: .1rem solid $white !important;
      border-radius: 50px;
      padding: .2rem 1rem;
      text-decoration: none;
      &:hover {
        background: rgba(0,0,0,0) !important;
        border: .1rem solid $white !important;
        color: $white !important;
      }
      span {
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: 0.5s;
        &:after {
          content: '\00bb';
          position: absolute;
          opacity: 0;
          font-size: 1.5rem;
          top: -19px;
          right: -20px;
          transition: 0.5s;
        }
      }
      &:hover span {
        padding-right: 25px;
      }
      &:hover span:after {
        opacity: 1;
        right: 0;
      }
      &::before {
        border-radius: 50px !important;
      }
      &.green {
        &:hover {
          background: $white !important;
          border: .1rem solid $white !important;
          color: $green !important;
        }
      }
    }
    &-yellow {
      width: max-content;
      height: max-content !important;
      font-size: .9rem;
      padding: .3rem 1rem !important;
      color: $black;
      border-radius: .3rem !important;
      background: rgba(0,0,0,0);
      border: .1rem solid $black;
      margin-top: 1rem;
      &:hover {
        box-shadow: 0 0 .5rem rgba(0,0,0,.1) !important;
        background: rgba(0,0,0,0);
        color: $dark;
        border: .1rem solid $dark;
      }
      &:before {
        border-radius: .3rem !important;
      }
    }
    &-green{
      width: max-content;
      height: max-content !important;
      font-size: .9rem;
      padding: .3rem 1rem !important;
      color: $green;
      text-decoration: none;
      display: flex;
      border-radius: 25px !important;
      background: rgba(0,0,0,0);
      border: .1rem solid $green;
      &:hover {
        box-shadow: 0 0 .5rem rgba(0,0,0,.1) !important;
        background: rgba(0,0,0,0);
        color: $green;
        border: .1rem solid $green;
      }
      &:before {
        border-radius: .3rem !important;
      }
      svg {
        margin-left: .5rem;
      }
    }
  }
  &-gradient {
    &-orange {
      width: max-content;
      min-height: 50px;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: $orange;
      border-radius: .7rem;
      border: .1rem solid $light;
      padding: .5rem 1rem;
      transition: .2s ease-in-out;
      p {
        color: $orange;
      }
      span {
        width: 25px;
        height: 25px;
        background: rgba(0,0,0,0);
        border: .1rem solid $orange;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: .5rem;
        transition: .2s ease-in-out;
        i {
          font-size: .6rem;
          margin-top: .1rem;
          transition: .2s ease-in-out;
        }
      }
      &:hover {
        box-shadow: 0 0 .25rem rgba(0,0,0,.1);
        span {
          width: 25px;
          height: 25px;
          background: $gradient-orange;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          border: 0;
          align-items: center;
          margin-left: .5rem;
          i {
            color: $white;
          }
        }
      }
    }
    &-music {
      width: 180px;
      height: 40px;
      border-radius: .5rem;
      padding: .5rem 1rem;
      display: flex;
      text-decoration: none;
      justify-content: center;
      align-items: center;
      color: $white;
      background: $dark;
      span {
        svg { color: $white; font-size: 2rem; transition: .2s ease-in-out; }
      }
      p {
        margin-left: 1rem;
      }
      &:hover {
        background: $gradient-red;
        p {
          color: $white;
        }
        span {
          svg { font-size: 2rem; }
        }
      }
    }
    &-green{
      width: max-content;
      min-height: 40px;
      text-transform: capitalize;
      background: $gradient-green;
      border-radius: .7rem !important;
      padding: .5rem 1rem !important;
      border: 0;
      text-decoration: none;
      color: $white;
      margin: 1rem 0;
      span {
        color: $white;
      }
      &:hover {
        box-shadow: 0 0 .2rem rgba(0,0,0,.3);
      }
      &:before {
        border-radius: .7rem !important;
      }
    }
  }
  &-like {
    width: max-content;
    height: 30px;
    padding: .25rem .3rem;
    border-radius: .5rem;
    background: $white;
    border: .1rem solid $light-gray;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: $dark;
    p {
      font-size: .9rem;
    }
    i {
      margin-right: .5rem;
    }
    &.red {
      p {
        color: $dark;
      }
      i {
        color: $red;
        margin-right: .5rem;
      }
    }
    &:hover {
      background: $light;
    }
    &:focus {
      background: $white;
    }
  }
  &-send {
    &-comment {
      width: 50px;
      height: 50px;
      border-radius: .5rem;
      background: $gradient-red;
      color: $white;
      margin-left: .5rem;
      transition: .2s ease-in-out;
      i { transition: .2s ease-in-out}
      &:hover {
        box-shadow: 0 0 .5rem rgba(0,0,0,.2);
        i {
          transform: scale(1.3);
        }
      }
    }
  }
  &-dots {
    width: 50px !important;
    height: 50px !important;
    padding: 0 !important;
    background: rgba(0,0,0,0);
    border: 0;
    color: $black;
    font-size: 1.7rem;
    border-radius: .5rem;
    &:hover, &:focus {
      background: $light;
      color: $black;
    }
    &-link {
      width: 200px;
      padding: .5rem 1rem;
      border-radius: .5rem;
      display: flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      transition: .2s ease-in-out;
      span {margin-right: 1rem}
      &:hover {
        background: $light;
      }
    }
  }
  &-publication {
    width: 100%;
    min-height: 40px;
    background: $gradient-green;
    border-radius: .5rem !important;
    border: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .2s ease-in-out;
    p {
      text-transform: capitalize;
    }
    &:hover {
      border: 0;
      background: $gradient-green;
      box-shadow: 0 0 .5rem rgba(0,0,0,.3);
    }
    &::before {
      border-radius: .5rem !important;
    }
    &-white {
      width: 100%;
      min-height: 40px;
      background: $white;
      color: $black;
      border-radius: .5rem !important;
      border: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.5rem;
      transition: .2s ease-in-out;
      p { font-size: 1rem; text-transform: capitalize;}
      &:hover {
        border: 0;
        background: $white;
        color: $black;
        box-shadow: 0 0 .5rem rgba(0,0,0,.3);
      }
      &::before {
        border-radius: .5rem !important;
      }
    }
    &-secondary {
      width: 100%;
      min-height: 40px;
      background: $light-gray;
      color: $black;
      border-radius: .3rem !important;
      border: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.5rem;
      transition: .2s ease-in-out;
      p { font-size: 1rem; text-transform: capitalize;}
      &:hover {
        border: 0;
        background: $light-gray;
        color: $black;
        box-shadow: 0 0 .5rem rgba(0,0,0,.3);
      }
      &::before {
        border-radius: .5rem !important;
      }
    }
  }
  &-upload-file {
    width: max-content;
    background: $white;
    border: .1rem solid $dark;
    border-radius: .5rem;
    color: $dark;
    cursor: pointer;
    padding: .5rem 1rem;
    svg {
      margin-right: .5rem;
    }
    &:hover, &:focus {
      background: $dark;
      color: $white !important;
    }
  }
}

.button {
  &-circle {
    width: 40px !important;
    height: 40px !important;
    min-width: unset !important;
    border-radius: 50px;
    background: $white;
    border: 0;
    color: $dark;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    &.share {
      .rs-dropdown-toggle {
        width: 100%;
        height: 100%;
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        color: $gray;
        border-radius: 50px;
        &:hover, &:focus {
          box-shadow: 0 0 .3rem rgba(0,0,0,.1);
          background: $white;
          cursor: pointer;
          .rs-dropdown-toggle {
            color: $dark;
          }
        }
      }
    }
    &.notifications {
      .rs-dropdown-toggle {
        width: 100%;
        height: 100%;
        display: flex;
        overflow: initial;
        border-radius: 50px;
        justify-content: center;
        align-items: center;
        padding: 0;
        position: relative;
        font-size: 1.5rem;
        color: $gray;
        &:hover, &:focus {
          background: $white;
          box-shadow: 0 0 .3rem rgba(0,0,0,.1);
          .rs-dropdown-toggle {
            color: $dark;
          }
        }
      }
      .quantity {
          position: absolute;
          z-index: 2;
          color: $dark;
          background: $light;
          border-radius: 0.25rem;
          width: 25px;
          max-width: 30px;
          top: -.5rem;
          font-size: 0.8rem;
          line-height: 0;
          right: -1rem;
          height: 15px;
          font-family: "is_bold", sans-serif;
          display: flex;
          justify-content: center;
          align-items: center;
      }
    }
  }
  &-outline-gradient {
    width: max-content !important;
    min-height: 30px;
    border-radius: 25px !important;
    padding: .5rem 3rem !important;
    color: $black;
    text-decoration: none;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5) !important;
    border: solid 3px transparent !important;
    background-image: linear-gradient(to right,#F5B84A, #AD4386, #3FA7D9,#44A59C,#F5B84A) !important;
    background-origin: border-box !important;
    box-shadow: 2px 1000px 1px #fff inset !important;
    transition: .2s ease-in-out;
    &:hover {
      box-shadow: unset !important;
      color: $white;
      text-decoration: none;
    }
  }
}
