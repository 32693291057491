@import "../../assets/theme/vars";
.messages-file-container{
  font-size: 5rem;
  color: #000000;
}
.tabs-messages {
  &-container {
    .tabs-links {
      width: max-content;
      min-width: unset;
      @include scrollbar_horizontal;
      min-height: 40px;
      overflow: auto;
      margin-bottom: 1rem;
      background: $white;
      border-radius: 1rem;
      display: flex;
      padding: .3rem .5rem;
      align-items: center;
      justify-content: flex-start;
      .btn-link {
        min-width: 60px;
        height: 40px;
        background: $white;
        padding: .5rem 1rem;
        text-decoration: none;
        border-radius: .5rem;
        margin-right: 1rem;
        position: relative;
        transition: .2s ease-in-out;
        i {
          color: $dark;
          font-size: 1.25rem;
          &.envelope {
            font-size: .9rem;
          }
          &.draft {
            font-size: 1rem;
          }
        }
        &:hover {
          background: rgba(0,0,0,.02);
          color: $dark;
        }
        &.active {
          &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: 3px;
            left: 0;
            opacity: .5;
            height: 3px;
            width: 100%;
            background: $whine;
            transform-origin: right top;
            transform: scale(0, 1);
            transition: color 0.1s, transform 0.2s ease-out;
          }

          &:active::before {
            background-color: #000;
          }

          &::before, &:focus::before {
            transform-origin: left top;
            transform: scale(1, 1);
          }
        }
      }
      @media(max-width: 768px) {
        width: 100%;
        justify-content: space-between;
        .btn-link.mr {
          margin-right: 0;
        }
        .btn-link {
          min-width: 40px;
          margin-right: 0;
        }
      }
    }
    .content_messages {
      background: $white;
      padding: 0 1rem 1rem 1rem;
      border-radius: 1rem;
      @media (max-width: 575px) {
        padding: 0 .5rem;
      }
      .wrapper_table {
        .td_user {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .box_avatar_user {
            margin-right: 0.5rem;
            .avatar {
              width: 35px;
              min-width: 35px;
              height: 35px;
            }
          }
        }
        .tr_msg {
          background: #ffffff;
          &.is_read {
            background: transparent;
          }
        }
        .icon_tables {
          font-size: 1.25rem;
        }
      }
    }
    .toggler-wrapper {
      display: block;
      width: 45px;
      height: 25px;
      cursor: pointer;
      position: relative;
      input[type="checkbox"] {
        display: none;
      }
      input[type="checkbox"]:checked + .toggler-slider {
        background-color: #44cc66;
      }
      .toggler-slider {
        background-color: #ccc;
        position: absolute;
        border-radius: 100px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
      }
      .toggler-knob {
        position: absolute;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
      }
      &.style-14 input[type="checkbox"]:checked+.toggler-slider .toggler-knob {
        left: calc(100% - 18.75px);
      }
      &.style-14 .toggler-knob {
        width: 12.5px;
        height: 12.5px;
        top: 50%;
        left: 6.25px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }
}

.inbox-messages {
  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
  }
  &-filters {
    width: max-content;
    display: flex;
    .dropdown {
      margin-right: 2rem;
      button {
        background: $white;
        color: $dark;
        font-family: "is_book", sans-serif;
        border-color: $white;
        border-radius: .5rem;
        span {
          color: $black;
          font-family: "is_medium", sans-serif;
        }
        &:hover, &:focus {
          background: $white;
          color: $dark;
          border-color: $white;
          box-shadow: 0 0 .2rem rgba(0,0,0,.2);
        }
      }
      &.show {
        .dropdown-menu {
          margin-top: .5rem;
          padding-bottom: 0;
          border-radius: .8rem;
          border: 0;
          box-shadow: 0 0 .2rem rgba(0,0,0,.2);
          button {
            font-size: .9rem;
            width: 100%;
            color: $dark;
            min-height: 35px;
            background: $light;
            margin-bottom: .5rem;
            transition: .2s ease-in-out;
            &:hover, &:focus {
              background: $dark;
              color: $white;
            }
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    &-header {
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
    &-filters {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      .dropdown {
        margin-right: 1rem;
        button {
          padding: 0;
          height: 40px;
          font-size: .85rem;
          span {
            font-size: .85rem;
          }
        }
      }
    }
  }
  @media (max-width: 480px) {
    &-header {
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
    }
    &-filters {
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: .5rem;
      .dropdown {
        margin-right: 0;
        padding: 0;
        margin-bottom: 0;
        button {
          margin-bottom: 0;
          height: 30px;
        }
      }
    }
  }
}

.message-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.messages {
  &-table-config {
    width: 100%;
    min-width: 1000px;
    overflow-x: scroll;
    background: $white;
    padding: 1rem !important;
    border-radius: 1rem;
    border-collapse: separate;
    border-spacing: 0 .3em;
    thead {
      tr {
        th {
          font-family: "is_medium", sans-serif;
        }
      }
      border-color: $white;
    }
    tbody {
      tr {
        border: 0;
        border-radius: .5rem;
        box-shadow: 0 0 .1rem rgba(0,0,0,.2);
        td {
          height: 69px;
          max-height: 69px;
          border: 0;
          transition: .2s ease-in-out;
          .td-center {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
          .avatar-container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .avatar {
              display: flex;
              padding: .1rem;
              background: $white;
              cursor: pointer;
              border-radius: 100%;
              margin-right: .5rem;
              box-shadow: 0 0 .3rem rgba(0,0,0,.1);
              img {
                width: 50px;
                height: 50px;
                object-fit: cover;
                border-radius: 100%;
              }
            }
            .btn-link {
              color: $dark;
              text-decoration: none;
              &:hover {
                font-family: "is_medium", sans-serif;
              }
            }
            p {
              padding-left: 1rem;
              font-size: 1rem;
            }
          }
          .link-data {
            text-decoration: none;
          }
          &:first-child {
            border-radius: .5rem 0 0 .5rem;
          }
          &:last-child {
            border-radius: 0 .5rem .5rem 0;
          }
          .d-flex-center {
            width: 100%;
            height: 100%;
          }
          .btn-icon-table {
            background: rgba(0,0,0,0);
            border-radius: .25rem;
            transition: .2s ease-in-out;
            &:hover {
              color: $dark;
              background: $white;
              box-shadow: 0 0 .2rem rgba(0,0,0,.1);
              &.delete {
                color: $red;
              }
              &.save {
                color: $green;
              }
            }
          }
        }
      }
    }
  }
  &-table {
    width: 100%;
    min-width: 1000px;
    overflow-x: scroll;
    background: $white;
    padding: .5rem;
    border-radius: 1rem;
    display: flex;
    @media (max-width: 575px) {
      padding: 0;
    }
    ul {
      width: max-content;
      list-style: none;
      &.isShow {
        display: flex;
      }
      &.isHide {
        display: unset;
      }
      li {
        width: 100%;
        display: flex;
        border: 0;
        border-radius: .5rem;
        box-shadow: 0 0 .1rem rgba(0,0,0,.2);
        margin-bottom: .25rem;
        :last-child {
          margin-bottom: 0;
        }
        .message-empty {
          width: 500px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 1rem .5rem;
          .icon-empty {
            width: 100px;
            height: 100px;
            font-size: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          p {
            font-size: .9rem;
            font-family: "is_medium", sans-serif;
          }
        }
        .msg {
          .btn-message {
            background: $light;
            border-left: .2rem solid $green;
            border-radius: .25rem .5rem .5rem .25rem;
          }
          &.readed {
            .btn-message {
              background: $white;
              border-left: 0;
              border-radius: .5rem;
            }
          }
          .btn-message {
            width: 500px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-decoration: none;
            transition: .2s ease-in-out;
            .avatar-container {
              width: max-content;
              .avatar {
                width: 50px;
                height: 50px;
                display: flex;
                padding: .1rem;
                background: $white;
                cursor: pointer;
                border-radius: 50px;
                margin-right: .5rem;
                box-shadow: 0 0 .3rem rgba(0,0,0,.1);
                img {
                  width: 50px;
                  height: 50px;
                  object-fit: cover;
                  border-radius: 100%;
                }
              }
              p {
                padding-left: 1rem;
                font-size: 1rem;
              }
            }
            .btn-icon-table {
              background: rgba(0,0,0,0);
              border-radius: .25rem;
              transition: .2s ease-in-out;
              &:hover {
                color: $dark;
                background: $white;
                box-shadow: 0 0 .2rem rgba(0,0,0,.1);
                &.delete {
                  color: $red;
                }
                &.save {
                  color: $green;
                }
              }
            }
            .btn-link {
              width: 100%;
              display: flex;
              text-decoration: none;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              .user {
                width: 100%;
                display: flex;
                align-items: center;
                font-size: .9rem;
                justify-content: space-between;
              }
              span {
                font-size: .8rem;
              }
            }
            &:hover {
              background: $light;
              .btn-link {
                color: $dark;
              }
            }
            @media (max-width: 360px) {
              padding: .15rem .2rem;
              .avatar-container {
                .avatar {
                  width: 30px;
                  height: 30px;
                  padding: 0;
                  justify-content: center;
                  align-items: center;
                  img {
                    width: 26px;
                    height: 26px;
                  }
                }
              }
              .btn-link {
                width: max-content;
                p {
                  width: 140px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                span {
                  width: 190px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
    @media(max-width: 992px) {
      min-width: unset;
      overflow-x: unset;
      ul {
        width: 100%;
        &.isShow {
          display: flex !important;
        }
        &.isHide {
          display: none !important;
        }
        li {
          .msg {
            width: 100%;
            .btn-message {
              width: 100% !important;
            }
          }
        }
      }
      .messages-readed {
        display: none;
        &.isShow {
          display: flex !important;
        }
        &.isHide {
          display: none !important;
        }
      }
    }
  }
  &-readed {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: .5rem .5rem;
    &-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: .5rem;
      h2 {
        margin: 0;
      }
      .btn-circle-message {
        display: none;
      }
      @media(max-width: 992px) {
        .btn-circle-message {
          display: flex;
        }
      }
    }
    &-content {
      width: 100%;
      .empty {
        width: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem .25rem;
        margin: 0 auto;
        i {
          font-size: 4rem;
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "is_medium", sans-serif;
          text-align: center;
          i {
            font-size: .9rem;
            width: 30px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            margin-right: .5rem;
            border: .1rem solid $light;
          }
        }
      }
      .messages-info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        border: .1rem solid $light;
        padding: .5rem;
        border-radius: 1rem;
        &-header {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          padding: .5rem;
          @media (max-width: 575px) {
            .profile {
              padding: 0;
            }
          }
          .user {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: rgba(0,0,0,.01);
            padding: 0 .5rem;
            border-radius: 1rem;
            .info {
              width: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              flex-direction: column;
              padding: .5rem 0;
              .avatar-container {
                width: max-content;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                img {
                  width: 48px;
                  height: 48px;
                  object-fit: cover;
                  border-radius: 50px;
                  padding: .15rem;
                  background: $light;
                }
                .name {
                  width: max-content;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: center;
                  @media (max-width: 575px) {
                    display: none;
                  }
                  label {
                    font-family: "is_medium" ,sans-serif;
                    font-size: 1rem;
                    line-height: 1rem;
                    margin-left: .5rem;
                  }
                  p {
                    width: max-content;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: .5rem;
                    font-size: .85rem;
                    color: $gray;
                    i {
                      margin-right: .5rem;
                    }
                  }
                }
              }
            }
            @media (max-width: 575px) {
              padding: 0;
            }
          }
          h4 {
            font-size: 21px;
            font-family: "is_medium", sans-serif;
            @media (max-width: 575px) {
              font-size: 1rem;
            }
          }
          .date {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            p {
              display: flex;
              padding: 0 .5rem 0 1rem;
              i {
                margin-right: .5rem;
              }
            }
          }
          .btn-circle-message.dots {
            display: none;
          }
          @media(max-width: 575px) {
            .user {
              .info {}
              .btn-circle-message {
                display: none;
              }
              .btn-circle-dots {
                width: 35px;
                border-radius: 50px;
                height: 35px;
                color: $dark;
                background: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover {
                  background: $light;
                  $color: $white;
                }
              }
              .btn-circle-message.dots {
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                .rs-dropdown-menu {
                  width: 135px;
                  gap: .3rem;
                  padding: .5rem;
                  li {
                    margin: 0;
                    padding: 0;
                  }
                  .rs-dropdown-item {
                    a {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding: .25rem .5rem;
                      i {
                        margin-right: .5rem;
                      }
                    }
                  }
                }
                .rs-dropdown-toggle-caret {display: none;}
                a.rs-dropdown-toggle {
                  width: 35px;
                  max-width: 35px;
                  height: 35px;
                  display: flex;
                  align-items: center;
                  padding: 0;
                  justify-content: center;
                  font-size: .85rem;
                  border-radius: 50px;
                  i {
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
        .messages-info-attachment {
          width: 100%;
          display: flex;
          flex-direction: column;
          .files {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: .5rem;
            padding: .5rem;
            .item-file {
              width: 70px;
              height: 60px;
              position: relative;
              .item {
                display: flex;
                flex-direction: column;
                width: 70px;
                height: 60px;
                border: .1rem solid $light;
                background: $white;
                border-radius: .5rem;
                text-decoration: none;
                justify-content: center;
                align-items: center;
                p {
                  font-size: .7rem;
                  text-decoration: none;
                }
                &:hover {
                  background: $light;
                }
              }
              .btn-delete {
                position: absolute;
              }
            }
          }
          @media(max-width: 575px) {
            .files {
              .item-file {
                width: 50px;
                height: 50px;
                .item {
                  width: 50px;
                  height: 50px;
                  p {
                    display: none;
                  }
                }
                .btn-delete {
                  position: absolute;
                }
              }
            }
          }
        }
        .messages-info-body {
          width: 100%;
          background: $white;
          padding: .5rem;
        }
      }
    }
  }
  &-answer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border: .1rem solid $light;
    padding: 1.5rem;
    border-radius: 1rem;
    background: $white;
    margin-top: 1rem;
    &-content {
      width: 100%;
      h4 {
        margin-bottom: 1rem;
        i {
          margin-right: .5rem;
        }
      }
    }
  }
  &-parent {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border: .1rem solid $light;
    padding: .5rem;
    border-radius: 1rem;
    background: rgba(0,0,0,.03);
    margin-top: 1rem;
    &-adjunts {
      width: 100%;
      background: #00AD42;
    }
    &-content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 1rem;
      background: rgba(0,0,0,0);
      h4 {
        font-size: 21px;
        font-family: "is_medium", sans-serif;
        i {
          margin-right: .5rem;
        }
        @media (max-width: 575px) {
          font-size: 1rem;
        }
      }
      .user {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .info {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          padding: .5rem 0;
          .avatar-container {
            width: max-content;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 48px;
              height: 48px;
              object-fit: cover;
              border-radius: 50px;
              padding: .15rem;
              background: $light;
            }
            .name {
              width: max-content;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              @media (max-width: 575px) {
                display: none;
              }
              label {
                font-family: "is_medium" ,sans-serif;
                font-size: 1rem;
                line-height: 1rem;
                margin-left: .5rem;
              }
              p {
                width: max-content;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: .5rem;
                font-size: .85rem;
                color: $gray;
                i {
                  margin-right: .5rem;
                }
              }
            }
          }
        }
      }
    }
    &-body {
      width: 100%;
      border-radius: 1rem;
      padding: 1rem;
    }
  }
  &-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 992px) {
      flex-direction: row;
    }
    @media (max-width: 768px) {
      .btn-back {
        i {
          margin-right: 0;
        }
      }
    }
  }
  &-file {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    &-container {
      width: 100%;
      border-radius: 1rem;
      border: .1rem dashed $light-gray;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: .5rem;
      background: $white;
      position: relative;
      .btn-remove-file {
        width: 50px;
        height: 50px;
        background: $white;
        border: 0;
        color: $red;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        align-items: center;
        transition: .2s ease-in-out;
        &:hover {
          background: $red;
          color: $white;
        }
      }
      .img-file {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 1rem;
      }
    }
  }
}

.btn-circle-message {
  border-radius: 50px;
  width: 35px;
  height: 35px;
  background: $white;
  color: $dark;
  font-size: 1rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .2s ease-in-out;
  &:hover {
    background: $light-gray;
    color:$dark;
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
}

/* este modifica los archivos adjuntos de nuevo mensaje */
.messages-info-attachment {
    width: 100%;
    display: flex;
    flex-direction: column;
    .files {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: .5rem;
      padding: .5rem;
      .item-file {
        width: 70px;
        height: 60px;
        position: relative;
        .item {
          display: flex;
          flex-direction: column;
          width: 70px;
          height: 60px;
          border: .1rem solid $light;
          background: $white;
          border-radius: .5rem;
          text-decoration: none;
          justify-content: center;
          align-items: center;
          p {
            font-size: .7rem;
            text-decoration: none;
          }
          &:hover {
            background: $light;
          }
        }
        .btn-delete {
          position: absolute;
          right: -1rem;
          top: -1rem;
          font-size: .85rem;
          border-radius: 50px;
          transition: .2s ease-in-out;
          &:hover {
            color: $white;
            background: $red;
          }
        }
      }
    }
    @media(max-width: 575px) {
      .files {
        .item-file {
          width: 50px;
          height: 50px;
          .item {
            width: 50px;
            height: 50px;
            p {
              display: none;
            }
          }
          .btn-delete {
            position: absolute;
          }
        }
      }
    }
  }
