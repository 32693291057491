@import "../../../assets/theme/vars";

.provider {
  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    &-logo {
      width: 150px;
      height: auto;
      border-radius: 1rem;
      padding: .2rem;
      background: $white;
      transition: .2s ease-in-out;
      img {
        border-radius: 1rem;
      }
      @media (max-width: 768px) {
        width: 120px;
      }
    }
  }
}

.wrapper_provider {
  @include bggrey;
  min-height: calc(100vh - 4rem);
  .box__providers {
    .box_logo_shop {
      img {
        height: 50px;
      }
    }
  }
  .info_media {
    margin-top: 1rem;
  }
}

.item-promo {
  width: 300px;
  max-width: 300px;
  margin-bottom: 2rem;
  position: relative;
  &-img {
    padding: 0 0.5rem;
    border-radius: 1rem 1rem 0 0;
    img {
      border-radius: 1rem 1rem 0 0;
    }
  }
  &-info {
    background: $white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 0.45rem;
    padding: 0.75rem;
    position: relative;
    margin-top: -.25rem;
    .name {
      display: flex;
      justify-content: space-between;
      font-size: 1rem;
      min-height: 55px;
      p {
        font-family: "is_medium", sans-serif;
      }
      button{
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 50px;
        background: $light;
        color: $gray;
        i {
          font-size: 1.2rem;
        }
        &:hover {
          background: $white;
          color: $dark;
        }
      }
    }
    .btn_info_promo {
      position: absolute;
      top: 9px;
      right: 9px;
      color: #dcdcdc;
      cursor: pointer;
      font-size: 1rem;
      &:hover {
        background: $dark;
      }
    }
    .price-add {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .price {color: $dark;}
      .btn-add {
        cursor: pointer;
        background: $dark;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50px;
        font-size: 1.1rem;
        transition: .2s ease-in-out;
        &:hover {
          background: $white;
          color: $dark;
          box-shadow: 0 0 .5rem rgba(0,0,0,.1);
        }
      }
    }
  }
}

.content_tooltip {
  display: none;
  background: $white;
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.15);
  padding: 1rem 1.15rem 0.75rem 0.75rem;
  border-radius: 0.3rem;
  font-size: 0.7rem;
  position: absolute;
  top: 0;
  left: 5%;
  width: 90%;
  z-index: 9;
  text-align: justify;

  .btnclose_info {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 1rem;
    color: var(--primary-color);
    cursor: pointer;
  }

  &.is-show {
    display: block;
  }
}

.items-promo{
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  position: relative;
}
