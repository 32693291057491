@import "../../assets/theme/vars";

.card-directory {
  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  width: 100%;
  height: 175px;
  background: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  position: relative;
  &-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    &-info {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      border-right: 0.1rem solid $light;
      h5 {
        font-family: "is_bold", sans-serif;
        padding-top: 0 !important;
        padding-left: 0 !important;
      }
      p {
        padding-top: 1rem;
        font-size: 1rem;
      }
    }
    &-extra {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding-left: 1rem;
      .category {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
      }
      .location {
        font-size: 0.9rem;
        p {
          font-family: "is_medium", sans-serif;
        }
        .ranking {
          font-family: "is_book", sans-serif;
        }
      }
      span {
        font-size: 0.9rem;
        &.badge-directory {
          width: max-content;
          padding: 0.25rem 0.5rem;
          border-radius: 0.5rem;
          color: $black;
          background: $gradient-black;
        }
      }
    }
  }
  &-thumbnail {
    width: 400px;
    height: 175px;
    border-radius: 1rem 0 0 1rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .img {
      position: absolute;
      width: 100%;
      height: 100%;
      filter: blur(0.5rem);
      transform: scale(2);
    }
    img {
      width: 100%;
      height: auto;
      margin: 0 auto;
      border-radius: 1rem 0 0 1rem;
      position: absolute;
      z-index: 2;
      transition: 0.2s ease-out;
    }
  }
  &:hover .card-directory-thumbnail img {
    opacity: 1;
    transform: scale(1.2);
  }
  &-rewards {
    width: 35px;
    height: 35px;
    background: rgba(255,255,255,.5);
    position: absolute;
    top: .5rem;
    left: .5rem;
    z-index: 5;
    padding: .2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: .2s ease-in-out;
    img {
      width: 30px;
      height: 30px;
      transition: .2s ease-in-out;
    }
    &:hover {
      background: $white;
      img {
        width: 32px;
        height: 30px;
      }
    }
  }
}

.directory {
  margin-top: 2rem;
  &-container {
    h5 {
      padding-left: 1rem;
      padding-top: 2rem;
    }
  }
  &-aside {
    padding-top: 2rem;
  }
  &-card-ad {
    width: 100%;
    height: 250px;
    background: $gradient-black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-radius: 1rem;
    padding: 1.5rem 0.5rem;
    p {
      color: $white;
      padding: 1rem;
    }
    h3 {
      color: $white;
      line-height: 1.5rem;
    }
    .btn-outline-white {
      width: unset;
      transition: 0.2s ease-in-out;
      color: $black;
      svg {
        opacity: 0;
        transition: 0.2s ease-in-out;
      }
      &:hover {
        background: $white ;
        color: $black;
        svg {
          opacity: 1;
          margin-left: 1rem;
          color: $white;
        }
      }
    }
  }
}

.card-directory-detail {
  width: 100%;
  height: auto;
  background: $white;
  display: flex;
  justify-content: space-between;
  border-radius: 1rem;
  padding: 1rem;
  gap: 20px;
  .thumb {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
    .title {
      width: 100%;
      margin-bottom: 1rem;
      .tags {
        .category {
          background: $green;
          border-radius: 0.5rem;
          padding: 0.2rem 0.3rem;
          color: $white;
          font-size: 0.9rem;
        }
        .subcategory {
          background: $light;
          font-size: 0.8rem;
          padding: 0.2rem 0.3rem;
          border-radius: 0.5rem;
          margin-top: -0.2rem;
        }
      }
    }
    .img-detail {
      width: max-content;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: 2rem auto 0 auto;
      .thumb {
        a.rewards {
          position: absolute;
          top: .5rem;
          left: .5rem;
          background: rgba(255,255,255,.5);
          border-radius: 50px;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: .2s ease-in-out;
          img {
            width: 30px;
            height: 30px;
          }
          &:hover {
            background: $white;
          }
        }
        img {
          max-height: 325px;
          border-radius: 1rem;
          transition: .2s ease-in-out;
          @media (max-width: 768px) {
            max-height: 225px;
          }
          @media (max-width: 480px) {
            max-height: 170px;
          }
        }
      }
    }
  }
  .info {
    width: 100%;
    height: 100%;

    .content {
      width: 100%;
      display: flex;
      gap: 25px;
      align-items: flex-start;
      .content_info_a,
      .content_info_b {
        width: 100%;
      }
      h5 {
        padding: 0.3rem 0.6rem;
        background: #eeeeee;
        border-radius: 0.3rem;
        font-size: 1rem;
        width: 100%;
      }
      .data {
        width: 100%;
        height: 100%;
        &-items {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 1rem 0;
          .item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: 0.5rem;
            .icodata {
              background-color: $green;
              width: 27px;
              height: 27px;
              font-size: 0.85rem;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 0.5rem;
              color: #fefefe;
            }
            a {
              text-decoration: none;
              &:hover {
                text-decoration: underline;
                color: $green;
              }
            }
          }
        }
        .social-media {
          width: 100%;
          display: flex;
          list-style: none;
          li {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: .5rem;
            &:last-child {
              margin-right: .5rem;
            }
            span {
              font-family: "is_bold";
              font-size: 0.85rem;
            }
            a {
              width: 30px;
              height: 30px;
              background: $dark;
              display: flex;
              border-radius: 100%;
              justify-content: center;
              align-items: center;
              color: $white;
              &:hover {
                background: $green;
              }
            }
          }
        }
        &-ranking {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column-reverse;
          padding: 0.5rem;
          .services {
            width: 100%;
            display: flex;
            flex-direction: column;
            .service {
              width: 100%;
              display: flex;
              align-items: center;
              padding: 0.25rem 0;
              p {
                margin-right: 1rem;
              }
            }
          }
          &-tooltip {
            width: 100%;
            display: flex;
            flex-direction: column;
            .item{
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 10px;
              margin-bottom: 1rem;
            }
          }
          .btns {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 0.5rem;
            button {
              width: max-content;
              min-width: 80px;
              height: 30px;
              min-height: 30px;
              padding: 0.3rem 0.5rem;
              border-radius: 0.5rem;
              background: $dark;
              line-height: 0;
              color: $white;
              font-size: 0.9rem;
              margin-bottom: 0.5rem;
              transition: 0.3s ease-in-out;
              i {
                margin: 0 0.5rem;
              }
              &:hover {
                box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .card-directory-detail .thumb {
    max-width: 400px;
  }
}

@media screen and (max-width: 560px) {
  .card-directory-detail .info .content {
    flex-direction: column;
  }
}
