@import "../../assets/theme/vars";

.register {
  &-wrapper {
    width: 100%;
    min-height: 100vh;
    background: $white;
    transition: .25s ease-in-out;
  }
  &-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    transition: .25s ease-in-out;
    &-img {
      width: 600px;
      min-height: 100vh;
      background-image: url('../../assets/imgs/bg-register.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 0 3rem 3rem 0;
      transition: .25s ease-in-out;
      .bg-gradients {
        width: 100%;
        height: 100%;
        border-radius: 0 3rem 3rem 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: relative;
        transition: .25s ease-in-out;
        background:linear-gradient(rgba(160,28,25,.5), rgba(211,199,162,.5));
        h1 {
          font-family: "is_black_italic", sans-serif;
          font-size: 42px;
          color: $white;
          text-align: center;
          top: 5rem;
          left: 0;
          text-shadow: 0 0 .3rem rgba(0,0,0,.3);
          right: 0;
          margin: 0 auto;
          position: relative;
          transition: .25s ease-in-out;
        }
        .login-footer-legals {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 1rem;
          ul {
            width: max-content;
            margin: 0 auto;
            display: flex;
            list-style: none;
            li {
              margin-right: 1rem;
              &:last-child {
                margin-right: 0;
              }
              a {
                width: max-content;
                height: max-content;
                text-decoration: none;
                color: $white;
                font-size: .9rem;
                transition: .25s ease-in-out;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
    &-form {
      width: 65%;
      min-height: 100vh;
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      transition: .25s ease-in-out;
      &-header {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1.5rem 2rem;
        transition: .25s ease-in-out;
        .logo {
          width: 180px;
          height: auto;
          transition: .25s ease-in-out;
        }
      }
      &-header-recover {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1.5rem 2rem;
        transition: .25s ease-in-out;
       .logo {
         width: 180px;
         height: auto;
         transition: .25s ease-in-out;
       }
      }
      &-notice {
        max-width: 600px;
        margin: 0 .5rem;
        background: $white;
        border-radius: 1rem;
        padding: 1rem 2rem;
        box-shadow: 0 0 .5rem rgba(0,0,0,.1);
        .content-notice {
          &-header {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            h5 {
              color: $green;
              font-size: 1.2rem;
              margin-bottom: 1rem;
              &.error {
                color: $red;
              }
            }
            .checkmark {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              display: block;
              stroke-width: 2;
              stroke: $green;
              stroke-miterlimit: 10;
              box-shadow: inset 0px 0px 0px $green;
              animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
              position:relative;
              top: 5px;
              right: 5px;
              margin: 0 auto .5rem auto;
              &-circle {
                stroke-dasharray: 166;
                stroke-dashoffset: 166;
                stroke-width: 2;
                stroke-miterlimit: 10;
                stroke: $green;
                fill: $white;
                animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
              }
              &-check {
                transform-origin: 50% 50%;
                stroke-dasharray: 48;
                stroke-dashoffset: 48;
                animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
              }
            }
            .cross {
              border-radius: 50%;
              display: flex;
              height: 80px;
              stroke-width: 2;
              width: 80px;
              margin-bottom: .5rem;
              &-circle {
                animation: 0.6s ease 0s normal forwards 1 running stroke;
                fill: none;
                margin: 0 auto;
                stroke: $red;
                stroke-dasharray: 166;
                stroke-dashoffset: 166;
                stroke-width: 2;
              }
              &-path {
                stroke: $red;
                stroke-dasharray: 48;
                stroke-dashoffset: 48;
                transform-origin: 50% 50% 0;
                &-right {
                  animation: 0.3s ease 0.8s normal forwards 1 running stroke;
                }
                &-left {
                  animation: 1s ease 0.8s normal forwards 1 running stroke;
                }
              }
            }
          }
          &-info {
            h3 {
              text-align: center;
            }
            & p {
              text-align: center;
              margin-bottom: 1rem;
            }
            .btn-dark {
              p { margin-bottom:  unset;}
            }
          }
        }
      }
      &-social-media {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .25s ease-in-out;
        ul {
          width: max-content;
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            a {
              text-decoration: none;
              width: 50px;
              height: 50px;
              display: flex;
              position: relative;
              justify-content: center;
              align-items: center;
              background: $dark;
              border-radius: 100%;
              margin-right: 1rem;
              transition: .2s ease-in-out;
              i {color: $white;}
             
            }
          }
        }
        .login-footer-legals {
          display: none;
        }
      }
      .register-verify {
        padding: 1rem;
        border-radius: 1rem;
        box-shadow: 0 0 0.5rem rgba(0,0,0,.1);
        .brand {
          text-align: center;
          h2 {
            margin: 0;
          }
        }
        .pay-success {
          .membership {
            span {
              &.success {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $green;
                i {
                  margin-right: 1rem;
                }
              }
              &.cancel {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $red;
                i {
                  margin-right: 1rem;
                }
              }
            }
            h4 {
              text-align: center;
              margin-top: 1rem;
              font-size: 21px;
            }
            h3 {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.form-register {
  width: 100%;
  max-width: 900px;
  height: max-content;
  position: relative;
  background: $white;
  transition: .25s ease-in-out;
  &-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 0 1.5rem 0;
    margin-bottom: 2rem;
    transition: .25s ease-in-out;
    h2 {
      font-family: "is_bold", sans-serif;
      font-size: 30px;
      margin-bottom: 0;
    }
  }
  h5 {
    font-family: "is_bold", sans-serif;
    text-align: center;
    padding-bottom: 2rem;
  }
  &-form {
    padding: 0 2rem 2rem 2rem;
  }
  &-recover {
    &-input {
      width: 100%;
      position: relative;
      display: flex;
      input[type=text], input[type=password] {
        width: 100%;
        border: .1rem solid $light-gray;
        height: 50px;
        padding-left: 35px;
        padding-right: 40px;
        border-radius: .5rem;
        background: $white;
        transition: 0.2s ease-in-out;
        &:hover,
        &:focus {
          background: $white;
          border-color: $light-gray;
          outline: 0;
          box-shadow: none;
        }
      }
    }
    &-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
    }
    &-password {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      svg {
        width: 20px;
        height: 20px;
        fill: $dark;
      }
      &:hover {
        svg {
          fill: $black;
        }
      }
    }
    &-submit {
      .btn-login {
        width: 100%;
        height: 50px;
        border-radius: 0.5rem;
        background: linear-gradient(
                        to right,
                        rgba(247, 194, 70, 1),
                        rgba(183, 75, 147, 1),
                        rgba(70, 175, 224, 1),
                        rgb(75, 175, 137, 1),
                        rgba(247, 194, 70, 1)
        );
        font-size: 1rem;
        border: 0;
        font-family: "is_bold", sans-serif;
        color: $white;
        transition: 0.2s ease-in-out;
        &:hover {
          transform: scale(1.05);
          border: 0;
          box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
        }
        &::before {
          border-radius: 0.5rem;
        }
        @media (max-width: 1360px) {
          height: 40px;
        }
      }
      .btn-outline-dark-login {
        margin-top: .5rem;
      }
    }
    &-recover {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      text-align: right;
      button {
        color: #333333;
        margin-bottom: 1rem;
        background: transparent;
        font-family: "is_medium", sans-serif;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &-submit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    .btn-outline-dark-login {
      margin-top: .5rem;
    }
  }
  @media(max-width: 1550px) {
    max-width: 800px;
  }
  @media(max-width: 1430px) {
    max-width: 600px;
  }
  @media(max-width: 1160px) {
    max-width: 480px;
  }
  @media(max-width: 992px) {
    max-width: 768px;
  }
  @media(max-width: 800px) {
    max-width: 575px;
  }
  @media(max-width: 605px) {
    max-width: 480px;
  }
  @media(max-width: 500px) {
    max-width: 380px;
  }
  @media(max-width: 400px) {
    max-width: 300px;
  }
}

.form {
  &-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 0 1.5rem 0;
    margin-bottom: 2rem;
    transition: .25s ease-in-out;
    h2 {
      font-family: "is_bold", sans-serif;
      font-size: 30px;
      margin-bottom: 0;
    }
  }
  h5 {
    font-family: "is_bold", sans-serif;
    text-align: center;
    padding-bottom: 2rem;
  }
  &-recover {
    width: 100%;
    max-width: 500px;
    padding: 0 2rem 2rem 2rem;
    &-form {
      width: 100%;
      max-width: 500px;
      height: max-content;
      position: relative;
      background: $white;
      padding: 0 2rem 2rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: .25s ease-in-out;
      &-input {
        width: 100%;
        position: relative;
        display: flex;
        input[type=text], input[type=password] {
          width: 100%;
          border: .1rem solid $light-gray;
          height: 50px;
          padding-left: 35px;
          padding-right: 40px;
          border-radius: .5rem;
          background: $white;
          transition: 0.2s ease-in-out;
          &:hover,
          &:focus {
            background: $white;
            border-color: $light-gray;
            outline: 0;
            box-shadow: none;
          }
        }
      }
      &-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
      }
      &-password {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        svg {
          width: 20px;
          height: 20px;
          fill: $dark;
        }
        &:hover {
          svg {
            fill: $black;
          }
        }
      }
      &-submit {
        margin-top:2rem;
        .btn-login {
          width: 100%;
          height: 50px;
          border-radius: 0.5rem;
          background: linear-gradient(
                          to right,
                          rgba(247, 194, 70, 1),
                          rgba(183, 75, 147, 1),
                          rgba(70, 175, 224, 1),
                          rgb(75, 175, 137, 1),
                          rgba(247, 194, 70, 1)
          );
          font-size: 1rem;
          border: 0;
          font-family: "is_bold", sans-serif;
          color: $white;
          transition: 0.2s ease-in-out;
          &:hover {
            transform: scale(1.05);
            border: 0;
            box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
          }
          &::before {
            border-radius: 0.5rem;
          }
          @media (max-width: 1360px) {
            height: 40px;
          }
        }
      }
      &-recover {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        text-align: right;
        button {
          color: #333333;
          margin-bottom: 1rem;
          background: transparent;
          font-family: "is_medium", sans-serif;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &-submit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
  }
}

.membership {
  &-wrapper{
    width: 100%;
    min-height: 100vh;
    background-image: url('../../assets/imgs/bg-register.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &-container {
    width: 100%;
    max-width: 1366px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 1rem;
  }
  &-bg {
    width: 100%;
    min-height: 100vh;
    background: rgba(0,0,0,.5);
  }
  &-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    padding: 1rem 0 2rem 0;
    h2 {
      font-family: "is_bold", sans-serif;
      font-size: 30px;
      margin: 0;
      color: $white;
      text-align: center;
    }
    .logo {
      width: 180px;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
      filter: drop-shadow(0 0 .25rem rgba(0,0,0,.3));
    }
  }
  &-content {
    width: 100%;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    border-radius: 1rem;
    padding: 2rem;
    margin-bottom: 2rem;
    .checkmark {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: block;
      stroke-width: 2;
      stroke: $green;
      stroke-miterlimit: 10;
      box-shadow: inset 0px 0px 0px $green;
      animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
      position:relative;
      top: 5px;
      right: 5px;
      margin: 0 auto .5rem auto;
      &-circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: $green;
        fill: $white;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
      }
      &-check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
      }
    }
    .cross {
      border-radius: 50%;
      display: flex;
      height: 80px;
      stroke-width: 2;
      width: 80px;
      margin-bottom: .5rem;
      &-circle {
        animation: 0.6s ease 0s normal forwards 1 running stroke;
        fill: none;
        margin: 0 auto;
        stroke: $red;
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
      }
      &-path {
        stroke: $red;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        transform-origin: 50% 50% 0;
        &-right {
          animation: 0.3s ease 0.8s normal forwards 1 running stroke;
        }
        &-left {
          animation: 1s ease 0.8s normal forwards 1 running stroke;
        }
      }
    }
    &-pay {
      .form-membership {
        .icon {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          h4, p, h2 {
            text-align: center;
          }
          p {
            .price {
              color: $dark;
              font-weight: 700;
            }
          }
        }
        &-footer {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1rem;
        }
      }
    }
  }
  &-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 2rem 1rem;
    color: $white;
    .login-footer-legals {
      padding-bottom: 1rem !important;
      ul {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          margin-left: 1rem;
          a {
            color: $white;
            text-decoration: none;
            transition: .2s ease-in-out;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      @media(max-width: 768px) {
        ul {
          flex-direction: column;
          li {
            a {
              font-size: .9rem;
            }
          }
        }
      }
    }
  }
}

.banner {
  width: 100px;
}

.btn-outline-login {
  max-width: 250px;
  height: 60px;
  padding: .3rem 5rem;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(to right, $black, $dark, $gray);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-sizing: border-box;
  border-radius: 1rem;
  color: $dark;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: .5rem;
  margin-bottom: 1rem;
  font-family: "is_bold", sans-serif;
  font-size: 1.1rem;
  box-shadow: 2px 1000px 1px $white inset;
  transition: .2s ease-in-out;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: 1rem;
    background:  linear-gradient(to right, $black, $dark, $gray);
  }
  &:hover {
    transform: scale(1.1);
    color: $white;
  }
}

.btn-outline-dark-login {
  max-width: 250px;
  height: 50px;
  padding: .5rem 5rem;
  color: $dark;
  font-family: "is_bold", sans-serif;
  font-size: 1.1rem;
  background: rgba(0,0,0,0);
  cursor: pointer;
  border: .15rem solid rgba(0,0,0,0) !important;
  border-radius: 1rem !important;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: .2s ease-in-out;
  &:hover {
    height: 50px;
    border: .15rem solid $dark !important;
    border-radius: 1rem !important;
    display: flex;
    justify-content: center;
    box-shadow: none !important;
    align-items: center;
    background: $white;
  }
}

.animationBg1 {
  -webkit-animation-name: fadeIn; /* Chrome, Safari, Opera */
  -webkit-animation-duration: 10s; /* Chrome, Safari, Opera */
  animation-name: fadeIn;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

.animationBg2 {
  -webkit-animation-name: fadeIn; /* Chrome, Safari, Opera */
  -webkit-animation-duration: 10s; /* Chrome, Safari, Opera */
  animation-name: fadeIn2;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

.animationBg3 {
  -webkit-animation-name: fadeIn; /* Chrome, Safari, Opera */
  -webkit-animation-duration: 10s; /* Chrome, Safari, Opera */
  animation-name: fadeIn3;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  0%   { background-color: rgba(183,75,147,.7); }
  15%   { background-color: rgba(247,194,70,.7); }
  30%   { background-color: rgba(75,175,173,.7); }
  45%   { background-color: rgba(70,175,224,.7); }
  60%  { background-color: rgba(216,52,47,.7); }
  75% { background-color: rgba(238,164,179,.7); }
  100% { background-color: rgba(32,58,132,.7); }
}

@keyframes fadeIn2 {
  0%   { background-color: rgba(70,175,224,.7); }
  15%   { background-color: rgba(247,194,70,.7); }
  30%  { background-color: rgba(216,52,47,.7); }
  45%   { background-color: rgba(75,175,173,.7); }
  60% { background-color: rgba(32,58,132,.7); }
  75% { background-color: rgba(238,164,179,.7); }
  100%   { background-color: rgba(183,75,147,.7); }
}

@keyframes fadeIn3 {
  0%   { background-color: rgba(247,194,70,.7); }
  15%   { background-color: rgba(183,75,147,.7); }
  30%   { background-color: rgba(70,175,224,.7); }
  45%  { background-color: rgba(216,52,47,.7); }
  60%   { background-color: rgba(75,175,173,.7); }
  75% { background-color: rgba(32,58,132,.7); }
  100% { background-color: rgba(238,164,179,.7); }
}

@keyframes fadeIn {
  0%    { background-color: rgba(183,194,70,.7);}
  15%   { background-color: rgba(247,194,70,.7); }
  30%   { background-color: rgba(75,175,173,.7); }
  45%   { background-color: rgba(70,175,224,.7); }
  60%   { background-color: rgba(216,52,47,.7); }
  75%   { background-color: rgba(238,164,179,.7); }
  100%  { background-color: rgba(32,58,132,.7); }
}

@keyframes fadeInFill {
  0%    { background-color: rgba(183,194,70,.7);}
  15%   { background-color: rgba(247,194,70,.7); }
  30%   { background-color: rgba(75,175,173,.7); }
  45%   { background-color: rgba(70,175,224,.7); }
  60%   { background-color: rgba(216,52,47,.7); }
  75%   { background-color: rgba(238,164,179,.7); }
  100%  { background-color: rgba(32,58,132,.7); }
}

@keyframes fadeInBorder {
  0%    { background-color: rgba(183,194,70,.7);}
  15%   { background-color: rgba(247,194,70,.7); }
  30%   { background-color: rgba(75,175,173,.7); }
  45%   { background-color: rgba(70,175,224,.7); }
  60%   { background-color: rgba(216,52,47,.7); }
  75%   { background-color: rgba(238,164,179,.7); }
  100%  { background-color: rgba(32,58,132,.7); }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
