@import "../../assets/theme/vars";

.wrapper__landing_v2 {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  .main__landing {
    position: relative;
    z-index: 1;
  }
  .my_container {
    max-width: 1350px;
    width: 100%;
    padding: 0 1rem;
    margin: 0 auto;
  }
  .wrapper_header_in_login {
    padding: 0.5rem 0;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    &.scrolling {
      background: #ffffff;
      box-shadow: 0 0px 24px rgba(0, 0, 0, 0.1);
      .container-lg {
        .cont_brand {
          img {
            max-height: 60px;
          }
        }
      }
    }
    .container-lg {
      display: flex;
      align-items: center;
      gap: 15px;
      justify-content: space-between;

      .cont_brand {
        img {
          max-height: 80px;
        }
      }
      .cont_options_links {
        width: 100%;
        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          li {
            list-style: none;
            a {
              text-align: center;
              display: block;
              text-decoration: none;
              font-size: 1.1rem;
              display: inline-block;
              padding: 0.25rem 1rem;
              &.ico_home {
                font-size: 1.25rem;
              }
            }
          }
        }
      }

      .cont_social {
        text-align: center;
        max-width: 150px;
        width: 100%;
        display: flex;
        .slabel {
          font-size: 0.75rem;
          font-family: "is_bold";
        }
        .box_link_fa {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
          a:hover {
            color: $whine;
          }
          svg {
            fill: $black;
            &:hover {
              fill: $whine;
            }
          }
        }
        .btn_menu_lndng {
          display: none;
          width: 150px;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .wrapper__welcome {
    height: 100vh;
    display: flex;
    max-height: 950px;
    min-height: 525px;
    position: relative;
    & > div {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .cont_info_welcome {
        width: 100%;
        max-width: 550px;
        .welcome_ttl {
          font-size: 1.85rem;
          text-transform: uppercase;
          &.xthin {
            font-family: "is_book";
          }
          &.xbold {
            font-family: "is_black";
            font-size: 4rem;
            -webkit-text-stroke: 1px #333333;
            line-height: 1;

            &.c_pink {
              -webkit-text-stroke: 1px $blue;
            }
            &.c_blue {
              -webkit-text-stroke: 1px $blue;
            }
          }
          &.xboldoutline {
            font-family: "is_black";
            font-size: 4rem;
            line-height: 1;

            &.c_black {
              -webkit-text-stroke: 1px $black;
            }
            &.c_blue {
              -webkit-text-stroke: 1px $blue;
            }
            &.c_beige{
              -webkit-text-stroke: 1px $beige;
            }
          }
        }
      }
      .cont_form_login {
        width: 100%;
        max-width: 375px;
        .form {
          &-login {
            .landing-header-subtitle {
              font-size: 2rem;
              text-transform: uppercase;
              text-align: center;
              font-family: "is_black_italic", sans-serif;
              margin-bottom: 1.5rem;
            }
            &-input {
              width: 100%;
              position: relative;
              display: flex;
              .form-control {
                height: 50px;
                padding-left: 35px;
                padding-right: 40px;
                border-color: $light-gray;
                background: $white;
                transition: 0.2s ease-in-out;
                &:hover,
                &:focus {
                  background: $white;
                  border-color: $light-gray;
                  outline: 0;
                  box-shadow: none;
                }
              }
            }
            &-icon {
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 35px;
            }
            &-password {
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              margin: auto 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              cursor: pointer;
              transition: 0.2s ease-in-out;
              svg {
                width: 20px;
                height: 20px;
                fill: $dark;
              }
              &:hover {
                svg {
                  fill: $black;
                }
              }
            }
            &-submit {
              .btn-login {
                width: 100%;
                height: 50px;
                border-radius: 0.5rem;
                background: linear-gradient(to right, $black, $dark, $gray);
                font-size: 1rem;
                border: 0;
                font-family: "is_bold", sans-serif;
                color: $white;
                transition: 0.2s ease-in-out;
                &:hover {
                  transform: scale(1.05);
                  border: 0;
                  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
                }
                &::before {
                  border-radius: 0.5rem;
                }
                @media (max-width: 1360px) {
                  height: 40px;
                }
              }
            }
            &-recover {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              text-align: right;
              button {
                color: #333333;
                margin-bottom: 1rem;
                background: transparent;
                font-family: "is_medium", sans-serif;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            .link__regster a {
              margin-top: 0.5rem;
              display: block;
              padding: 0.5rem;
              text-decoration: none;
              font-family: "is_bold";
              &:hover {
                text-decoration: underline;
                color: $whine;
              }
            }
          }
        }
      }
    }
    .box_scroll_home {
      position: absolute;
      bottom: 5%;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      p {
        font-family: "is_bold_italic";
        font-size: 0.95rem;
        margin-bottom: 0;
      }
      img {
        max-height: 50px;
        filter: brightness(0);
      }
    }
  }

  // == SOMOSPRIDE ==
  .wrapper__a {
    padding: 130px 0 90px;
    position: relative;
    .item_resource_video {
      position: absolute;
      left: 0;
      top: 210px;
      transform: translateY(-50%);
      width: 200px;
      text-align: right;
      border-radius: 0 270px 270px 0;
      background: rgb(255, 12, 38);
      background: linear-gradient(
        90deg,
        rgba(255, 12, 38, 1) 27%,
        rgba(255, 112, 1, 1) 100%
      );
      &:hover {
        width: 40%;
      }
      .btn_icon_play {
        height: 170px;
        transform: scale(1.1);
        animation: iconPulse 2s infinite;
        cursor: pointer;
        // &:hover {
        //   transform: scale(1.25);
        // }
      }
    }
    .sec_a {
      display: flex;
      justify-content: flex-end;
      .content_a {
        text-align: right;
        max-width: 600px;
        width: 100%;
        .paragraph_lndgn {
          padding: 1.65rem 0 2rem;
        }
      }
      .btn_in_section {
        background: rgb(255, 98, 1);
        background: linear-gradient(
          90deg,
          rgb(255, 98, 1) 27%,
          rgba(255, 112, 1, 1) 100%
        );
        color: #fefefe;
        font-family: "is_bold";
        padding: 0.65rem 1.5rem;
        border-radius: 0.5rem;
        text-decoration: none;
        &:hover {
          background: rgb(255, 79, 3);
        }
      }
    }
  }

  @keyframes iconPulse {
    0% {
      transform: scale(1.1);
    }
    70% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(1.1);
    }
  }

  // == PRIDEMUSIC ==
  .wrapper__b {
    padding: 150px 0 100px;
    .sec__b {
      display: flex;
      gap: 25px;
      align-items: center;
      justify-content: space-between;
      .content_info {
        max-width: 550px;
        width: 100%;
      }
      .content_media {
        width: 100%;
        display: flex;
        gap: 15px;
        max-width: 650px;
        height: 300px;
        .carta-box {
          width: 100%;
          position: relative;
          perspective: 1000px;
          cursor: crosshair;
          &.box_middle {
            margin-top: -8px;
          }
          &:hover .carta {
            transform: rotateY(180deg);
          }
          .carta {
            transform-style: preserve-3d;
            transition: all 0.35s linear;
            .cara {
              position: absolute;
              backface-visibility: hidden;
              &.detras {
                transform: rotateY(180deg);
                img {
                  border-radius: 1rem;
                }
              }
            }
          }
          .disc {
            position: absolute;
            bottom: -70px;
            left: 40%;
            height: 50px;
            z-index: 1;
            width: 50px;
            -webkit-animation: 2s rotate linear infinite;
            animation: 2s rotate linear infinite;
          }
        }
      }
    }
  }

  // == REWARDS ==
  .wrapper__c {
    padding: 200px 0 125px;
    .sec_c_carousel {
      position: relative;
      height: 420px;
      // background: chocolate;
      .slick-slider {
        &:hover .slick-prev,
        &:hover .slick-next {
          opacity: 1 !important;
          box-shadow: 2px 2px 12px rgba($color: #000000, $alpha: 0.15);
        }
      }

      .slick-slide {
        display: block;
        padding: 0;
        padding-top: 2rem;
        img {
          width: 100%;
          border-radius: 0.75rem;
        }
      }

      .slick-slide.slick-active.slick-center.slick-current {
        .item_picture {
          padding: 0;
          margin-top: -30px;
        }
      }

      .item_picture {
        padding: 1.5rem;
        transition: all 0.5s ease;
      }
      /* Arrows */
      .slick-prev,
      .slick-next {
        font-size: 0;
        line-height: 0;
        background: #ffffff;
        position: absolute;
        top: calc(50% - 40px);
        display: block;
        width: 40px;
        height: 40px;
        padding: 0;
        z-index: 9;
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        border-radius: 0 0.35rem 0.35rem 0;
        opacity: 0.25;
        -webkit-transition: 0.4s cubic-bezier(0.2, 0.61, 0.41, 0.95);
        transition: 0.4s cubic-bezier(0.2, 0.61, 0.41, 0.95);
        border-radius: 50%;
      }

      .slick-prev {
        left: 20px;

        &:hover:before {
          border-right: 8px solid red;
        }
      }

      .slick-next {
        right: 20px;

        &:hover:before {
          border-left: 8px solid red;
        }
      }

      .slick-prev:hover,
      .slick-prev:focus,
      .slick-next:hover,
      .slick-next:focus {
        outline: none;
      }

      .slick-prev:before,
      .slick-next:before {
        content: "";
        position: absolute;
        top: calc(50% - 6px);
        left: calc(50% - 4px);
        z-index: 9;
      }

      .slick-prev:before {
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-right: 8px solid #999999;
        border-bottom: 7px solid transparent;
      }

      .slick-next:before {
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-left: 8px solid #999999;
        border-bottom: 7px solid transparent;
      }
    }
    .sec_c_info {
      display: flex;
      justify-content: flex-end;
      & > div {
        max-width: 425px;
        text-align: right;
      }
    }
  }

  // == EMPRENDEDORES ==
  .wrapper__d {
    padding: 125px 0 150px;
    .sec_d_info {
      & > div {
        max-width: 475px;
      }
    }
    .sec_d_custom {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      .carta-box-emp {
        width: 225px;
        height: 225px;
        position: relative;
        perspective: 1000px;
        &.ei {
          .back_box {
            background: #f16168;
          }
          margin-bottom: 3rem;
        }
        &.eii {
          .back_box {
            background: #f49461;
          }
          margin-top: 5rem;
        }
        &.eiii {
          margin-bottom: 3rem;
          .back_box {
            background: #fbd86d;
          }
        }
      }

      .carta-box-emp:hover .carta {
        transform: rotateY(180deg);
      }

      .carta {
        transform-style: preserve-3d;
        transition: all 0.25s linear;
        text-align: center;
      }

      .cara {
        position: absolute;
        backface-visibility: hidden;
        img {
          border-radius: 50%;
        }
        .back_box {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.75rem;
          font-size: 1.15rem;
          font-family: "is_medium";
        }
      }

      .cara.detras {
        transform: rotateY(180deg);
      }
    }
  }

  // == FOOTER ==
  .wrapper_footer {
    padding: 50px 0;
    border-top: 1px solid #faddcb;
    a {
      text-decoration: none;
    }
    .sec_footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .links {
        display: flex;
        gap: 15px;
      }
    }
  }
}

.alert-chrome {
  width: 350px;
  height: 80px;
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  padding: 1rem;
  z-index: 99;
  background: $white;
  border-radius: 1rem;
  border: 0.1rem solid $light;
  .btn-close {
    width: 30px;
    position: absolute;
    height: 30px;
    padding: 0;
    top: -15px;
    right: -15px;
    color: $gray;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    transition: .2s ease-in-out;
    i {
      color: $gray;
      font-size: 1rem;
    }
    &:hover {
      background: $gray;
      i {
        color: $white;
      }
    }
  }
  &-content {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 0 0 0;
    p {
      font-size: .9rem;
      color: $dark;
    }
    img {
      width: 30px;
      height: 30px;
      border-radius: 50px;
      margin: 0 4px 0 0;
    }
  }
  &.showing {
    display: none !important;
  }
  @media(max-width: 768px) {
    width: 200px;
    height: 125px;
    right: 1rem;
    &-content {
      flex-direction: column-reverse;
      align-items: flex-start;
      p {
        font-size: .8rem;
        padding-top: .5rem;
      }
    }
    .btn-close {
      top: 15px;
      right: 16px;
    }
  }
}

// == LOAD ANIMATION ==
.wrapper_load_animation {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  .content_bg_img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgba(255, 255, 255, 0.9);
    }
  }
  & > div {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 450px !important;
      height: 450px !important;
    }
  }
  &.is__show {
    display: flex;
  }
}

.c_black {
  color: $black;
}
.c_blue {
  color: $blue;
}
.c_beige{
  color: $beige;
}
.c_whine{
  color: $whine;
}
.ttl_primary {
  font-size: 3.35rem;
  font-family: "is_light";
  span {
    font-family: "is_black";
  }
}

.ttl_secundary {
  font-size: 1.9rem;
  font-family: "is_light_italic";
}

.paragraph_lndgn {
  font-size: 1.35rem;
  line-height: 1.25;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.particle_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.particle {
  position: absolute;
  border-radius: 50%;
}

@for $i from 1 through 30 {
  @keyframes particle-animation-#{$i} {
    100% {
      transform: translate3d(
        (random(90) * 1vw),
        (random(90) * 1vh),
        (random(100) * 1px)
      );
    }
  }
  .particle:nth-child(#{$i}) {
    animation: particle-animation-#{$i} 7s infinite;
    $size: random(5) + 5 + px;
    opacity: 0.2;
    height: $size;
    width: $size;
    animation-delay: -$i * 0.2s;
    transform: translate3d(
      (random(90) * 1vw),
      (random(90) * 1vh),
      (random(100) * 1px)
    );
    background: hsl(random(360), 70%, 50%);
  }
}

@media screen and (max-width: 920px) {
  .wrapper__landing_v2 {
    .wrapper_header_in_login {
      .container-lg {
        .cont_options_links {
          ul {
            li {
              a {
                padding: 0.25rem 0.5rem;
              }
            }
          }
        }
      }
    }
    .wrapper__welcome > div {
      .cont_info_welcome {
        .welcome_ttl {
          font-size: 1.5rem;
          &.xbold {
            font-size: 3.25rem;
          }
          &.xboldoutline { 
            font-size: 3.25rem;
          }
        }
      }
    }
    .wrapper__c {
      padding: 140px 0;
    }

    .wrapper_footer {
      padding: 20px 0;
      .sec_footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper__landing_v2 {
    .wrapper_header_in_login {
      .container-lg {
        position: relative;
        .cont_brand img {
          max-height: 50px;
        }
        .cont_options_links {
          background: #ffffff;
          display: none;
          position: absolute;
          top: 55px;
          left: 0;
          width: 100%;
          border-top: 1px solid #eeeeee;
          padding: 1rem 0;
          box-shadow: 0 2px 24px rgba(0, 0, 0, 0.05);
          &.is__open {
            display: block;
          }
        }
        .cont_social {
          max-width: 185px;
          gap: 10px;
          .btn_menu_lndng {
            display: flex;
            cursor: pointer;
            background: #0393D6;
            color: #fefefe;
            border-radius: 0.25rem;
            .btn_menu {
              display: inline-block;
              width: 25px;
              height: 20px;
              position: relative;
              top: 0;
              z-index: 2;
              cursor: pointer;
              & span {
                width: 100%;
                height: 4px;
                border-radius: 4px;
                display: block;
                position: absolute;
                background: #fefefe;
                transition: all 0.35s ease;
                transform-origin: 0px 100%;
                &:nth-child(2) {
                  top: calc(50% - 2px);
                }
                &:nth-child(3) {
                  bottom: 0;
                }
              }
              &.visible_menu {
                right: -5px;
                span:nth-child(1) {
                  transform: rotate(45deg) translate(-2px, 3px);
                }
                span:nth-child(2) {
                  opacity: 0;
                }
                span:nth-child(3) {
                  transform: rotate(-45deg) translate(-3px, 1px);
                }
              }
            }
          }
        }
      }
      &.scrolling .container-lg .cont_brand img {
        max-height: 50px;
      }
    }
    .wrapper__welcome {
      max-height: 950px;
      justify-content: flex-start;
      min-height: 828px;
      padding-top: 150px;
      & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        .cont_info_welcome {
          width: 100%;
          max-width: 100%;
          text-align: center;
          margin-bottom: 3.75rem;
          .welcome_ttl {
            font-size: 1.25rem;
            &.xbold {
              font-size: 3rem;
              line-height: 0.5;
            }
          }
        }
      }
    }
    .wrapper__a {
      padding: 350px 0 90px;
      .item_resource_video {
        top: 200px;
        width: 50%;
        &:hover {
          width: 60%;
        }
      }
      .sec_a {
        flex-direction: column;
        .content_a {
          margin: 0 auto;
          text-align: center;
        }
      }
    }
    .wrapper__b {
      .sec__b {
        justify-content: space-between;
        flex-direction: column;
        .content_info {
          max-width: 550px;
          width: 100%;
          margin: 0 auto;
          text-align: center;
          margin-bottom: 3rem;
        }
      }
    }
    .wrapper__c {
      padding: 150px 0 80px;
      .row {
        flex-direction: column-reverse;
      }
      .sec_c_info > div {
        max-width: 100%;
        text-align: center;
        margin-bottom: 3rem;
      }
    }
    .wrapper__d {
      .sec_d_info > div {
        max-width: 650px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 3rem;
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .wrapper__landing_v2 {
    .wrapper__welcome > div {
      .cont_info_welcome .welcome_ttl.xbold {
        font-size: 3rem;
        line-height: 1;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .ttl_primary {
    font-size: 2.75rem;
  }

  .wrapper__landing_v2 {
    .wrapper_header_in_login .container-lg {
      gap: 30px;
      .cont_options_links {
        background: #ffffff;
        display: none;
        position: absolute;
        top: 55px;
        left: 0;
        width: 100%;
        border-top: 1px solid #eeeeee;
        padding: 1rem 0;
        box-shadow: 0 2px 24px rgba(0, 0, 0, 0.05);
        ul {
          flex-direction: column;
        }
      }
    }
    .wrapper__welcome {
      padding-top: 130px;
    }
    .wrapper__a {
      .item_resource_video .btn_icon_play {
        height: 125px;
      }
    }

    .wrapper__c {
      .sec_c_carousel {
        height: 325px;
        .item_picture {
          padding: 1rem;
        }
      }
    }
    .wrapper__d {
      .sec_d_custom {
        flex-direction: column;
        .carta-box-emp {
          margin: 0 !important;
          .cara .back_box {
            width: 220px;
            height: 220px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .wrapper_load_animation > div svg {
    width: 300px !important;
    height: 300px !important;
  }
  .ttl_primary {
    font-size: 2.5rem;
    word-break: break-all;
    margin-bottom: 0.5rem !important;
  }
  .ttl_secundary {
    font-size: 1.5rem;
  }
  .paragraph_lndgn {
    font-size: 1.2rem;
    line-height: 1.25;
  }
  .wrapper__landing_v2 {
    .wrapper__welcome {
      padding-top: 110px;
      .box_scroll_home {
        bottom: 10px;
      }
      & > div .cont_info_welcome .welcome_ttl {
        margin-bottom: 0.5rem;
      }
    }
    .wrapper__a {
      .item_resource_video .btn_icon_play {
        height: 125px;
      }
    }

    .wrapper__b {
      padding: 150px 0 0;
      .sec__b {
        .content_media {
          gap: 5px;
        }
      }
    }

    .wrapper__c {
      .sec_c_carousel {
        height: 325px;
        .item_picture {
          padding: 1rem;
        }
      }
    }

    .wrapper_footer {
      .sec_footer {
        .links {
          flex-direction: column;
          gap: 3px;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .ttl_primary {
    font-size: 2rem;
  }
  .ttl_secundary {
    font-size: 1.4rem;
    line-height: 1.15;
  }
  .paragraph_lndgn {
    font-size: 1.1rem;
    line-height: 1.25;
  }

  .wrapper__landing_v2 {
    .wrapper__welcome > div .cont_info_welcome .welcome_ttl.xbold {
      font-size: 2.75rem;
    }
    .wrapper__d .sec_d_custom {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      flex-direction: column;
      .carta-box-emp {
        margin-top: 2rem !important;
      }
    }
  }
}
