@import "../../assets/theme/vars";

.splide-dashboard {
  width: 100%;
  position: relative;
  border-radius: 2rem;
  cursor: grab;
  background: rgba(0,0,0,0);
  &-video {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
    &-play {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      font-size: 5rem;
      color: $gray;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      transition: 0.2s ease-in-out;
      &:hover {
        font-size: 6rem;
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
        background: rgba(255, 255, 255, 1);
      }
    }
  }
  .splide {
    border-radius: 2rem;
    &__pagination {
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0;
      pointer-events: none;
      position: absolute;
      z-index: 10;
      top: 0;
      bottom: 0;
      right: 1rem;
      li {
        margin-bottom: 0.5rem;
        &:last-child {
          margin-bottom: 0;
        }
        button {
          width: 10px;
          height: 12px;
          border-radius: 100%;
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
          &.is-active {
            background: $gray;
          }
        }
      }
    }
    &__arrows {
      position: absolute;
      z-index: 1;
      bottom: 0.7rem;
      left: 45%;
    }
    &__arrow {
      opacity: 0.4;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 0.1rem solid rgba(255, 255, 255, 0.8);
      transition: 0.2s ease-in-out;
      background: rgba(0, 0, 0, 0.9);
      &:hover {
        opacity: 1;
      }
      svg {
        fill: rgba(255, 255, 255, 0.8);
        width: 20px;
        height: 20px;
      }
      &--prev {
        transform: rotate(180deg);
        margin-right: 0.5rem;
      }
      // &--next {
      // }
    }
    &__slide {
      width: 100%;
      border-radius: 2rem;
      position: relative;
      cursor: grab;
      overflow: hidden;
      img {
        object-fit: contain !important;
        width: 100%;
        border-radius: 2rem;
        position: relative;
      }
      a {
        cursor: grab;
        .splide-dashboard-slider {
          object-fit: contain !important;
          width: 100%;
          border-radius: 2rem;
        }
      }
    }
    &:hover {
      .splide__arrow {
        opacity: 1 !important;
      }
    }
  }
  .splide__pagination {
    counter-reset: pagination-num;
    top: 0;
    right: 2rem;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    gap: 15px;
    li {
      margin: 0 !important;
      button {
        background: transparent;
        &.is-active {
          background: transparent;
          &:before {
            opacity: 1;
          }
        }
        &:before {
          font-size: .90rem;
          counter-increment: pagination-num;
          content: counter(pagination-num);
          background: rgba(255, 255, 255, 1);
          opacity: .35;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 0.25rem;
        }
      }
    }
  }
}

// .splide-dashboard {
//   width: 100%;
//   position: relative;
//   border-radius: 2rem;
//   cursor: grab;
//   &-video {
//     width: 100%;
//     height: 100%;
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: contain;
//     border-radius: 2rem;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: grab;
//     &-play {
//       width: 150px;
//       height: 150px;
//       border-radius: 100%;
//       font-size: 5rem;
//       color: $gray;
//       background: rgba(255,255,255,.8);
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       position: absolute;
//       transition: .2s ease-in-out;
//       &:hover {
//         font-size: 6rem;
//         box-shadow: 0 0 .5rem rgba(0,0,0,0.5);
//         background: rgba(255,255,255,1);
//       }
//     }
//     &-thumb {}
//   }
//   .splide {
//     border-radius: 2rem;
//     &__pagination {
//       align-items: center;
//       display: flex;
//       justify-content: center;
//       flex-direction: column;
//       margin: 0;
//       pointer-events: none;
//       position: absolute;
//       z-index: 10;
//       top: 0;
//       bottom: 0;
//       right: 1rem;
//       li {
//         margin-bottom: .5rem;
//         &:last-child {
//           margin-bottom: 0;
//         }
//         button {
//           width: 10px;
//           height: 12px;
//           border-radius: 100%;
//           background: rgba(255,255,255,.8);
//           box-shadow: 0 0 .2rem rgba(0,0,0,.2);
//           &.is-active {
//             background: $gray;
//           }
//         }
//       }
//     }
//     &__arrows {
//       position: absolute;
//       z-index: 1;
//       bottom: 1rem;
//       left: 1rem;
//     }
//     &__arrow {
//       opacity: 0;
//       background: rgba(0,0,0,0);
//       width: 50px;
//       height: 50px;
//       border-radius: 50px;
//       border: .1rem solid rgba(255,255,255,.8);
//       transition: .2s ease-in-out;
//       &:hover {
//         background: rgba(0,0,0,0.5);
//       }
//       svg {
//         fill : rgba(255,255,255,.8);
//         width: 30px;
//         height: 30px;
//       }
//       &--prev {
//         transform: rotate(180deg);
//         margin-right: 1rem;
//       }
//       &--next {}
//     }
//     &__slide {
//       width: 100%;
//       height: auto;
//       border-radius: 2rem;
//       position: relative;
//       cursor: grab;
//       overflow: hidden;
//       img {
//         object-fit: contain !important;
//         width: 100%;
//         height: auto;
//         border-radius: 2rem;
//         position: relative;
//       }
//       a {
//         cursor: grab;
//         .splide-dashboard-slider {
//           object-fit: contain !important;
//           width: 100%;
//           border-radius: 2rem;
//         }
//       }
//     }
//     &:hover {
//       .splide__arrow {
//         opacity:  1 !important;
//       }
//     }
//   }
// }
