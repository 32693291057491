@import "../../assets/theme/vars";

.job-bank {
  &-form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .group {
      width: 100%;
      position: relative;
      label {
        position: absolute;
        top: 0.7rem;
        left: 1rem;
        color: $gray;
        z-index: 1;
      }
      input[type="text"] {
        width: 100%;
        height: 40px;
        min-height: 40px;
        border: 0.1rem solid $light-gray;
        background: $light;
        border-right: 0;
        padding: 0.5rem 0.5rem 0.5rem 2.5rem;
        transition: 0.2s ease-in-out;
        &.location {
          border-radius: 25px 0 0 25px;
        }
        &.job {
          border-radius: 0;
        }
        &:hover,
        &:focus {
          background: $white;
          outline: 0;
        }
      }
    }
    .btn-searcher {
      height: 40px !important;
      padding: 0.5rem 1rem !important;
      border: 0;
      border-radius: 0 25px 25px 0;
      transition: 0.2s ease-in-out;
      svg {
        font-size: 1.7rem;
      }
      &:hover {
        color: $white;
      }
      &:before {
        border-radius: 0 25px 25px 0;
      }
    }
  }
  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    border-radius: 1rem;
    position: relative;
    margin-bottom: 1.5rem;
    &-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
    }
    &-thumb {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: end;
      padding: .75rem;
      .banner {
        width: 100%;
        height: 230px;
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto 0;
        border-radius: 2rem;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .drawer__bg__img{
        max-width: 550px;
        border-radius: 1rem;
        width: 100%;
      }

      .thumb {
        width: 100%;
        height: 340px;
        position: absolute;
        bottom: 0;
        right: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom right;
      }
    }
  }
  &-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-gradient-green {
      min-width: 150px;
      height: 40px;
      margin-right: 1rem;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      line-height: 1rem;
      font-size: 0.9rem;
      flex-direction: column;
      span {
        &.min {
          font-size: 0.8rem;
        }
      }
      &:hover {
        color: $white;
        box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
      }
    }
  }
  &-cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
  }
  &-card {
    width: 400px;
    border-radius: 1rem;
    background: $white;
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.true {
      height: max-content;
    }
    &-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .thumb {
        width: 140px;
        height: 100px;
        background: $light;
        border-radius: 0.5rem;
        padding: 0.5rem 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 140px;
          height: 90px;
          object-fit: contain;
          border-radius: 0.5rem;
          background: $white;
        }
      }
      .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 0.5rem;
        h5 {
          font-family: "is_bold", sans-serif;
        }
        .content {
          width: 100%;
          p {
            color: $green;
            font-size: 0.95rem;
            margin-top: 0;
          }
        }
        .date {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.2rem;
          p {
            width: 100%;
            text-align: left;
            font-size: 0.85rem;
            color: #666666;
          }
        }
      }
    }
    &-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.5rem;
      .price {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          display: flex;
          align-items: center;
          i {
            margin-right: 0.5rem;
          }
          font-size: 1.1rem;
          font-family: "is_bold", sans-serif;
        }
      }
      .btns {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        .btn-outline-green {
          transition: 0.25s ease-in-out;
          &:hover {
            background: $gradient-green;
            color: $white;
          }
        }
      }
    }
  }
  &-card-job {
    width: 400px;
    height: 270px;
    border-radius: 1rem;
    background: $white;
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .thumb {
        width: 140px;
        height: 100px;
        background: $light;
        border-radius: 0.5rem;
        padding: 0.5rem 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 140px;
          height: 90px;
          object-fit: contain;
          border-radius: 0.5rem;
          background: $white;
        }
      }
      .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 0.5rem;
        h5 {
          font-family: "is_bold", sans-serif;
        }
        .content {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            width: 120px;
            font-size: 0.8rem;
            height: 2rem;
            border: 0.1rem solid $green;
            display: flex;
            color: $green;
            line-height: 0.9rem;
            justify-content: center;
            text-align: center;
            align-items: center;
            border-radius: 25px;
            padding: 0.2rem 0.5rem;
            margin-top: 0;
          }
          .company {
            svg {
              path {
                stroke: $green !important;
              }
            }
          }
        }
        .date {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.2rem;
          p {
            width: 100%;
            text-align: left;
            font-size: 0.9rem;
            color: $dark;
          }
        }
      }
    }
    &-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin-top: 0.5rem;
      .price {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          display: flex;
          align-items: center;
          i {
            margin-right: 0.5rem;
          }
          font-size: 1.1rem;
          font-family: "is_bold", sans-serif;
        }
      }
      .btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
      }
    }
  }
  &-options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .counter {
      width: 100%;
      span {
        background: $green;
        color: $white;
        padding: 0.3rem 0.5rem;
        border-radius: 0.3rem;
        font-family: "is_medium", sans-serif;
      }
      p {
        font-family: "is_medium", sans-serif;
      }
    }
    .filters {
      width: 100%;
      max-width: 300px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      label {
        width: 150px;
        font-family: "is_medium", sans-serif;
      }
      select {
        width: 100%;
        min-width: 150px;
        height: 40px;
        border-radius: 0.5rem;
        border: 0.1rem solid $light-gray;
        padding: 0.3rem 1rem;
        font-size: 1rem;
        color: $dark;
        transition: 0.2s ease-in-out;
        &:hover {
          border-color: $light-gray;
          box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.1);
        }
        &:focus {
          border-color: $light-gray;
          outline: 0;
        }
      }
    }
  }
  &-categories {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    &-content {
      width: 100%;
      display: flex;
      list-style: none;
      margin: 1rem 0;
      li {
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
        .btn-white {
          background: $white;
          color: $dark;
          text-transform: capitalize;
          padding: 0.5rem 1rem !important;
          height: 30px !important;
          min-height: 30px !important;
          line-height: 0;
          font-size: 0.9rem;
          border: 0 !important;
          box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  &-content {
    &-published {
      .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        h3 {
        }
        a {
        }
      }
      .tabs-job-bank {
        margin-top: 1rem;
        .nav-item {
          .nav-link {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            background: $white;
            border: 0;
            border-radius: 1rem;
            color: $dark;
            &.active {
              background: $gradient-green;
              color: $white;
              font-family: "is_medium", sans-serif;
            }
          }
          &.mr {
            margin-right: 0.5rem;
          }
          &.ml {
            margin-left: 0.5rem;
          }
        }
      }
      .tab-content {
        margin-top: 1rem;
        .content {
          background: $white;
          padding: 1rem;
          border-radius: 1rem;
          .options {
            width: 100%;
            border-radius: 1rem;
            padding: 1rem 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .badge {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;
              background: $green;
              border-radius: 0.5rem;
              margin-right: 1rem;
            }
            p {
              display: flex;
              color: $dark;
              line-height: 1.8rem;
            }
          }
          .items {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            .empty {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              i {
                font-size: 3rem;
              }
              p {
                text-align: center;
                padding-top: 1rem;
              }
            }
          }
          .pagination-jobs {
            .page-item {
              .page-link {
                background: $white;
                border: 0;
                box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
                span {
                  color: $dark;
                }
                &:hover {
                  background: $dark;
                  span {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &-pagination {
    .pagination-jobs {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .page-item {
        .page-link {
          background: $white;
          border: 0;
          box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
          color: $dark;
          span {
            color: $dark;
          }
          &:hover {
            background: $dark;
            color: $white;
            span {
              color: $white;
            }
          }
        }
        &.active {
          .page-link {
            background: $dark;
            color: $white;
            span {
              color: $white;
            }
          }
        }
      }
      @media (max-width: 992px) {
        justify-content: center;
      }
    }
  }
  &-register {
    &-form {
      h4 {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }
      .steps {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        .lines {
          width: 50%;
          height: 1px;
          background: $light-gray;
          margin: 0 1rem;
        }
        .item {
          background: $white;
          width: 50px;
          height: 50px;
          border: 0.1rem solid $light;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.2rem;
          &.active {
            background: $gradient-green;
            color: $white;
          }
        }
      }
      .content {
        width: 100%;
        padding: 1rem;
        background: $white;
        border-radius: 1rem;
        .group-append {
          .input-group-text {
            border: 0;
            background: $light;
            input[type="radio"] {
              margin-left: 0.5rem;
              margin-top: 0;
            }
          }
          .form-control {
            border-color: $light;
            border-left: none;
            color: $dark;
            &:focus {
              box-shadow: none;
            }
          }
        }
        .rdw-editor-toolbar {
          background: $light-gray;
          border: 0;
          border-radius: 0.7rem;
        }
        .rdw-editor-main {
          border: 0.1rem solid $light-gray;
          border-radius: 1rem;
          padding: 1rem;
        }
        .DraftEditor-editorContainer {
          width: 100%;
          height: 200px;
          min-height: 200px;
          background: white;
          padding: 0 1rem;
          border-radius: 1rem;
        }
      }
    }
  }
}

.job-bank-register-form .content .rdw-editor-toolbar {
    margin-bottom: 2rem;
}
.maxw{max-width: 202px;}
.btn-searcher {
  width: max-content;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 2rem;
  background: $gradient-green;
  border-radius: 25px;
}

.details-job {
  width: 100%;
  &-header {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-content {
    background: $white;
    border-radius: 1rem;
    .header {
      width: 100%;
      height: 185px;
      background: $gradient-green;
      padding: 2rem;
      border-radius: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .thumbnail {
        width: 200px;
        height: 180px;
        border-radius: 100%;
        background: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
        img {
          width: 170px;
          height: 170px;
          object-fit: contain;
          border-radius: 100%;
          background: $white;
        }
      }
      .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        color: $white;
        .company {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          line-height: 0;
          span {
            margin-right: 0.5rem;
          }
        }
      }
    }
    .content {
      width: 100%;
      padding: 2rem;
      &-description {
        width: 100%;
      }
      .salary {
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1rem;
        p {
          margin-top: 0;
          font-family: "is_bold", sans-serif;
          color: $dark;
        }
        .quantity {
          width: max-content;
          padding: 0.3rem 0.5rem;
          border-radius: 0.5rem;
          color: $white;
          font-family: "is_bold", sans-serif;
          font-size: 1.2rem;
          background: $gradient-green;
          margin-left: 1rem;
        }
      }
    }
  }
}

.empty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  i {
    font-size: 3rem;
  }
  p {
    text-align: center;
    padding-top: 1rem;
  }
}

.sec__provisional {
  h2 {
    text-align: center;
    max-width: 600px;
    padding: 4rem 1rem;
    letter-spacing: 8px;
    margin: 0 auto;
    margin-top: 5rem;
    border: 5px dashed var(--primary_color);
    border-radius: 1rem;
  }
}

.wrapper_jobbank {
  @include bggrey;
  min-height: calc(100vh - 4rem);

  .contain_gral_jobbank {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
  }

  .sec_catalog_listjobs {
    width: 100%;
    .box_searching_jobs {
      padding: 1rem 1.5rem 1.5rem;
      background: #222222;
      border-radius: 0.5rem;
      color: #f2f2f2;
      form {
        .form-group {
          margin-bottom: 0;
        }
      }
      .btn {
        min-width: auto;
        width: 100%;
        border-color: #ffffff;
        color: #ffffff;
      }
    }
    .nav-tabs {
      border-bottom: 2px solid #cccccc;
    }
    .cont_box_items_jobs {
      margin-top: 2rem;
      .options_order {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 0.75rem;
      }
    }
    .box_aside_hidde {
      display: none;
    }
  }
  .sec_category_jobs {
    max-width: 265px;
    width: 100%;
    padding: 0 0.25rem;
    .box_items_aside {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      .tags_item_category_jobs {
        min-width: auto;
        margin-bottom: 0.25rem;
        border: 1.5px solid #444;
        color: #333;
        height: 30px;
        padding: 0.15rem 0.7rem;
        font-size: 0.8rem;
        &:hover {
          border: 1.5px solid var(--primary_color);
          background: var(--primary_color);
          color: #ffffff;
        }
      }
    }
  }
  .page-link {
    color: #333;
  }
}

.card_shadows {
  background: #ffffff;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
}

.sec_ttls_jobbank {
  .content_ttls_jobbank {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content_btns_job {
      min-width: 445px;
      .btn {
        position: relative;
        margin-left: 0.5rem;
        min-width: auto;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        &.secundary {
          padding-top: 0.4rem;
          span {
            display: block;
            line-height: 1.1;
          }
          .min {
            font-size: 0.75rem;
          }
        }
        .dots_num {
          position: absolute;
          width: 25px;
          height: 25px;
          top: -6px;
          right: -9px;
          // background: #1b3978;
          background: #333;
          border: 4px solid #f5f5f5;
          color: #fff;
          // box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.15);
          text-align: center;
          padding-top: 3px;
          font-size: 0.73rem;
          border-radius: 50%;
        }
      }
    }
  }
  .content_ttls_line {
    margin-top: 1rem;
    .ttls {
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        background: #303030;
        z-index: 0;
        top: 45%;
        left: 0;
      }
      span {
        background-color: #f2f2f2;
        position: relative;
        z-index: 1;
      }
    }
  }
}

.box_items_jobs {
  .item_card_jobs {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 1rem;
    .card_info_jobs {
      width: 100%;
      .name_vacant {
        line-height: 1.1;
        min-height: 50px;
        margin-bottom: 0;
      }
      .publication_date {
        color: #bbbbbb;
      }
      .description_job {
        margin-top: 2rem;
      }
    }
    .card_options {
      text-align: right;
      max-width: 120px;
      width: 100%;
      img {
        max-height: 70px;
        margin-bottom: 1rem;
      }
      .box_salary {
        margin-bottom: 1rem;
        p {
          font-size: 0.9rem;
        }
        .monto_salary {
          letter-spacing: -1px;
          font-size: 1.5rem;
        }
      }
      .btn {
        min-width: auto;
        padding: 0.5rem 0;
        width: 100%;
        line-height: 1;
        margin-bottom: 0.5rem;
        font-size: 0.95rem;
      }
    }
  }
}

.special .box_items_jobs .item_card_jobs {
  margin-top: 1.75rem !important;
}
.wrapper_detailsjobs {
  position: relative;
  .box_items_jobs {
    .item_card_jobs {
      margin-top: 1.5rem;
      flex-direction: column;
      .card_options {
        max-width: 100% !important;
        text-align: left;
        img {
          position: absolute;
          top: 105px;
          right: 20px;
        }
        .box_btns_actions_item {
          position: absolute;
          top: 28px;
          right: 0;
          display: flex;
          width: 50%;
          gap: 5px;
        }
      }
    }
  }
}

.wrapper_applicant_form {
  width: 100%;
  .content_applicant_form {
    max-width: 550px;
    margin: 0 auto;
    .sec_form_applicant {
      margin-top: 1rem;
      .content_application {
        .info_business_applicant {
          text-align: center;
          background: #f5f5f5;
          padding: 0.75rem 1rem;
          border-radius: 0.5rem;
          margin-bottom: 1rem;
          p {
            font-size: 0.9rem;
            color: #999999;
            margin-bottom: 0;
          }
        }
        padding: 1.25rem 2rem;
        .info_user_applicant {
          p {
            margin: 0;
            // margin-bottom: .15rem;
            color: #999999;
          }
        }
      }
      .content_application_sent {
        max-width: 500px;
        margin: 0 auto;
        padding: 2rem 1rem;
        .ico-sent {
          img {
            max-height: 125px;
          }
        }
        .box_info_note {
          display: flex;
          align-items: center;
          margin-bottom: 1.25rem;
          .ico_note {
            min-width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.75rem;
          }
        }
        .bx_application {
          margin-top: 3rem;
        }
      }
    }
  }
}

.wrapper_form {
  .content_shadows_form {
    padding: 2rem;
    padding-top: 1rem;
    border-radius: 0.75rem;
    box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.15);
    background: #fff;
    .goback {
      justify-content: flex-end;
      max-width: 100%;
    }
    .form-control-sm {
      height: calc(1.5em + 0.75rem + 2px) !important;
      font-size: 1rem;
    }
    .isHidde {
      overflow: hidden;
    }
    .box_picture {
      text-align: center;
      img {
        max-height: 80px;
      }
    }
    .input-group-text {
      min-width: 100px;
    }
    .box_info_save {
      h6 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          max-height: 30px;
        }
      }
    }
    .box_pay_sec {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #ddd;
      margin-top: 1em;
      padding-top: 2.4rem;
      .dinfotex {
        text-align: center;
        img {
          max-height: 40px;
        }
        div {
          font-size: 0.6rem;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: bold;
        }
      }
    }
  }
}

.input-group-text.amount {
  min-width: 35px !important;
}

.wrapper_reg_services {
  .wrapper__editor_text {
    background: #f8f8f8;
    .public-DraftStyleDefault-block {
      padding: 0 1rem;
    }
  }
}

@media screen and (max-width: 992px) {
.job-bank-header {
    .job-bank-header-thumb{
      text-align: center;
      .drawer__bg__img{
        border-radius: 1rem;
      }
    }
  }
}
