@import '../../assets/theme/vars';

.admin-board {
  &-container {
    width: 100%;
  }
}

.content_new_question{
  background: #f8f7f7;
  padding: 1rem;
  margin-bottom: 1.5rem;
  .form-group .form-control{
    background: #ffffff;
  }
}
