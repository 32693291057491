@import "../../assets/theme/vars";

.wrapper_block {
  position: relative;
  user-select: none;
  .modal-content {
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: none;
    border: 0;
  }
  .box_content_block {
    display: flex;
    align-self: center;
    align-items: center;
    height: 100vh;
  }
}
.modal-open {
  overflow: auto !important;
  padding-right:0 !important;
}
.modal-blockscreen {
  .modal-content {
    background: rgba(0, 0, 0, 0) !important;
    border: 0;
  }
}
.loader {
  width: 200px;
  height: 200px;
  position: relative;
  margin: auto;
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &-circle::before,
  &-circle::after {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  &-circle::before {
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: #d3c7a2 transparent transparent;
    transform: scale(0.3) rotate(0deg);
    opacity: 0.5;
    -webkit-animation-name: foregroundCircle;
    animation-name: foregroundCircle;
  }
  &-circle::after {
    background: #000000;
    opacity: 0.5;
    transform: scale(0);
    -webkit-animation-name: backgroundCircle;
    animation-name: backgroundCircle;
  }
}
@-webkit-keyframes foregroundCircle {
  0% {
    transform: scale(0.3) rotate(0deg);
  }
  12.5% {
    transform: scale(0.3) rotate(180deg);
  }
  25%,
  50% {
    opacity: 1;
  }
  50% {
    transform: scale(1) rotate(720deg);
  }
  100% {
    transform: scale(0.3) rotate(1800deg);
    opacity: 0.5;
  }
}
@keyframes foregroundCircle {
  0% {
    transform: scale(0.3) rotate(0deg);
  }
  12.5% {
    transform: scale(0.3) rotate(180deg);
  }
  25%,
  50% {
    opacity: 1;
  }
  50% {
    transform: scale(1) rotate(720deg);
  }
  100% {
    transform: scale(0.3) rotate(1800deg);
    opacity: 0.5;
  }
}
@-webkit-keyframes backgroundCircle {
  12.5% {
    transform: scale(0.3);
  }
  90%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes backgroundCircle {
  12.5% {
    transform: scale(0.3);
  }
  90%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
