@import "../../assets/theme/vars";

.admin {
  &-container {
    width: 100%;
    background: $white;
    border-radius: 1rem;
    padding: 2rem;
    &-cards {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .columns {
        &-primary {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding-left: 0.5rem;
          &-cards {
            &-first {
              width: 100%;
              border-radius: 1rem;
              display: flex;
              justify-content: space-between;
              gap: 1rem;
              .column {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;
              }
            }
            &-second {
              width: 100%;
              display: flex;
              justify-content: space-between;
              gap: 1rem;
            }
            &-third {
              width: 100%;
              display: flex;
              justify-content: space-between;
              gap: 1rem;
            }
          }
          &-card {
            width: 100%;
            min-height: 180px;
            border-radius: 1rem;
            background: $white;
            position: relative;
            overflow: hidden;
            text-decoration: none;
            transition: 0.2s ease-in-out;
            &:hover {
              box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
              &.outline {
                border-color: $white;
              }
              &.fill {
                .bg-icon {
                  background: rgba(255, 255, 255, 0.2);
                  color: rgba(255, 255, 255, 0.3);
                }
              }
              &::-webkit-scrollbar {
                border-radius: 1rem;
                width: 3px;
              }
              &::-webkit-scrollbar-track {
                background: $light;
                border-radius: 10px;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: rgba(0,0,0,.1)
              }
            }
            .bg-icon {
              width: 100px;
              height: 100px;
              background: $light;
              border-radius: 100%;
              display: flex;
              font-size: 3rem;
              color: $white;
              justify-content: center;
              align-items: center;
              position: absolute;
              bottom: -1rem;
              left: -1rem;
            }
            p {
              position: relative;
              z-index: 1;
            }
            ul {
              padding-left: 1.5rem;
            }
            .btns {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              position: relative;
              z-index: 1;

              .btn-show-more {
                background: rgba(0, 0, 0, 0) !important;
                color: $dark !important;
                transition: 0.2s ease-in-out;

                span {
                  transition: unset;
                }

                &:hover {
                  background: $dark !important;
                  color: $white !important;
                }
              }
            }
            &.outline {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 1rem;
              border: 0.1rem dashed $light-gray;
              h3 {
                font-size: 1.35rem;
              }
              p {
                font-size: 0.9rem;
              }
            }
            &.scroll {
              overflow-y: auto;
            }
            &.outline-dashed {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 1rem;
              border: 0.1rem solid $light;
            }
            &.fill {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 1rem;
              background: $dark;

              .bg-icon {
                background: rgba(255, 255, 255, 0.1);
                color: rgba(255, 255, 255, 0.2);
                transition: 0.2s ease-in-out;
              }

              h3 {
                color: $white;
              }

              p {
                font-size: 0.9rem;
                color: $white;
              }

              &.green {
                background: $green;
              }

              &.yellow {
                background: $yellow;
              }

              &.purple {
                background: $purple;
              }

              &.pink {
                background: $pink;
              }

              &.red {
                background: $red;
              }

              &.orange {
                background: $orange;
              }

              &.blue {
                background: $blue;
              }

              &.skyblue {
                background: $skyblue;
              }

              &.darkblue {
                background: $darkblue;
              }

              .btns {
                .btn-show-more {
                  background: rgba(0, 0, 0, 0) !important;
                  color: $white !important;
                  border-color: $white !important;
                  transition: 0.2s ease-in-out;

                  span {
                    transition: unset;
                  }

                  &:hover {
                    background: $white !important;
                    color: $dark !important;
                  }
                }
              }
            }
          }
        }
        &-secondary {
          width: 300px;
          height: 100%;
          padding-right: 0.5rem;
          .card-user-admin {
              width: 100%;
              background: $white;
              border-radius: 1rem;
              padding: 2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              margin-bottom: 2rem;
              transition: 0.3s ease-in-out;
              &-avatar {
                width: 160px;
                height: 170px;
                padding: 0.5rem;
                border-radius: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .rotating {
                  width: 170px;
                  height: 170px;
                  border-radius: 100%;
                  position: absolute;
                }

                img {
                  width: 160px;
                  height: 160px;
                  border-radius: 100%;
                  background: $white;
                  padding: 0.3rem;
                  object-fit: cover;
                  position: absolute;
                }
              }

              h3 {
                font-family: "is_bold", sans-serif;
                font-size: 1.2rem;
                text-align: center;
                margin-top: 1rem;
                line-height: 1.2rem;
              }

              h4 {
                font-size: 1rem;
                line-height: 1rem;
                margin-bottom: 0;
              }

              p {
                font-size: 0.9rem;
                color: $dark;
                margin-bottom: 1.5rem;
                padding: 1rem;
              }

              .btn-dark {
                p {
                  color: $white;
                  margin-bottom: 0;
                }

                &:hover {
                  p {
                    color: $dark;
                  }
                }
              }

              &:hover {
                box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.05);
              }
          }
        }
      }
    }
  }
  &-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 2rem;

    h2 {
      margin-bottom: 0;
    }

    h6 {
      color: $gray;
    }
  }
  &-subtitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .btn-dark-toggle {
      &.rs-dropdown-open {
        .rs-dropdown-toggle {
          background: $dark;
          color: $white;

          i {
            color: $dark;
          }
        }
      }
      .rs-dropdown-menu {
        width: 200px;

        .rs-dropdown-item {
          &-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            i {
              width: 25px;
              height: 25px;
              background: $white;
              border-radius: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 1rem;
            }

            &:hover {
              background: $light;
              color: $dark;
            }
          }
        }
      }
      .rs-dropdown-toggle {
        width: max-content;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background: $white;
        color: $gray;

        i {
          width: 25px;
          height: 25px;
          background: $white;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1rem;
        }

        &-caret {
          margin-left: 0.5rem;
          position: relative;
          top: 0;
          right: 0;
        }

        &:hover {
          background: $dark;
          color: $white;

          i {
            color: $dark;
          }
        }
      }
    }
    h2 {
      margin-bottom: 0;
    }
    h6 {
      color: $gray;
    }
  }
  &-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 0;
    @media(max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media(max-width: 575px) {
      .btn-dark, .btn-back {
        width: 100%;
        max-width: unset;
        margin-top: .5rem;
      }
    }
  }
  &-table {
    width: 100%;
    background: $white;
    padding: 1rem !important;
    border-radius: 1rem;
    border-collapse: separate;
    border-spacing: 0 0.3em;
    table-layout: fixed;
    thead {
      tr {
        th {
          font-family: "is_book", sans-serif;
          font-size: .9rem;
        }
      }

      border-color: $white;
    }
    tbody {
      tr {
        border: 0;
        border-radius: 0.5rem;
        box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);

        td {
          width: 100%;
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border: 0;
          height: 50px;
          font-size: .9rem;
          padding: 0 .5rem;
          padding-left: 1rem !important;
          vertical-align: middle;
          .btn-link {
            min-width: 100px;
            max-width: 300px;
            white-space: break-spaces;
            cursor: pointer;
            padding: 0 0.5rem;
            text-decoration: none;
            border-radius: .5rem;
            transition: 0.2s ease-in-out;

            &:hover {
              color: $dark;
              font-family: "is_medium", sans-serif;
              background: $light;
            }
          }
          &:first-child {
            padding: 0;
            border-radius: 0.5rem 0 0 0.5rem;
          }

          &:last-child {
            border-radius: 0 0.5rem 0.5rem 0;
          }

          .d-flex-center {
            width: 100%;
            height: 100%;
            display: flex;
            gap: 1rem;
          }
        }
      }
    }
    &-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    @media(max-width: 992px) {
      min-width: 800px;
      &-footer {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
  &-tabs {
    width: 100% !important;
    background: $white;
    margin-bottom: 1rem !important;
    border-radius: .5rem;
    overflow-x: auto;
    justify-content: flex-start !important;
    .nav-item {
      .nav-link {
        border: none !important;
        background: $white !important;
        color: $dark !important;
        border-radius: .5rem !important;
        white-space: nowrap;
        &.active {
          background: $black !important;
          border: none !important;
          color: $white !important;
          border-radius: .5rem;
        }
      }
    }

    &-content {
      border-radius: 1rem !important;
      border: 0 !important;

      .tab-pane {
        .card-tabs {
          padding: 1rem;
          background: $white;
          border-radius: 1rem;
        }
      }
    }
  }
  &-iframe {
    width: 100%;

    iframe {
      width: 100%;
      height: 315px;
      border-radius: 1rem;
    }

    &-footer {
      width: 100%;

      button {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      }
    }

    .yt {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1rem;
    }
  }
}

.btn-dark-toggle {
  &.rs-dropdown-open {
    .rs-dropdown-toggle {
      background: $black !important;
      color: $white;
      i, svg {
        color: $white;
      }
    }
  }
  .rs-dropdown-menu {
    width: 200px;
    margin-top: .5rem;
    .rs-dropdown-item {
      &-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $black;
        i, svg {
          color: $black;
          margin-right: 1rem;
        }
        &:hover {
          background: $black;
          color: $white;
          i, svg {
            color: $white;
          }
        }
      }
    }
  }
  .rs-dropdown-toggle {
    width: max-content;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background: $black;
    color: $white;
    i,svg {
      color: $white;
      margin-right: 1rem;
    }
    &-caret {
      margin-left: 0.5rem;
      position: relative;
      top: 0;
      right: 0;
    }
    &:hover {
      background: $black;
      color: $white;
      i,svg {
        color: $white;
      }
    }
  }
}

.table-admin {
  .ant-table {
    &-tbody {
      .btn-icon-table {
        width: 40px;
        height: 40px;
        border-radius: 0.25rem;
        padding: .15rem .25rem;
        cursor: pointer;
        color: $black;
        transition: 0.2s ease-in-out;
        i {
          font-size: 1.25rem;
        }
        &.delete {
          i, svg {
            color: $red;
            fill: $red;
          }
        }
        &.edit {
          color: $black;
          i, svg {
            color: $black;
          }
        }
        &:hover {
          &.delete {
            background: $red;
            border-color: $red;
            i, svg {
              color: $white;
              fill: $white;
            }
          }
          &.edit {
            background: $black;
            border-color: $black;
            i, svg {
              color: $white;
              fill: $white;
            }
          }
        }
        &:disabled {
          color: $black;
          &.delete {
            background: $light-gray;
            border-color: $light-gray;
            i {
              color:$gray;
            }
            &:hover {
              background: $light-gray;
              border-color: $light-gray;
              i {
                color: $gray;
              }
            }
          }
        }
      }
    }
  }
}

.link-data {
  text-decoration: underline;
}

.form-dashboard-group-upload {
  width: 100%;
  height: 200px;
  border: 0.1rem dashed $light-gray;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  input {
    display: none;
  }

  label {
    width: 200px;
    height: 40px;
    background: $white;
    border: 0.1rem dashed $dark;
    color: $dark;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    font-family: "is_medium", sans-serif;
    transition: 0.2s ease-in-out;

    svg {
      margin-right: 0.5rem;
    }

    &:hover {
      box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.5);
      background: $dark;
      color: $white;
    }
  }

  .file {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;

    &-container {
      width: 100%;
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $white;
      position: relative;

      .btn-remove-file {
        width: 50px;
        height: 50px;
        background: $white;
        border: 0;
        color: $red;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        align-items: center;
        transition: 0.2s ease-in-out;

        &:hover {
          background: $red;
          color: $white;
        }
      }

      .img-file {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 1rem;
      }
    }
  }
}

@media (max-width: 1280px) {
  .admin {
    &-container {
      &-cards {
        .columns {
          &-primary {
            &-cards {
              &-first {
                .column {
                  flex-direction: column;
                }
              }

              &-second {}

              &-third {}
            }

            &-card {
              width: 100%;
              min-height: 220px;

              .btns {
                justify-content: flex-end;
              }
            }
          }

          &-secondary {
            width: 250px;

            .card {}
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .admin {
    &-container {
      padding: 1rem;

      &-cards {
        .columns {
          &-primary {
            &-cards {
              &-first {}

              &-second {}

              &-third {}
            }

            &-card {
              width: 100%;
              min-height: 220px;

              .btns {
                justify-content: flex-end;
              }
            }
          }

          &-secondary {
            width: 200px;

            .card {
              &-user-admin {
                padding: 1rem;

                .btn-dark {
                  font-size: 0.9rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

.notice {
  &-file {
    &-container {
      width: 100%;
      border-radius: 1rem;
      border: 0.1rem dashed $light-gray;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      background: $white;
      position: relative;

      .btn-remove-file {
        width: 50px;
        height: 50px;
        background: $white;
        border: 0;
        color: $red;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        align-items: center;
        transition: 0.2s ease-in-out;

        &:hover {
          background: $red;
          color: $white;
        }
      }

      .img-file {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 1rem;
      }
    }
  }
}

.modal-images {
  .modal-content {
    background: rgba(0, 0, 0, 0);
    border: 0;
  }

  .modal-body {
    padding: 0;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
    width: max-content;
    margin: 0 auto;
  }
}

.img-table {
  border-radius: 1rem;

  img {
    border-radius: 1rem;
  }
}

@media (max-width: 992px) {
  .admin {
    &-iframe {
      margin: 1rem auto 1rem auto;

      iframe {
        height: 280px;
      }

      &-footer {}

      .yt {
        width: 100%;
        height: 200px;
        margin: 1rem auto 1rem auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .notice-file-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .admin {
    &-container {
      padding: 0.5rem;

      &-cards {
        flex-direction: column;

        .columns {
          &-primary {
            padding-left: 0;

            &-cards {
              &-first {}

              &-second {}

              &-third {}
            }

            &-card {
              .btns {}
            }
          }

          &-secondary {
            width: 100%;
            padding-right: 0;

            .card {}
          }
        }
      }
    }
  }

  .form-dashboard-group-upload {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 575px) {
  .admin {
    &-container {
      &-cards {
        .columns {
          &-primary {
            &-cards {
              &-first {
                flex-direction: column;
              }

              &-second {
                flex-direction: column;
              }

              &-third {
                flex-direction: column;
              }
            }

            &-card {
              min-height: 180px;

              .btns {}
            }
          }

          &-secondary {
            .card {}
          }
        }
      }
    }
  }

  .admin {
    &-iframe {
      iframe {
        height: 200px;
      }
    }
  }

  .form-dashboard-group-pdf {
    flex-direction: column;

    .uploaded-file {
      margin-right: unset !important;
      margin-bottom: 1rem;

      img {
        position: relative !important;
      }
    }

    button.btn-dark {
      position: relative !important;
    }
  }
}

.disabled {
  color: lightgray;
}

.enabled {
  color: black;
}
