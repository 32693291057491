@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?in6zku');
  src:  url('fonts/icomoon.eot?in6zku#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?in6zku') format('truetype'),
    url('fonts/icomoon.woff?in6zku') format('woff'),
    url('fonts/icomoon.svg?in6zku#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-challenge:before {
  content: "\e959";
}
.icon-fitness:before {
  content: "\e96a";
}
.icon-champion:before {
  content: "\e96b";
}
.icon-testimony:before {
  content: "\e96c";
}
.icon-competences:before {
  content: "\e96d";
}
.icon-man:before {
  content: "\e96e";
}
.icon-docs-published:before {
  content: "\e900";
}
.icon-all:before {
  content: "\e912";
}
.icon-Asset-357:before {
  content: "\e91c";
}
.icon-save:before {
  content: "\e924";
}
.icon-camera:before {
  content: "\e928";
}
.icon-dots-vertical:before {
  content: "\e930";
}
.icon-help:before {
  content: "\e941";
}
.icon-docs-postuleds:before {
  content: "\e942";
}
.icon-calendar-dots:before {
  content: "\e94d";
}
.icon-clock-fill:before {
  content: "\e954";
}
.icon-capture:before {
  content: "\e957";
}
.icon-group:before {
  content: "\e95b";
}
.icon-docs-user:before {
  content: "\e95f";
}
.icon-star:before {
  content: "\e960";
}
.icon-birthday:before {
  content: "\e961";
}
.icon-location:before {
  content: "\e962";
}
.icon-empty:before {
  content: "\e963";
}
.icon-minus:before {
  content: "\e964";
}
.icon-Asset-374:before {
  content: "\e965";
}
.icon-portfolio:before {
  content: "\e966";
}
.icon-store:before {
  content: "\e967";
}
.icon-portfolio-2:before {
  content: "\e968";
}
.icon-cart:before {
  content: "\e969";
}
.icon-msgs:before {
  content: "\e919";
}
.icon-writting-msg:before {
  content: "\e926";
}
.icon-settings-msg:before {
  content: "\e93b";
}
.icon-sent-msg:before {
  content: "\e93c";
}
.icon-draft-msg:before {
  content: "\e947";
}
.icon-answer-msg:before {
  content: "\e95a";
}
.icon-opinion:before {
  content: "\e956";
}
.icon-rewards:before {
  content: "\e958";
}
.icon-heart-outline:before {
  content: "\e92a";
}
.icon-edit:before {
  content: "\e92c";
}
.icon-download-file:before {
  content: "\e93d";
}
.icon-jobs:before {
  content: "\e952";
}
.icon-add-user:before {
  content: "\e94a";
}
.icon-close-circle:before {
  content: "\e922";
}
.icon-clock:before {
  content: "\e923";
}
.icon-upload-file:before {
  content: "\e925";
}
.icon-close-circle-fill:before {
  content: "\e943";
}
.icon-users:before {
  content: "\e944";
}
.icon-doc:before {
  content: "\e945";
}
.icon-edit-user:before {
  content: "\e946";
}
.icon-envelope-open:before {
  content: "\e948";
}
.icon-play:before {
  content: "\e949";
}
.icon-close:before {
  content: "\e94b";
}
.icon-search:before {
  content: "\e94c";
}
.icon-send:before {
  content: "\e94e";
}
.icon-trash:before {
  content: "\e94f";
}
.icon-chevron-down:before {
  content: "\e950";
}
.icon-chevron-up:before {
  content: "\e951";
}
.icon-info:before {
  content: "\e953";
}
.icon-coins:before {
  content: "\e955";
}
.icon-world:before {
  content: "\e95c";
}
.icon-healthy:before {
  content: "\e95d";
}
.icon-user-outline:before {
  content: "\e95e";
}
.icon-add:before {
  content: "\e92d";
}
.icon-phone:before {
  content: "\e935";
}
.icon-user-admin:before {
  content: "\e936";
}
.icon-eye:before {
  content: "\e937";
}
.icon-eye-slash:before {
  content: "\e938";
}
.icon-message:before {
  content: "\e939";
}
.icon-archive:before {
  content: "\e93a";
}
.icon-heart-fill:before {
  content: "\e93e";
}
.icon-chevron-right:before {
  content: "\e93f";
}
.icon-chevron-left:before {
  content: "\e940";
}
.icon-dots-v:before {
  content: "\e910";
}
.icon-business-man:before {
  content: "\e901";
}
.icon-user:before {
  content: "\e902";
}
.icon-gif:before {
  content: "\e903";
}
.icon-envelope:before {
  content: "\e904";
}
.icon-hand-heart:before {
  content: "\e905";
}
.icon-entrepreneurs:before {
  content: "\e906";
}
.icon-interest-group:before {
  content: "\e907";
}
.icon-discounts:before {
  content: "\e908";
}
.icon-calendar-time:before {
  content: "\e909";
}
.icon-community:before {
  content: "\e90a";
}
.icon-categories:before {
  content: "\e90b";
}
.icon-profile:before {
  content: "\e90c";
}
.icon-home:before {
  content: "\e90d";
}
.icon-pride-talks:before {
  content: "\e90e";
}
.icon-pride-world:before {
  content: "\e90f";
}
.icon-pride:before {
  content: "\e911";
}
.icon-billboard:before {
  content: "\e913";
}
.icon-disc:before {
  content: "\e914";
}
.icon-notifications:before {
  content: "\e915";
}
.icon-share:before {
  content: "\e916";
}
.icon-business:before {
  content: "\e917";
}
.icon-messages:before {
  content: "\e918";
}
.icon-job:before {
  content: "\e91a";
}
.icon-news:before {
  content: "\e91b";
}
.icon-school:before {
  content: "\e91d";
}
.icon-doc-search:before {
  content: "\e91e";
}
.icon-food:before {
  content: "\e91f";
}
.icon-money:before {
  content: "\e920";
}
.icon-hotel:before {
  content: "\e921";
}
.icon-groups:before {
  content: "\e927";
}
.icon-calendar:before {
  content: "\e929";
}
.icon-sound:before {
  content: "\e92b";
}
.icon-web:before {
  content: "\e92e";
}
.icon-health:before {
  content: "\e92f";
}
.icon-rewards-medal:before {
  content: "\e931";
}
.icon-map:before {
  content: "\e932";
}
.icon-doc-published:before {
  content: "\e933";
}
.icon-video:before {
  content: "\e934";
}
