@import "../../assets/theme/vars";

.course-container-view {
  padding: 1rem;
  .btn-close-modal {
    right: -13px;
    top: -45px;
  }
}

.close__in_modal {
  background: rgba(0, 0, 0, 0);
  color: #222222;
  text-decoration: none;
  position: absolute;
  right: 0;
  font-size: 1.5rem;
  top: -5px;
  transition: 0.3s ease-in-out;
  z-index: 1;
  cursor: pointer;
}

.course-view {
  &-title {
    width: 100%;
    margin-top: 6rem;
  }
  &-header {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2rem;
    padding: 1rem;
    position: relative;

    .titles {
      color: $dark;

      h3 {
        line-height: 1.5rem;
      }
    }
  }
  &-alternative {
    width: 100%;
    height: 250px;
    background: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2rem;
    padding: 2rem;
    position: relative;
    margin-bottom: 5rem;
    img {
      width: auto;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto 0;
    }
    &-info {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      h3 {
        background: $gradient-red;
        -webkit-background-clip: text;
        -o-background-clip: text;
        -ms-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 2rem;
      }
      h4 {
        color: $dark;
      }
      p {
        padding: 1rem 0;
      }
    }
    &-imgs {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .header {
        width: auto;
        height: 100%;
        position: relative;
      }
    }
  }
  &-scroll {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
  }
  &-aside {
    width: 100%;
    top: 170px;
    position: sticky;
  }
}

.border-pointed {
  border-color: $yellow;
  color: $yellow;
  &.course-view {
    width: max-content;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1rem;
    margin: 0;
    padding: 0.5rem 1rem;
  }
}

.card-billboard {
  width: 250px;
  height: 300px;
  position: relative;
  border-radius: 1.5rem;
  overflow: hidden;
  &-btn {
    color: $white;
    font-size: 5rem;
    border: 0;
    padding: 0 !important;
    background: rgba(0, 0, 0, 0);
    transition: 0.2s ease-in-out;
    &:hover {
      color: $yellow;
    }
  }
  &.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 2rem;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    display: flex;
    justify-content: center;
    border-radius: 1.8rem;
    align-items: center;
    flex-direction: column;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    a {
      min-width: 120px;
      margin-top: 1rem;
      text-decoration: none !important;
      &:hover {
        color: $yellow;
        text-decoration: none !important;
      }
      &:visited {
        text-decoration: none !important;
      }
      button {
        &:focus {
          color: $yellow;
        }
        svg {
          color: $yellow;
        }
      }
      a {
        svg {
          color: $yellow;
        }
      }
    }
    &:focus {
      color: $yellow;
    }
    button {
      background: rgba(0, 0, 0, 0);
    }
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1.5rem;
  }
  &-body {
    width: 100%;
    background: $white;
    padding: 2rem;
    border-radius: 1rem;
    position: relative;
    &-thumb {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -35px;
      right: 25px;
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
      img {
        width: 110px;
        height: 110px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
  &-title {
    max-width: 395px;
    h3 {
      color: $black;
      font-size: 1.3rem;
      line-height: 1.5rem;
    }
  }
  &-description {
    padding: 1.75rem 0 1rem;
  }
}

.card-billboard.current {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.thumb-youtube {
  border-radius: 1.5rem;
  width: 100%;
  background-size: cover;
  height: 100%;
  background-image: url(https://img.youtube.com/vi/pfOwX5h8Ong/0.jpg);
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(1.5);
}

.empty-grid {
  width: 100%;
  min-height: 100px;
  background: $white;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  padding: 1rem;
  font-family: "is_medium", sans-serif;
  margin-top: 1rem;
  margin-bottom: 1rem;
  p {
    padding-bottom: 1rem;
  }
  i {
    font-size: 4rem;
  }
}

.video-billboard {
  iframe {
    width: 100%;
    height: 415px;
    border-radius: 1rem;
    @media (max-width: 992px) {
      height: 315px;
    }
    @media (max-width: 480px) {
      height: 180px;
    }
  }
}
