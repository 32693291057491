@import "../../assets/theme/vars";

.wrapper_post_admin {
  @include bggrey;
  min-height: calc(100vh - 4rem);
  .wrapper_editor_textual {
    .post_img {
      position: relative;
      margin-left: 1rem;
      span {
        background: $light;
        color: $white_text;
        cursor: pointer;
        position: absolute;
        box-shadow: -3px 1px 6px rgba(0, 0, 0, 0.25);
        top: -10px;
        left: -10px;
        width: 25px;
        height: 25px;
        padding-left: 6px;
        padding-top: 3px;
        border-radius: 1rem;
        font-size: 0.9rem;
        &:hover {
          box-shadow: none;
          top: -11px;
          left: -11px;
        }
      }
    }
    .files {
      flex-wrap: wrap;
      justify-content: flex-start;
      & > span {
        margin-bottom: 1rem;
      }
    }
  }
  .box_iframe_course{
    .form-group{
      margin-bottom: .25rem;
    }
    .box_btn_modal{
      text-align: right;
      font-size: .90rem;
      span{
        text-decoration: underline;
        font-style: italic;
        cursor: pointer;
        &:hover{
        text-decoration: none;

        }
      }
    }
  }
}

.btn-image-table {
  cursor: pointer;
  img {
    transition: .25s ease-in-out;
  }
  &:hover {
    img {
      opacity: .7;
    }
  }
}
