@import '../../../assets/theme/vars';

.gallery {
  &-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &-item {
    width: 200px;
    padding: 0.5rem;
    position: relative;
    cursor: pointer;
    img {
      width: 200px;
      height: 100px;
      object-fit: cover;
      border-radius: .5rem;
    }
    &:hover .ico_trash{
      display: flex;
    }
    .btn-trash {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background: $red;
      color: $white;
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
      font-size: 0.9rem;
      box-shadow: -3px 1px 6px rgba(0, 0, 0, 0.25);
      &:hover {
        box-shadow: none;
        top: 0px;
        left: 0px;
      }
    }
  }
}

.list-files {
  width: 100%;
  background: $white;
  list-style: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  li {
    width: 100%;
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    i {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background: 50px;
      border: .1rem solid $light-gray;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
    }
  }
}
