@import "../../assets/theme/vars";

.surveys {
  &-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    transition: 0.25s ease-in-out;
    gap: 25px;
    &-content {
      width: 100%;
      min-height: calc(100vh - 50px);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      transition: 0.25s ease-in-out;
      .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          width: 100%;
        }
        .btns {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .btn-ellipsis {
            width: 30px;
            height: 30px;
            display: none;
            justify-content: center;
            align-items: center;
            margin-left: 0.75rem;
            border-radius: 50px;
            background: $light;
            cursor: pointer;
            color: $dark;
          }
        }
        @media (max-width: 992px) {
          .btns {
            .btn-ellipsis {
              display: flex;
            }
          }
        }
        @media (max-width: 575px) {
          .btns {
            .date {
              display: none;
            }
          }
        }
      }
      &-body {
        width: 100%;
        height: calc(100vh - 50px);
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        .surveys-list {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          grid-gap: 25px;
          .list-group-item {
            background: #ffffff;
            width: 100%;
            border: 2px solid $light;
            padding: 0.75rem;
            border-radius: 1rem;
            transition: 0.1s ease-in-out;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0 0 40px rgba($color: #000000, $alpha: 0.08);
            h6 {
              font-size: 1rem;
              margin-bottom: 0.5rem;
            }
            p {
              min-height: unset;
              margin: unset;
              text-align: left;
              background: unset;
              padding: unset;
              font-size: 0.9rem;
              color: #666666;
            }
            .btn_next {
              min-width: 35px;
              width: 35px;
              height: 35px;
              border-radius: 50px;
              background: $white;
              border: 0.1rem solid $dark;
              color: $dark;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: 0.1s ease-in-out;
              font-size: 0.75rem;
            }
            &.active {
              background: $light !important;
              color: $purple !important;
              button {
                background: $dark;
                color: $white;
                border-color: $dark;
              }
              .question {
                background: $white;
              }
            }
            &:hover {
              border: 2px solid var(--primary_color);
              color: var(--primary_color);
              button {
                background: var(--primary_color);
                color: $white;
                border: none;
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .surveys-card {
          display: none;
          background-color: #ffffff;
          height: 100vh;
          width: 100%;
          padding: 0 1rem;
          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 99;
          overflow: auto;
          &.is_show {
            display: block;
          }
          .surveys-section {
            width: 100%;
            margin: 0 auto;
            max-width: 920px;
            text-align: center;
            .sec__ttl_surveys {
              background: var(--primary_color);
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 10px;
              padding: 0.6rem 1rem;
              border-radius: 0 0 0.5rem 0.5rem;
              color: #ffffff;
              margin-bottom: 3rem;
              .drawer__ttl_info_a {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                .ico__doc {
                  font-size: 2.25rem;
                }
                .box__ttl_survey {
                  text-align: left;
                  h6 {
                    margin-bottom: 0.15rem;
                  }
                }
              }
              .drawer__ttl_info_b {
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: flex-end;
                .close_surveys {
                  width: 25px;
                  text-align: center;
                  background: #ffffff;
                  border-radius: 0.25rem;
                  cursor: pointer;
                  color: #333333;
                  margin-bottom: 0.25rem;
                }
                div {
                  text-align: left;
                  width: 100%;
                }
              }
            }
            .question {
              font-family: is_medium;
              padding-bottom: 1rem;
              border-bottom: 0.1rem solid $light-gray;
            }
            .answers {
              margin: 2rem 0 1rem;
              .form-group .form-control:focus {
                border: 3px solid var(--primary_color) !important;
              }
            }
            .box_arrows {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              margin-top: 1rem;
              margin-bottom: 65px;
              .arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0.5;
                font-family: "is_medium", sans-serif;
                font-size: 0.9rem;
                cursor: pointer;
                background: $white;
                &:hover {
                  opacity: 1;
                }
              }
              button.lf {
                i {
                  margin-right: 0.5rem;
                }
              }
              button.rg {
                i {
                  margin-left: 0.5rem;
                }
              }
            }
            // .slick-slider {
              // background: coral;
              // position: absolute;
              // max-width: 920px;
              // top: 200px;
              // left: 50%;
              // transform: translateX(-50%);
              // width: 100%;
              // .slick-slide {
              //   margin-top: 225px;
              // }
            // }
          }
        }
      }
    }
    &-aside {
      position: relative;
      width: 100%;
      max-width: 250px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin-top: 2rem;
      margin-bottom: 1rem;
      padding: 0;
      transition: 0.25s ease-in-out;
      &.is-show {
        display: block;
      }
      .btn-ellipsis-close {
        display: none;
        position: absolute;
        cursor: pointer;
        top: 10px;
        right: 10px;
        font-size: 1.25rem;
      }
      &-prev {
        margin-bottom: 4rem;
        width: 100%;
        h6 {
          width: 100%;
          font-size: 1rem;
          margin-bottom: 1rem;
        }
        p {
          width: 100%;
          background: $white;
          border-radius: 0.5rem;
          text-align: center;
          padding: 1rem;
        }
        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          margin-bottom: 0.75rem;
          span {
            color: $green;
          }
          .xtl_survey {
            font-family: "is_bold";
            line-height: 1.25;
          }
          line-height: 1;
        }
        small {
          color: #999999;
        }
      }
    }
  }
}

.wrapper_label_radio_answ {
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  .box_label_radio_answ {
    position: relative;
    background: #ffffff;
    width: 100%;
    padding-left: 0;
    .form-check-input {
      float: none;
      margin-left: 0;
      min-width: 1em;
      position: absolute;
      top: 11px;
      left: 10px;
      &:checked {
        background-color: var(--primary_color);
        border-color: var(--primary_color);
        & ~ .label_radio_answ {
          border-color: var(--primary_color);
          box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
        }
      }
    }
    .label_radio_answ {
      background: #ffffff;
      padding: 0.5rem;
      padding-left: 2rem;
      border-radius: 0.5rem;
      border: 2px solid #e5e5e5;
      text-align: left;
      width: 100%;
      height: 100%;
      cursor: pointer;
      word-wrap: break-word;
    }
  }
}

.ico-round {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.15rem solid $dark;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  font-size: 2.25rem;
  border-radius: 50%;
  i {
    color: $dark;
  }
}

.wrapper_input_file.files_up {
  color: #0eab0a;
  span {
    font-size: 2.5rem;
  }
  p {
    text-transform: uppercase;
    font-family: "is_bold";
  }
}

button {
  &.ico-left {
    margin-right: 0.75rem !important;
  }
  @media (max-width: 992px) {
    &.ico-left {
      margin-right: 0.75rem !important;
      transform: rotate(90deg);
    }
  }
}

.custom-control-input:checked ~ .custom-control-label {
  &::before {
    border-color: var(--primary_color) !important;
    background-color: var(--primary_color) !important;
    width: 20px;
    height: 20px;
    top: 0px;
    left: -30px;
  }
  &::after {
    display: none !important;
  }
}

.custom-control-label {
  cursor: pointer;
  &::before {
    width: 20px !important;
    height: 20px !important;
    top: 0px !important;
    left: -30px !important;
    border: #adb5bd solid 2px !important;
  }
}

.wrapper_side_advertising {
  width: 100%;
  margin: 0 !important;
  border-radius: 1rem !important;
  .item_advertising {
    border-radius: 1rem !important;
    width: 100%;
    img {
      border-radius: 1rem !important;
      width: 100%;
    }
  }
}

.opi {
  position: relative;
}

.hide-over {
  overflow: hidden !important;
}

.btn-ellipsis-open {
  position: absolute;
  right: 0px;
  top: -10px;
  font-size: 1.25rem;
  padding: 5px !important;
  display: none;
}

.wrapper__groups {
  thead th {
    background-color: #f9f9f9 !important;
  }
  tbody th {
    background-color: #ffffff !important;
  }
  td,
  th {
    vertical-align: middle;
  }
  .form-check {
    .form-check-input {
      transform: scale(1.3);
      cursor: pointer;
    }
  }
  .form-check-input:checked {
    background-color: var(--primary_color) !important;
    border-color: var(--primary_color) !important;
  }
  .box__flex__center {
    display: flex;
    justify-content: center;
  }
}

.wrapper__heat_maps {
  iframe {
    width: 100%;
    min-height: 600px;
  }
}

@media screen and (max-width: 1090px) {
  .surveys-container-aside {
    display: none;
    position: fixed;
    top: 45px;
    right: 10px;
    background: #ffffff;
    padding: 1rem;
    max-width: 310px;
    width: 100%;
    border-radius: 1rem;
    .btn-ellipsis-close {
      display: block;
    }
  }
  .btn-ellipsis-open {
    display: block;
  }
}

@media screen and (max-width: 680px) {
  .surveys-container-content-body {
    .surveys-card {
      padding: 0;
      .surveys-section {
        .sec__ttl_surveys {
          border-radius: 0;
          margin-bottom: 2rem;
        }
        .question {
          text-align: left;
        }
        .slick-slider {
          padding: 0 1rem;
        }
      }
    }
  }

  .wrapper_label_radio_answ {
    padding: 0 0.75rem;
    .box_label_radio_answ {
      width: 45%;
    }
  }
}

@media screen and (max-width: 460px) {
  .wrapper_label_radio_answ {
    .box_label_radio_answ {
      width: 100%;
    }
  }

  .surveys-container-content-body {
    .surveys-list .list-group-item {
      width: fit-content;
    }
    .surveys-card {
      .surveys-section {
        .sec__ttl_surveys {
          flex-direction: column;
          align-items: flex-start;
          gap: 0;
          position: relative;
          padding: 0.6rem 1rem;
          .drawer__ttl_info_a,
          .drawer__ttl_info_b {
            width: 100%;
            text-align: left;
          }
          .drawer__ttl_info_a {
            .ico__doc {
              display: none;
            }
          }
          .drawer__ttl_info_b {
            .close_surveys {
              position: absolute;
              top: 7px;
              right: 7px;
              font-size: 0.85rem;
              padding: 0 0.15rem;
            }
          }
        }
      }
    }
  }
}
