@import "../../assets/theme/vars";

.rewards {
  &-account {
    width: 100%;
    height: 100%;
    background: $white;
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    &-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-cards {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &-btns {
      width: 100%;
      .btn-gradient-orange {
        margin-bottom: 0.5rem;
      }
    }
    &-card {
      width: 260px;
      max-width: 260px;
      height: 120px;
      box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.2s ease-in-out;
      a {
        width: 100%;
        text-decoration: none;
        display: flex;
      }
      .balance {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        .icon-container {
          margin-top: 0.5rem;
          width: 60px;
          height: 60px;
          background: rgba(0, 0, 0, 0);
          border: 0.1rem solid $orange;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            font-size: 2rem;
            color: $orange;
          }
        }
      }
      .points {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h5 {
          font-family: "is_medium", sans-serif;
        }
        h6 {
          font-family: "is_book", sans-serif;
        }
      }
      &:hover {
        background: $light;
      }
    }
  }
  &-points {
    width: 100%;
    background: $white;
    border-radius: 1rem;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      border-radius: 1rem;
      width: 100%;
      height: auto;
    }
    .messages {
      width: 100%;
      height: 100%;
      .items-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .items-center {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
      }
      .items-bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p {
        min-height: 40px;
        margin-top: 0;
        background: $light-gray;
        border-radius: 1rem;
        padding: 0.5rem;
        line-height: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 0.9rem;
        margin-right: 0.5rem;
      }
    }
  }
  &-cards {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 2rem 0;
    gap: 2rem;
  }
  &-card {
    width: max-content;
    background: $white;
    border-radius: 1rem;
    transition: 0.25s ease-in-out;
    .coupon {
      background: $white;
      border-radius: 1rem;
      &-thumb {
        width: 100%;
        max-width: 250px;
        // height: 250px;
        // position: relative;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        background: $white;
        border-radius: 1rem 1rem 0 0;
        img {
          // width: 250px;
          // height: 250px;
          // object-fit: cover;
          // position: absolute;
          border-radius: 1rem 1rem 0 0;
        }
        &-text {
          max-width: 230px;
          min-height: 100px;
          width: 100%;
          height: 100%;
          padding: 0;
          padding-bottom: 0.5rem;
          font-family: "is_medium", sans-serif;
          line-height: 1.2;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        background: $white;
        border-radius: 0 0 1rem 1rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
        padding: 0.5rem;
        .category {
          background: $gradient-orange;
          border-radius: 0.3rem;
          width: max-content;
          height: max-content;
          font-size: 0.8rem;
          color: $white;
          padding: 0.25rem 0.5rem;
        }
        .points {
          font-size: 1.1rem;
          font-family: "is_medium", sans-serif;
        }
        .footer {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        button {
          width: 40px;
          height: 40px;
          border-radius: 50px;
          background: $white;
          border: 0.1rem solid $orange;
          color: $orange;
          font-size: 1.25rem;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background: $gradient-orange;
            color: $white;
            border-color: rgba(0, 0, 0, 0);
          }
        }
      }
    }

    &:hover {
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
    }
  }
}

.box_tag_category {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 3rem;
  .item_tab_category {
    cursor: pointer;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 1rem;
    color: $dark;
    padding: 0.5rem 1rem;
    border-radius: 25px;
    background: $white;
    &:last-child {
      display: none;
      margin-right: 1rem;
    }
    .tag_ico {
      font-size: 1.1rem;
      margin-right: 0.5rem;
    }
    .tag_name {
      font-family: "is_book", sans-serif;
      text-transform: capitalize;
      font-size: 0.9rem;
    }
    &:hover,
    &.active {
      color: $white;
      background: linear-gradient(to top, #f4812e, #f0562d);
      .tag_ico {
        color: $white;
      }
      svg {
        margin-top: -0.2rem;
        path {
          fill: $white;
        }
      }
    }
  }
}
.box_category_items {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  .box_item_cupon {
    margin-bottom: 1.5rem;
    width: 20%;
    padding: 0 1rem;
    &:hover {
      .info_cupon .ico_download {
        .fas {
          display: inline;
          color: var(--primary_color);
        }
        .far {
          display: none;
        }
      }
      .item_cupon {
        .box_img_cupon {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
    .item_cupon {
      .box_img_cupon {
        overflow: hidden;
        border-radius: $border_radius;
        img {
          border-radius: $border_radius;
        }
      }
    }
    .info_cupon {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0.5rem 0.5rem 0;
      .name_categ {
        color: var(--primary_color);
        font-size: 0.65rem;
        text-transform: uppercase;
      }
      .name_cupon {
        font-family: "is_medium";
      }
      .ico_download {
        font-size: 1.85rem;
        border-left: 1px solid #ccc;
        padding-left: 0.5rem;
        color: #aaaaaa;
        cursor: pointer;
        .fas {
          display: none;
        }
      }
    }
  }
}

.wrapper_table {
  thead th {
    border-top: 0 !important;
  }
  tbody {
    font-size: 0.9rem;
    font-family: "is_light";
  }
}

.wrapper_list_history {
  .list_history {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #dedede;
    font-size: 0.9rem;
    .item_list_history {
      text-align: center;
      .img_cupon {
        height: 90px;
        border-radius: 5px;
      }
      .btndown {
        min-width: 120px !important;
      }
    }
  }
}

.textPointReward {
  font-size: 11px;
}
.canjea {
  color: #c91616;
  font-family: "is_medium";
}

.container-redemption {
  margin-bottom: 4rem;
  h3 {
    margin-top: 2rem;
  }
  &-header {
    width: 100%;
    background: $white;
    border-radius: 1rem;
    padding: 1rem;
  }
  &-table {
    width: 100%;
    background: $white;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 1rem;
    .tag {
      border-radius: 0.5rem;
      padding: 0.1rem;
      background: $dark;
      color: $white;
      text-align: center;
    }
  }
}
