.form-register {
  .ant-form {
    &-item {
      &-required {
        &:before {
          display: none !important;
        }
      }
      &-feedback-icon {
        line-height: 0;
      }
      &-control-input {
        &-content {
          .ant-input {
            background: rgba(0,0,0,.005);
            &-affix-wrapper {
              border-color: $lighter-gray;
              background: rgba(0,0,0,.01);
              &:hover {
                background: rgba(0,0,0,.03);
              }
              &-focused {
                box-shadow: none;
              }
              &-disabled {
                background: $lighter-gray;
                .ant-input-prefix {
                  color: $light;
                }
                &:hover {
                  background: $lighter-gray;
                }
              }
            }
            &:hover {
              background: rgba(0,0,0,.005);
            }
            &-prefix {
              color: $lighter-gray;
            }
          }
          .ant-picker{
            background: rgba(0,0,0,.01);
            border-color: $lighter-gray;
            &-focused {
              box-shadow: none;
            }
            &-input {
              background: rgba(0,0,0,.01);
              border-color: $lighter-gray;
            }
          }
          .ant-select {
            &-selector {
              background: rgba(0,0,0,.01);
              border-color: $light !important;
            }
            &-focused {
              .ant-select-selector {
                border-color: $light !important;
                box-shadow: none !important;
              }
            }
            &-item {
              &-option {
                &-selected {
                  background: $whine !important;
                }

              }
            }
          }
        }
      }
    }
  }
}

.ant-select-dropdown .ant-select-item-option-selected {
  background: $whine !important;
  color: $white !important;
}

.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background: $whine !important;
  }
}
