@import "../../assets/theme/vars";

.caring-community {
  &-container {}
  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    .badge-dashed {
      &.green {
        width: 100%;
      }
    }
  }
}

.content_ttls {
  background: #8edcf0;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  height: 170px;
  margin-bottom: 2rem !important;
  .ttl {
    color: #38707e !important;
  }
  .box_img_surveys {
    text-align: center;
    min-width: 120px;
    max-width: 300px;
    img {
      width: 100%;
    }
  }
  .minw {
    max-width: 400px;
  }
}
.content_card_audience {
  padding: 1.25rem 2rem;
  .box_list_audience {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    .item_audience {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.3rem;
      border-radius: 5px;
      margin: 0.15rem 0;
      font-size: 0.95rem;
      &:hover {
        background: $bg_grey;
      }
      .box_info_audience {
        width: 100%;
        padding-right: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .box_icon {
          min-width: 65px;
          font-size: 2.5rem;
          text-align: center;
        }
      }
      .box_btns {
        margin-top: 0.5rem;
      }
    }
  }
}
.item_carin_box {
  padding: 0.85rem;
  .ttls {
    min-height: 50px;
  }
  .text-description {
    color: #888888;
    min-height: 65px;
    line-height: 1.2;
    font-size: .95rem;
    margin-top: .25rem;
  }
}

.iframe-powerbi{
  background: $white;
  border-radius: 1rem;
  padding: 1rem;
  margin: 2rem 0;
  iframe{
    width: 100% !important;
  }

}

.cards-caring {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.card-caring {
  width: 320px;
  height: 450px;
  border-radius: 0 0 .5rem .5rem;
  transition: .2s ease-in-out;
  &-content {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    background: $white;
    a {
      text-decoration: none;
    }
    a h5 {
      width: 100%;
      padding: .5rem 1rem;
      background: $white;
      border-radius: 1rem 1rem 0 0;
      font-size: 1.2rem;
      font-family: "is_bold", sans-serif;
      color: $dark;
      text-transform: capitalize;
    }
    .container-img {
      overflow: hidden;
      padding: 1rem;
      border-radius: 1rem;
      box-shadow: 0 0 .1rem rgba(0,0,0,.1);
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 1rem 1rem 0 0;
        transition: .3s ease-in-out;
      }
    }
    &-info {
      width: 100%;
      margin-top: -.5rem;
      border-radius: 1rem;
    }
    .text-description {
      width: 100%;
      height: 100%;
      padding: 1rem;
      display: flex;
      border-radius: 0 0 1rem 1rem;
    }
    &:hover {
      box-shadow: 0 0 .5rem rgba(0,0,0,.1);
    }
    &:hover .container-img img {
      transform: scale(1.2);
    }
  }
}
