@import "../../assets/theme/vars";

.tabs {
  &-help {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .nav-pills {
      gap: 1rem;
      margin-bottom: 2rem;
      .nav-item {
        .nav-link {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background: $white;
          border: .1rem solid $light;
          color: $dark;
          span {
            background: $white;
            border: .1rem solid $light;
            width: 30px;
            height: 30px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $dark;
          }
          h5 {
            font-size: 1rem;
            margin-left: .5rem;
          }
          &.active {
            background: $light;
            text-decoration: none;
            span {
              background: $dark;
              color: $white;
            }
          }
        }
      }
    }
    &-content {
      width: 100%;
      height: 100%;
      background: $white;
      border-radius: 1rem;
      padding: 1rem;
      &-pane {
        width: 100%;
        height: 100%;
        margin: 0;
      }
      &-body {
        width: 100%;
        height: 100%;
        .info {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 1rem;
          span {
            width: 30px;
            height: 30px;
            background: $white;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          h4 {
            margin-left: .5rem;
          }
        }
        .video {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          video {
            width: 100%;
            border-radius: 1rem;
          }
        }
        .text {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          background: $white;
          border: .1rem solid $light;
          border-radius: 1rem;
          padding: 1rem;
          h5 {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

.banner-help {
  width: 100%;
  border-radius: 1rem;
  max-height: 250px;
  object-fit: cover;
  margin-top: 2rem;
}
