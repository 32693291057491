@import "../../assets/theme/vars";

.card-user {
  width: 100%;
  background: transparent;
  border-radius: 1rem;
  // padding: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // box-shadow: 0 0 .7rem rgba(0,0,0,.1);
  margin-bottom: 2rem;
  transition: 0.3s ease-in-out;
  .card-user-avatar {
    width: 160px;
    height: 160px;
    padding: 0;
    border-radius: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .rotating {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      position: absolute;
    }
    img {
      width: 140px;
      height: 140px;
      border-radius: 100%;
      background: $white;
      padding: 0.3rem;
      object-fit: cover;
      position: absolute;
    }
  }
  h3 {
    font-family: "is_bold", sans-serif;
    font-size: 1.1rem;
    margin-top: 0.25rem;
    line-height: 1.2;
    margin-bottom: 0rem;
    text-align: center;
  }
  .p_email {
    font-size: 0.9rem;
    color: $dark;
    margin-bottom: 1.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    padding: 0 .25rem;
  }
}

