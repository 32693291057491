@import "../../assets/theme/vars";

.profile {
  width: 100%;
  border-radius: 1rem;
  background: $white;
  padding: 2rem;
  .btn-white {
    &.sm {
      width: 40px !important;
      font-size: 1.5rem;
      padding: 0 !important;
    }
  }
  &-avatar {
    width: 180px;
    margin: 0 auto;
    height: 165px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .flag {
      width: 165px;
      height: 165px;
      position: absolute;
      border-radius: 100%;
    }
    img {
      width: 150px;
      height: 150px;
      position: absolute;
      object-fit: cover;
      border-radius: 100%;
      border: .3rem solid $white;
    }
  }
  .file {
    width: 40px;
    height: 40px;
    background: $white;
    box-shadow: 0 0 .5rem rgba(0,0,0,0.3);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    right: 0;
    transition: .2s ease-in-out;
    input[type=file] {
      display: none;
    }
    color: $dark;
    font-size: 1.2rem;
    &:hover {
      background: $dark;
      box-shadow: 0 0 .5rem rgba(0,0,0,0.3);
      color: $white;
    }
  }
  &-info {
    text-align: left;
    padding: 1rem .5rem;
    h4 {
      font-family: "is_bold", sans-serif;
    }
    h5 {}
    h6 {}
    &-extra {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      p {
        margin-top: 0;
        line-height: 2rem;
        display: flex;
        color: $dark;
        align-items: center;
        margin-right: 1rem;
        span {
          i {margin-right: .25rem; margin-bottom: .3rem; fill: $black;}
          svg {margin-right: .25rem; margin-bottom: .3rem; fill: $black;}
        }
      }
    }
  }
  &-activities {}
}
.my-flag {
  width: 100%;
  background: $white;
  padding: 1rem 1rem 0 1rem;
  border-radius: 1rem;
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-left: 0;
    li {
      margin-right: .5rem;
      margin-bottom: 1rem;
      text-align: center;
      .form-check {
        padding-left: 0;
      }
      input[type=radio] {
        display: none;
      }
      .flag {
        width: 50px;
        height: 50px;
        cursor: pointer;
        border-radius: 100%;
        border: .2rem solid $white;
        box-shadow: 0 0 .5rem rgba(0,0,0,.2);
        transition: .2s ease-in-out;
        &:hover {
          border: .2rem solid $dark;
          box-shadow: 0 0 .5rem rgba(0,0,0,.5);
        }
        &.active {
          border: .2rem solid $dark;
          box-shadow: 0 0 .5rem rgba(0,0,0,.5);
        }
      }
      p {
        font-size: .9rem;
        color: $dark;
        line-height: 1rem;
      }
    }
  }
}
.preferences-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  background: $white;
  border-radius: .7rem;
  padding: .5rem 1rem .5rem 1rem;
  &-checkbox {
    width: max-content;
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;
    cursor: pointer;
    color: $dark;
    input[type=checkbox] {display: none;}
    p {
      padding-left: 1.5rem;
      font-size: .9rem;
    }
  }
  @media (max-width:575px) {
    &-checkbox{
      p{
        width: 280px;
      }
    }
  }
  @media (max-width:420px) {
    &-checkbox{
      p{
        width: 200px;
      }
    }
  }
}
.notification {
  display: block;
  width: 45px;
  height: 25px;
  cursor: pointer;
  position: relative;
  input[type="checkbox"]:checked+.notification-slider .notification-knob {
    left: calc(100% - 19px - 3px);
  }
  input[type="checkbox"]:checked+.notification-slider {
    background-color: $orange;
  }
  &-slider {
    background-color: #ccc;
    position: absolute;
    border-radius: 100px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  &-knob {
    width: calc(25px + 6px);
    height: calc(25px + 6px);
    border-radius: 50%;
    left: -3px;
    top: -3px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 6px rgba(153, 153, 153, 0.75);
    box-shadow: 0 2px 6px rgba(153, 153, 153, 0.75);
    position: absolute;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
}
.tabs-user {
  margin: 1rem 0;
    ul.nav-tabs {
      margin-bottom: 1rem;
      li.nav-item {
        height: max-content;
        padding: 1rem 0;
        margin-right: 1rem;
        transition: .2s ease-in-out;
        .nav-link {
          width: 100%;
          border-radius: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: .5rem .5rem;
          border: .1rem solid $light;
          background: $white;
          transition: .2s ease-in-out;
          p {
            color: $gray;
            transition: .2s ease-in-out;
          }
          span {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background: $white;
            border: .1rem solid $light;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: .2s ease-in-out;
            color: $gray;
            font-size: 1.7rem;
          }
          &.active {
            background: $white;
            border: .1rem solid $orange;
            box-shadow: none;
            span {
              color: $orange;
              background: rgba(0,0,0,0);
              border: .1rem solid $orange;
            }
            p {
              color: $orange;
            }
          }
        }
      }
    }
  .tab-content {
    .tab-pane {
      &.active {}
      h5 {
        font-family: "is_bold", sans-serif;
      }
      .table-activities {
        width: 100%;
        background: $white;
        padding: 1rem !important;
        border-radius: 1rem;
        border-collapse: separate;
        border-spacing: 0 .3em;
        thead {
          tr {
            th {
              font-family: "is_medium", sans-serif;
            }
          }
          border-color: $white;
        }
        tbody {
          tr {
            border: 0;
            border-radius: .5rem;
            box-shadow: 0 0 .1rem rgba(0,0,0,.2);
            td {
              height: 69px;
              max-height: 69px;
              border: 0;
              transition: .2s ease-in-out;
              .td-center {
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }
              .avatar-container {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .avatar {
                  display: flex;
                  padding: .1rem;
                  background: $white;
                  cursor: pointer;
                  border-radius: 100%;
                  box-shadow: 0 0 .3rem rgba(0,0,0,.1);
                  img {
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                  }
                }
                p {
                  padding-left: 1rem;
                  font-size: 1rem;
                }
              }
              .link-data {
                text-decoration: none;
              }
              &:first-child {
                border-radius: .5rem 0 0 .5rem;
              }
              &:last-child {
                border-radius: 0 .5rem .5rem 0;
              }
              .d-flex-center {
                width: 100%;
                height: 100%;
              }
              .btn-icon-table {
                background: rgba(0,0,0,0);
                border-radius: .25rem;
                transition: .2s ease-in-out;
                &:hover {
                  color: $dark;
                  background: $white;
                  box-shadow: 0 0 .2rem rgba(0,0,0,.1);
                  &.delete {
                    color: $red;
                  }
                  &.save {
                    color: $green;
                  }
                }
              }
            }
          }
        }
      }
      .table-services-published {
        width: 100%;
        background: $white;
        padding: 1rem !important;
        border-radius: 1rem;
        border-collapse: separate;
        border-spacing: 0 .3em;
        thead {
          tr {
            th {
              font-family: "is_medium", sans-serif;
            }
          }
          border-color: $white;
        }
        tbody {
          tr {
            border: 0;
            border-radius: .5rem;
            box-shadow: 0 0 .1rem rgba(0,0,0,.2);
            td {
              height: 69px;
              max-height: 69px;
              border: 0;
              transition: .2s ease-in-out;
              .td-center {
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }
              .avatar-container {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .avatar {
                  display: flex;
                  padding: .1rem;
                  background: $white;
                  cursor: pointer;
                  border-radius: 100%;
                  box-shadow: 0 0 .3rem rgba(0,0,0,.1);
                  img {
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                  }
                }
                p {
                  padding-left: 1rem;
                  font-size: 1rem;
                }
              }
              .link-data {
                text-decoration: none;
              }
              &:first-child {
                border-radius: .5rem 0 0 .5rem;
              }
              &:last-child {
                border-radius: 0 .5rem .5rem 0;
              }
              .d-flex-center {
                width: 100%;
                height: 100%;
              }
              .btn-icon-table {
                background: rgba(0,0,0,0);
                border-radius: .25rem;
                transition: .2s ease-in-out;
                &:hover {
                  color: $dark;
                  background: $white;
                  box-shadow: 0 0 .2rem rgba(0,0,0,.1);
                  &.delete {
                    color: $red;
                  }
                  &.save {
                    color: $green;
                  }
                }
              }
            }
          }
        }
      }
      .table-postulations {
        width: 100%;
        background: $white;
        padding: 1rem !important;
        border-radius: 1rem;
        border-collapse: separate;
        border-spacing: 0 .3em;
        thead {
          tr {
            th {
              font-family: "is_medium", sans-serif;
            }
          }
          border-color: $white;
        }
        tbody {
          tr {
            border: 0;
            border-radius: .5rem;
            box-shadow: 0 0 .1rem rgba(0,0,0,.2);
            td {
              height: 69px;
              max-height: 69px;
              border: 0;
              transition: .2s ease-in-out;
              .td-center {
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }
              .avatar-container {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .avatar {
                  display: flex;
                  padding: .1rem;
                  background: $white;
                  cursor: pointer;
                  border-radius: 100%;
                  box-shadow: 0 0 .3rem rgba(0,0,0,.1);
                  img {
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                  }
                }
                p {
                  padding-left: 1rem;
                  font-size: 1rem;
                }
              }
              .link-data {
                text-decoration: none;
              }
              &:first-child {
                border-radius: .5rem 0 0 .5rem;
              }
              &:last-child {
                border-radius: 0 .5rem .5rem 0;
              }
              .d-flex-center {
                width: 100%;
                height: 100%;
              }
              .btn-icon-table {
                background: rgba(0,0,0,0);
                border-radius: .25rem;
                transition: .2s ease-in-out;
                &:hover {
                  color: $dark;
                  background: $white;
                  box-shadow: 0 0 .2rem rgba(0,0,0,.1);
                  &.delete {
                    color: $red;
                  }
                  &.save {
                    color: $green;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.modal-profile {
  .modal-content {
    border-radius: 1rem;
    padding: 1rem;
  }
  h3 {
    padding-bottom: 1rem;
  }
  .btn-gradient-orange {
    width: 100% !important;
    border-radius: .5rem !important;
    border: 0;
    margin-top: 1rem;
    &:before {
      border-radius: .5rem !important;
    }
  }
  input[type=text] {
    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}
