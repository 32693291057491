@import "../../assets/theme/vars";

.dashboard {
  padding: 1rem;
  &-header {
    width: 99%;
    height: 80px;
    border-radius: 1rem;
    margin-top: .25rem;
    left: 8px;
    background: $white;
    box-shadow: 0 0 .5rem rgba(0,0,0,.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    position: fixed;
    z-index: 99;
    transition: .3s ease-in-out;
    @media(max-width: 992px) {
      left: 6px;
    }
    @media(max-width: 768px) {
      left: 6px;
    }
    @media(max-width: 575px) {
      left: 3px;
    }
    &-brand {
      width: 30%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: .3s ease-in-out;
      a {
        img {
          width: 150px;
          height: auto;
        }
      }
      .doodle {
        width: 100%;
        height: 65px;
        padding: 0 .5rem;
        text-decoration: none;
        overflow-y: hidden;
        position: relative;
        &-content {
          display: flex;
          align-items: center;
        }
        .drawer-particles {
          left: 6px;
        }
        &-img {
          width: auto;
          height: 60px;
        }
        &-info {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          h6 {
            font-family: "is_bold",sans-serif;
            color: $dark;
            line-height: unset;
            margin: 0;
            font-size: 1rem;
          }
          p {
            font-family: "is_book", sans-serif;
            line-height: 1rem;
            font-size: .9rem;
            margin-bottom: 0;
          }
        }
      }
    }
    &-menu {
      width: 40%;
      transition: .3s ease-in-out;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          margin-right: 1rem;
          &:last-child { margin-right:  0; }
          .rs-btn-toolbar.user {
            .rs-dropdown {
              &.btn-menu.userLink {
                .rs-dropdown-toggle {
                  background: $gradient-orange;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
              &-toggle {
                width: 50px;
                height: 50px;
                min-width: 50px !important;
                color: $gray;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0,0,0,0);
                padding: 0;
                cursor: pointer;
                border-radius: .8rem;
                font-size: 1.75rem;
                transition: all .2s ease-in-out;
                &:hover {
                  &.user {
                    background: $gradient-orange;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
                .circle-notification {
                  width: 9px;
                  height: 9px;
                  background: $orange;
                  position: absolute;
                  display: flex;
                  border-radius: 50px;
                  top: 6px;
                  right: 6px;
                }
              }
              &-toggle-caret {
                display: none;
              }
              &-open {
                &.btn-menu.user {
                  .rs-dropdown-toggle {
                    background: $gradient-orange;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
                &-toggle {
                  color: $dark;
                }
              }
              &-menu {
                padding: .5rem;
                box-shadow: 0 0 .3rem rgba(0,0,0,.1);
                .rs-dropdown-item {
                  margin: 0;
                  .rs-dropdown-item-content{
                    width: 100%;
                    min-width: 180px;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    padding: .5rem 1rem;
                    align-items: center;
                    background: $white;
                    color: $dark;
                    border: none;
                    border-radius: .5rem;
                    text-decoration: none;
                    transition: all .2s ease-in-out;
                    .sublinks {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      color: $dark;
                      text-decoration: none;
                      font-size: 14px;
                      transition: all .2s ease-in-out;
                      i {
                        width: 30px;
                        height: 30px;
                        opacity: 1;
                        display: flex;
                        transform: unset;
                        justify-content: space-evenly;
                        align-items: center;
                        background: $light;
                        border-radius: 25px;
                        margin-right: .5rem;
                        transition: .2s ease-in-out;
                      }
                      p {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: $dark;
                        text-decoration: none;
                        .notification {
                          width: 20px;
                          height: max-content;
                          font-family: "is_medium", sans-serif;
                          background: $light;
                          border-radius: 50px;
                          margin-left: .5rem;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        }
                      }
                    }
                    i {
                      opacity: 0;
                      transform: translate(-.5rem,0);
                      transition: .1s ease-in-out;
                    }
                  }
                  &.user {
                    &:hover {
                      border-radius: .5rem;
                      background: $light;
                      .rs-dropdown-item-content{
                        background: $light;
                        .sublinks {
                          p {
                            color: $dark;
                          }
                          i {
                            color: $white;
                            transform: unset;
                            background: $gradient-orange;
                          }
                        }
                        i {
                          opacity: 1;
                          transform: translate(0,0);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .rs-btn-toolbar.categories {
            .rs-dropdown {
              &.btn-menu.categoriesLink{
                .rs-dropdown-toggle {
                  background: $gradient-red;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
              &-toggle {
                width: 50px;
                height: 50px;
                min-width: 50px !important;
                color: $gray;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0,0,0,0);
                padding: 0;
                cursor: pointer;
                border-radius: .8rem;
                font-size: 1.75rem;
                transition: all .2s ease-in-out;
                &:hover {
                  &.categories {
                    background: $gradient-red;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
                .circle-notification {
                  width: 9px;
                  height: 9px;
                  background: $red;
                  position: absolute;
                  display: flex;
                  border-radius: 50px;
                  top: 6px;
                  right: 6px;
                }
              }
              &-toggle-caret {
                display: none;
              }
              &-open {
                &.btn-menu.categories {
                  .rs-dropdown-toggle {
                    background: $gradient-red;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                  &:hover {
                    color: $dark;
                  }
                }
                &-toggle {
                  color: $dark;
                  &:hover {
                    color: $dark;
                  }
                }
              }
              &-menu {
                padding: .5rem;
                box-shadow: 0 0 .3rem rgba(0,0,0,.1);
                .rs-dropdown-item {
                  margin: 0;
                  .rs-dropdown-item-content{
                    width: 100%;
                    min-width: 180px;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    padding: .5rem 1rem;
                    align-items: center;
                    background: $white;
                    color: $dark;
                    border: none;
                    border-radius: .5rem;
                    text-decoration: none;
                    transition: all .2s ease-in-out;
                    .sublinks {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      color: $dark;
                      text-decoration: none;
                      font-size: 14px;
                      transition: all .2s ease-in-out;
                      i {
                        width: 30px;
                        height: 30px;
                        opacity: 1;
                        display: flex;
                        transform: unset;
                        justify-content: space-evenly;
                        align-items: center;
                        background: $light;
                        border-radius: 25px;
                        margin-right: .5rem;
                        transition: .2s ease-in-out;
                      }
                      p {
                        display: flex;
                        justify-content: flex-start;
                        color: $dark;
                        text-decoration: none;
                        .notification {
                          width: 20px;
                          height: max-content;
                          font-family: "is_medium", sans-serif;
                          background: $light;
                          border-radius: 50px;
                          margin-left: .5rem;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        }
                      }
                    }
                    i {
                      opacity: 0;
                      transform: translate(-.5rem,0);
                      transition: .1s ease-in-out;
                    }
                  }
                  &.categories {
                    &:hover {
                      border-radius: .5rem;
                      background: $light;
                      .rs-dropdown-item-content{
                        background: $light;
                        .sublinks {
                          p {
                            color: $dark;
                          }
                          i {
                            color: $white;
                            transform: unset;
                            background: $gradient-red;
                          }
                        }
                        i {
                          opacity: 1;
                          transform: translate(0,0);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .rs-btn-toolbar.community {
            .rs-dropdown {
              &.btn-menu.communityLink{
                .rs-dropdown-toggle {
                  background: $gradient-green !important;
                  -webkit-background-clip: text !important;
                  -webkit-text-fill-color: transparent !important;
                }
              }
              &-toggle {
                width: 50px;
                height: 50px;
                min-width: 50px !important;
                color: $gray;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0,0,0,0);
                padding: 0;
                cursor: pointer;
                border-radius: .8rem;
                font-size: 1.75rem;
                transition: all .2s ease-in-out;
                &:hover {
                  &.community{
                    background: $gradient-green;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
              }
              &-toggle-caret {
                display: none;
              }
              &-open {
                &.btn-menu.community {
                  .rs-dropdown-toggle {
                    background: $gradient-green;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                  &:hover {
                    color: $dark;
                  }
                }
              }
              &-menu {
                padding: .5rem;
                box-shadow: 0 0 .3rem rgba(0,0,0,.1);
                .rs-dropdown-item {
                  margin: 0;
                  .rs-dropdown-item-content{
                    width: 100%;
                    min-width: 180px;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    padding: .5rem 1rem;
                    align-items: center;
                    background: $white;
                    color: $dark;
                    border: none;
                    border-radius: .5rem;
                    text-decoration: none;
                    transition: all .2s ease-in-out;
                    .sublinks {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      color: $dark;
                      text-decoration: none;
                      font-size: 14px;
                      transition: all .2s ease-in-out;
                      i {
                        width: 30px;
                        height: 30px;
                        opacity: 1;
                        display: flex;
                        transform: unset;
                        justify-content: space-evenly;
                        align-items: center;
                        background: $light;
                        border-radius: 25px;
                        margin-right: .5rem;
                        transition: .2s ease-in-out;
                      }
                      p {
                        color: $dark;
                        text-decoration: none;
                      }
                    }
                    i {
                      opacity: 0;
                      transform: translate(-.5rem,0);
                      transition: .1s ease-in-out;
                    }
                  }
                  &.community {
                    &:hover {
                      border-radius: .5rem;
                      background: $light;
                      .rs-dropdown-item-content{
                        background: $light;
                        .sublinks {
                          p {
                            color: $dark;
                          }
                          i {
                            color: $white;
                            transform: unset;
                            background: $gradient-green;
                          }
                        }
                        i {
                          opacity: 1;
                          transform: translate(0,0);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .rs-btn-toolbar.dashboard {
            width: 50px;
            height: 50px;
            min-width: 50px !important;
            color: $gray;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0,0,0,0);
            padding: 0;
            cursor: pointer;
            border-radius: .8rem;
            font-size: 1.75rem;
            transition: all .2s ease-in-out;
            &:hover {
              &.dashboard {
                background: $black;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }
          .rs-btn-toolbar.dashboardLink {
            background: $black;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .rs-btn-toolbar.admin {
            width: 50px;
            height: 50px;
            min-width: 50px !important;
            color: $gray;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0,0,0,0);
            padding: 0;
            cursor: pointer;
            border-radius: .8rem;
            font-size: 1.75rem;
            transition: all .2s ease-in-out;
            &:hover {
              &.admin {
                background: $gradient-blue;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }
          .rs-btn-toolbar.adminLink {
            background: $gradient-blue;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .rs-dropdown {
            &-menu {
              left: -75px !important;
            }
          }
        }
      }
    }
    &-user {
      width: 30%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      transition: .3s ease-in-out;
      .btns-dropdowns {
        transition: .3s ease-in-out;
        display: flex;
        .btn-menu {
          position: relative;
          margin-right: .5rem;
        }
        .rs-dropdown {
          transition: .3s ease-in-out;
          &-toggle-caret {
            display: none;
          }
          &-menu {
            width: 200px;
            max-width: 200px;
            background: white;
            border-radius: 0.8rem;
            transform: translate(0%, 60px) !important;
          }
        }
        .rs-btn-toolbar {
          .rs-dropdown.share {
            width: max-content;
            display: flex;
            position: relative;
            gap: 1rem;
            transition: .2s ease-in-out;
            .rs-dropdown-toggle-caret {
              display: none;
            }
            .rs-dropdown-menu {
              background: $white;
              box-shadow: 0 0 .5rem rgba(0,0,0,.1);
              border-radius: 1rem;
              width: 300px;
              max-width: 300px;
              position: absolute;
              padding: 1rem .5rem;
              left: unset;
              top: -.5rem;
              right: 0;
              height: 200px;
              .sharing-content {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .alert.alert-success {
                  position: absolute;
                  bottom: 25px;
                  left: 53px;
                  z-index: 1;
                  background: $white;
                  .btn-close {
                    padding: .55rem;
                    cursor: pointer;
                  }
                }
                p {
                  font-family: "is_bold", sans-serif;
                  margin-bottom: 0;
                }
                .icons {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  flex-wrap: wrap;
                  margin: 0;
                  padding: 1rem 0;
                  button {
                    width: 40px;
                    height: 40px;
                    transition: .2s ease-in-out;
                    svg {
                      width: 40px;
                      height: 40px;
                      transition: .2s ease-in-out;
                      circle {
                        fill: $dark;
                        transition: .2s ease-in-out;
                      }
                    }
                    &:hover {
                      &.facebook {
                        svg {
                          circle {
                            fill: $darkblue;
                          }
                        }
                      }
                      &.whatsapp {
                        svg {
                          circle {
                            fill: $green;
                          }
                        }
                      }
                      &.twitter {
                        svg {
                          circle {
                            fill: $skyblue;
                          }
                        }
                      }
                      &.linkedin {
                        svg {
                          circle {
                            fill: #0e76a8;
                          }
                        }
                      }
                      &.email {
                        svg {
                          circle {
                            fill: $pink;
                          }
                        }
                      }
                    }
                  }
                }
                .copy-paste {
                  .input-group {
                    .form-control {
                      border-radius: 25px 0 0 25px;
                      background: $light;
                      color: $dark;
                      font-size: .9rem;
                    }
                    .input-group-text {
                      border-radius: 0 25px 25px 0;
                      background: $dark;
                      border: 0;
                      transition: 2s ease-in-out;
                      button {
                        background: rgba(0,0,0,0);
                        svg {
                          fill: $white;
                        }
                      }
                      &:hover {
                        box-shadow: 0 0 .5rem rgba(0,0,0,.5);
                      }
                    }
                  }
                }
              }
            }
            &.rs-dropdown-open {
              .rs-dropdown-toggle {
                color: $dark;
              }
            }
          }
          .rs-dropdown.notifications {
            width: max-content;
             display: flex;
             gap: 1rem;
              position: relative;
             transition: .2s ease-in-out;
             .rs-dropdown-toggle-caret {
               display: none;
             }
             .rs-dropdown-menu {
               background: $white;
               box-shadow: 0 0 .5rem rgba(0,0,0,.1);
               border-radius: 1rem;
               width:300px;
               position: absolute;
               padding: 0;
               left: unset;
               top: -.5rem;
               right: 100px;
               .notifications-body  {
                 background: $white;
                 box-shadow: 0 0 .5rem rgba(0,0,0,.1);
                 width: 300px;
                 position: relative;
                 padding: 0;
                 border-radius: 1rem;
                 .notifications-title {
                   width: 100%;
                   height: 50px;
                   font-family: "is_bold", sans-serif;
                   font-size: 1.2rem;
                   background: $white;
                   border-radius: 1rem 1rem 0 0;
                   display: flex;
                   justify-content: flex-start;
                   align-items: center;
                   padding: .5rem 1rem 1rem 1rem;
                 }
                 .notifications-content {
                   width: 100%;
                   height: 400px;
                   overflow-y: auto;
                   padding: .5rem;
                   border-radius: 1rem;
                   background: $white;
                   margin-top: -10px;
                   display: flex;
                   margin-left: 0;
                   list-style: none;
                   flex-direction: column;
                   &.empty {
                     height: 180px;
                   }
                   &::-webkit-scrollbar  {
                     width: .2rem;
                     border-radius: 50px;
                   }
                   &::-webkit-scrollbar-thumb {
                     box-shadow: inset 0 0 6px $dark;
                     border-radius: 50px;
                   }
                   &-item {
                     margin-bottom: 0.5rem;
                     background: $light;
                     border: .1rem solid $light;
                     padding: .3rem;
                     border-radius: 0.5rem;
                     a {
                       width: 100%;
                       display: flex;
                       flex-direction: column;
                       text-decoration: none;
                       color: $dark;
                       p {
                         font-size: .9rem;
                         line-height: .9rem;
                       }
                       span {
                         width: unset;
                         height: unset;
                         font-size: .8rem;
                         font-family: "is_medium", sans-serif;
                         line-height: .8rem;
                         padding: .1rem .1rem .3rem .1rem;
                         background: rgba(0,0,0,0);
                       }
                     }
                     &:first-child {
                       margin-top: 1rem;
                     }
                     &:last-child {
                       margin-bottom: 0;
                     }
                     &:hover {
                       background: $light-gray;
                     }
                   }
                   &-empty {
                     width: 100%;
                     height: 100%;
                     display: flex;
                     justify-content: center;
                     align-items: center;
                     flex-direction: column;
                     span {
                       font-size: .9rem;
                     }
                     svg {
                       width: 30px;
                       height: 30px;
                       margin-bottom: 1rem;
                     }
                   }
                 }
               }
             }
              &.rs-dropdown-open {
                .rs-dropdown-toggle {
                  color: $dark;
                }
              }
           }
        }
      }
      .rs-btn-toolbar {
        .button-user {
          width: 100%;
          min-width: 100px;
          height: 50px;
          display: flex;
          position: relative;
          border-radius: 50px;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          background: $white;
          border: none;
          padding: 0;
          box-shadow: 0 0 .2rem rgba(0,0,0,.1);
          transition: .3s ease-in-out;
          .quantity {
            position: absolute;
            z-index: 2;
            color: $dark;
            background: $light;
            border-radius: 0.25rem;
            width: 25px;
            max-width: 30px;
            top: 0;
            font-size: 0.8rem;
            line-height: 0;
            left: 2.7rem;
            height: 15px;
            font-family: "is_bold", sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .rs-dropdown-toggle {
            width: 100%;
            height: 50px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            border-radius: 25px;
            .rs-dropdown-toggle-caret {
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0;
              margin: 0 0 0 2rem;
              font-size: 1.5rem;
              position: relative;
              margin-bottom: .8rem;
            }
          }
          .avatar {
            border-radius: 50px;
            padding: .2rem;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            .rotating {
              width: 40px;
              height: 40px;
              border-radius: 100%;
              position: absolute;
            }
            img {
              width: 36px;
              height: 36px;
              border-radius: 100%;
              background: $white;
              padding: .1rem;
              object-fit: cover;
              position: absolute;
            }
          }
          &:focus, &:hover {
            background: $light !important;
          }
          .rs-dropdown-menu {
            width: 200px;
            right: 0;
            top: 3.5rem;
            left: unset;
            padding: .2rem;
            .rs-dropdown-item {
              &-content {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background: $white;
                color: $gray;
                border-radius: .5rem;
                transition: .2s ease-in-out;
                i {
                  width: 25px;
                  height: 25px;
                  border-radius: 50px;
                  margin-right: .5rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                p {
                  font-size: 1rem;
                  transition: .2s ease-in-out;
                }
                &:hover {
                  background: $light;
                  p {
                    transform: translate(.3rem,0);
                  }
                }
              }
            }
          }
        }
      }
      .rs-dropdown.dropdown-notifications {
        width: 100%;
        .rs-dropdown-toggle {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background: $white;
          border-radius: .5rem;
          padding: 8px 12px;
          clear: both;
          line-height: 1.42857143;
          color: $gray;
          white-space: nowrap;
          cursor: pointer;
          transition: 0.2s ease-in-out;
          .rs-dropdown-toggle-caret {
            display: none;
          }
          i {
            width: 25px;
            height: 25px;
            border-radius: 50px;
            margin-right: .5rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          p {
            font-size: 1rem;
            transition: .2s ease-in-out;
          }
          &:hover {
            background: $light;
          }
        }
        .rs-dropdown-menu {
          right: unset;
          left: -19.2rem;
          top: 0;
          z-index: 999;
          width: 300px;
          padding: 0;
          @media(max-width: 530px) {
            left: -16rem;
            top: 2.5rem;
          }
          @media(max-width: 480px) {
            left: -10rem;
          }
          @media(max-width: 380px) {
            left: -6.5rem;
          }
          .rs-dropdown-item-submenu {
            .rs-dropdown-item-content {
              padding: 0;
              .rs-dropdown-menu-toggle {
                span {
                  .notifications-body  {
                    background: $white;
                    box-shadow: 0 0 .5rem rgba(0,0,0,.1);
                    width: 300px;
                    position: relative;
                    padding: 0;
                    border-radius: 1rem;
                    .notifications-title {
                      width: 100%;
                      height: 50px;
                      font-family: "is_bold", sans-serif;
                      font-size: 1.2rem;
                      background: $white;
                      border-radius: .5rem .5rem 0 0;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      padding: .5rem 1rem 1rem 1rem;
                    }
                    .notifications-content {
                      width: 100%;
                      height: 400px;
                      overflow-y: auto;
                      padding: .5rem;
                      border-radius: 1rem;
                      background: $white;
                      margin-top: -10px;
                      display: flex;
                      margin-left: 0;
                      list-style: none;
                      flex-direction: column;
                      &::-webkit-scrollbar  {
                        width: .2rem;
                        border-radius: 50px;
                      }
                      &::-webkit-scrollbar-thumb {
                        box-shadow: inset 0 0 6px $dark;
                        border-radius: 50px;
                      }
                      &-item {
                        margin-bottom: 0.5rem;
                        background: $light;
                        border: .1rem solid $light;
                        padding: .5rem;
                        border-radius: 0.5rem;
                        transition: .2s ease-in-out;
                        a {
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: flex-start;
                          text-decoration: none;
                          color: $dark;
                          p {
                            font-size: .9rem;
                            line-height: .9rem;
                            padding-left: 0;
                          }
                          span {
                            width: unset;
                            height: unset;
                            font-size: .8rem;
                            font-family: "is_medium", sans-serif;
                            line-height: .8rem;
                            padding: .1rem .1rem .3rem .1rem;
                            background: rgba(0,0,0,0);
                          }
                        }
                        &:first-child {
                          margin-top: 1rem;
                        }
                        &:last-child {
                          margin-bottom: 0;
                        }
                        &:hover {
                          background: $light-gray;
                          a {
                            p {
                              padding-left: 0 !important;
                              margin-left: 0 !important;
                              translate: unset !important;
                            }
                          }
                        }
                      }
                      &-empty {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &-content {
    padding: 1rem 0;
  }
}

.drawer-particles {
  position: absolute;
  left: -50px;
  right: 0;
  top: 0;
  bottom: 0;
}
