@import "../../assets/theme/vars";

.diary {
  &-slider {
    .splide {
      position: relative;
      margin-bottom: 2rem;

      &__arrows {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        z-index: 99;
        display: flex;
      }

      &__arrow {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background: rgba(0, 0, 0, 0);
        border: .1rem solid $white;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: .25s ease-in-out;

        &:hover {
          background: rgba(0, 0, 0, .5);
        }

        &--prev {
          margin-right: 1rem;
          transform: rotate(180deg);

          svg {
            width: 20px;
            height: 20px;
            fill: $white;
          }
        }

        &--next {
          svg {
            width: 20px;
            height: 20px;
            fill: $white;
          }
        }
      }

      &__track {}

      &__list {}

      &__slide {
        width: 100%;
        height: 100%;
        border-radius: 1rem;

        a {
          display: flex;

          img {
            width: 100%;
            height: auto;
            border-radius: 1rem;
          }
        }
      }

      &__pagination {
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0;
        pointer-events: none;
        position: absolute;
        z-index: 10;
        top: 0;
        bottom: 0;
        right: 1rem;

        li {
          margin-bottom: .5rem;

          &:last-child {
            margin-bottom: 0;
          }

          button {
            width: 10px;
            height: 12px;
            border-radius: 100%;
            background: rgba(255, 255, 255, .8);
            box-shadow: 0 0 .2rem rgba(0, 0, 0, .2);

            &.is-active {
              background: $gray;
            }
          }
        }
      }

      &:hover {
        .splide__arrow {
          opacity: 1 !important;
        }
      }
    }
  }
}

a {
  cursor: pointer;
}
