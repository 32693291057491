.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

a {
  &:visited {
    text-decoration: none;
  }
}

p {
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.container-gallery {
  .slider-container {
    .slider {
      height: 350px !important;
    }
  }
}

.form-group {
  margin-bottom: 1.25rem;
  .box_input_icon {
    position: relative;
    display: block;
    .form-control {
      padding-left: 37px;
    }
    .ico_input {
      position: absolute;
      left: 12px;
      top: calc(50% - 11px);
      img {
        height: 18px;
      }
    }
  }
  .form-control {
    border-radius: .7rem;
    height: 45px;
    transition: .3s ease-in-out;
    &:focus {
      color: $black;
      outline: 0;
      border: 0;
      background: $light;
      box-shadow: none;
    }
    &:hover {
      background: $light;
    }
  }
}

/*plugin react-slideshow*/
.react-slideshow-container .default-nav {
  display: none;
}

/*  SLIDESHOW plugin */
.slider-control-centerright, .slider-control-centerleft {
  button {
    background: $white !important;
    color: $dark !important;
    font-size: 1.5rem !important;
    transition: .2s ease-in-out;
    &:hover {
      background: $dark !important;
      color: $white !important;
    }
  }
}

