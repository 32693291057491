@import "../../assets/theme/vars";

.form-dashboard-group-upload {
  background: #efefef;
}

.dashboard-container-page .form-dashboard-group input[type="date"] {
  padding: .375rem .75rem;
}

.alt40{
  .form-control {
    height: 40px;
  }
}

.wrapper_label_radio_reto {
  width: 100%;
  min-height: unset !important;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  .box_label_radio_answ {
    position: relative;
    width: max-content;
    min-width: 200px;
    min-height: 40px;
    padding-left: 0 !important;
    display: flex;
    align-items: center;
    .form-check-input {
      width: 20px;
      height: 20px;
      float: none;
      min-width: 1em;
      position: absolute;
      top: -8px;
      bottom: 0;
      margin: auto 0;
      left: 1rem;
      &:checked {
        background-color: $dark;
        border-color: $white;
        box-shadow: none;
        & ~ .label_radio_answ {
          border-color: $dark;
          background: $dark;
          color: $white;
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
        }
      }
    }
    .label_radio_answ {
      background: $white;
      border-radius: 0.5rem;
      padding-left: 2.5rem;
      padding-top: .45rem;
      border: 1px solid $lighter-gray;
      text-align: left;
      width: 100%;
      min-height: 40px;
      cursor: pointer;
      word-wrap: break-word;
    }
  }
}

@media screen and (max-width: 440px) {
  .wrapper_label_radio_reto {
    flex-direction: column;
    .box_label_radio_answ {
      width: 100%;
    }
  }
}
