// FONTS SYSTEMS ---

@font-face {
  font-family: "is_light";
  src: url(../fonts/Avenir-Light.woff) format("woff");
}
@font-face {
  font-family: "is_light_italic";
  src: url(../fonts/Avenir-LightOblique.woff) format("woff");
}
@font-face {
  font-family: "is_book";
  src: url(../fonts/Avenir-Book.woff) format("woff");
}
@font-face {
  font-family: "is_book_italic";
  src: url(../fonts/Avenir-BookOblique.woff) format("woff");
}
@font-face {
  font-family: "is_medium";
  src: url(../fonts/Avenir-Medium.woff) format("woff");
}
@font-face {
  font-family: "is_medium_italic";
  src: url(../fonts/Avenir-MediumOblique.woff) format("woff");
}
@font-face {
  font-family: "is_bold";
  src: url(../fonts/Avenir-Heavy.woff) format("woff");
}
@font-face {
  font-family: "is_bold_italic";
  src: url(../fonts/Avenir-HeavyOblique.woff) format("woff");
}
@font-face {
  font-family: "is_black";
  src: url(../fonts/Avenir-Black.woff) format("woff");
}
@font-face {
  font-family: "is_black_italic";
  src: url(../fonts/Avenir-BlackOblique.woff) format("woff");
}

@mixin scrollbar_vertical {
  &::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c9c9c9;
    border-radius: 20px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #8a8787;
  }
}

@mixin scrollbar_horizontal {
    /* height */
  &::-webkit-scrollbar {
    height: 8px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dddddd;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
  }
}

@mixin bggrey {
  background: rgba(255, 255, 255, 0.99);
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0.99) 0%,
    rgba(242, 242, 242, 0.99) 6%,
    rgba(242, 242, 242, 0.99) 94%,
    rgba(255, 255, 255, 0.99) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0.99)),
    color-stop(6%, rgba(242, 242, 242, 0.99)),
    color-stop(94%, rgba(242, 242, 242, 0.99)),
    color-stop(100%, rgba(255, 255, 255, 0.99))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0.99) 0%,
    rgba(242, 242, 242, 0.99) 6%,
    rgba(242, 242, 242, 0.99) 94%,
    rgba(255, 255, 255, 0.99) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0.99) 0%,
    rgba(242, 242, 242, 0.99) 6%,
    rgba(242, 242, 242, 0.99) 94%,
    rgba(255, 255, 255, 0.99) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0.99) 0%,
    rgba(242, 242, 242, 0.99) 6%,
    rgba(242, 242, 242, 0.99) 94%,
    rgba(255, 255, 255, 0.99) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.99) 0%,
    rgba(242, 242, 242, 0.99) 6%,
    rgba(242, 242, 242, 0.99) 94%,
    rgba(255, 255, 255, 0.99) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
}

// COLOR SYSTEMS ---
$bg_grey: #f2f2f2;
$white_text: #fefefe;
$border_radius: 9px;
$c_pending: #ffc907;
$c_success: #47c542;

$black: #000;
$dark: #3C3C3C;
$gray: #6F6F6F;
$light-gray: #E8E8E8;
$lighter-gray: #EDEDED;
$light: #fefefe;
$yellow: #FFE100;
$skyblue: #00D4FE;
$blue: #0393D6;
$darkblue: #005EFD;
$purple: #CF4592;
$darkpurple: #B6398F;
$red: #FF002A;
$green: #00AD42;
$pink: #EEA4B3;
$white: #ffffff;
$orange: #FF7400;
$beige: #d2c6a1;
$light-beige: #f4eed3;
$whine: #9f1b18;

$gradient-beige: linear-gradient($beige,$light-beige);
$gradient-orange: linear-gradient($orange,$yellow);
$gradient-green: linear-gradient($green,$skyblue);
$gradient-red: linear-gradient($red,$orange);
$gradient-blue: linear-gradient($skyblue, #870EAB);
$gradient-purple: linear-gradient($purple,$darkpurple);
$gradient-pride: linear-gradient(to bottom,$yellow, $pink,$skyblue,$green);
$gradient-black: linear-gradient($black, $dark, $gray);

$desktop: 1200px;
$tablet: 992px;
$mobile: 768px;
$mobileSM: 480px
