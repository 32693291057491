@import "../../assets/theme/vars";

.prideWorld-container {
  margin-bottom: 4rem;
  position: relative;
  a {text-decoration: none; }
  &-header {
    display: flex;
  }
  .splide {
    &__arrow {
      width: 35px;
      height: 35px;
      background: $white;
      border-radius: 50px;
      &:disabled {
        opacity: 0.5;
      }
      &--prev {
        margin-right: 0.25rem;
        transform: rotate(180deg);
        svg {
          width: 15px;
          height: 15px;
        }
      }
      &--next {
        svg {
          width: 15px;
          height: 15px;
          margin-bottom: 5px;
        }
      }
    }
    &__arrows {
      width: max-content;
      position: absolute;
      top: -40px;
      right: -6px;
      // fill: #999999;
    }
    &__slide {
      border-radius: 1.5rem;
      padding: 1rem 0;
      .card {
        &-worldpride {
          width: 220px;
          height: 200px;
          overflow: hidden;
          display: flex;
          text-decoration: none;
          border-radius: 1.5rem;
          margin: 0 auto;
          padding: 0 0.5rem;
          transition: 0.2s ease-in-out;
          &-content {
            overflow: hidden;
            &::before {
              content: "";
              background: rgb(0, 0, 0);
              background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.4990589985994398) 0%,
                rgba(0, 0, 0, 0) 62%
              );
              position: absolute;
              border-radius: 1rem;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 1;
            }
            width: 220px;
            height: 200px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1.5rem;
            .overlay-img {
              width: 100%;
              background: $white;
              overflow: hidden;
              position: absolute;
              img {
                border-radius: 1rem;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                width: 100%;
                height: 200px;
                object-fit: cover;
              }
            }
            .info {
              position: absolute;
              bottom: 0.7rem;
              left: 1rem;
              z-index: 2;

              h5 {
                color: $white;
                font-family: "is_bold", sans-serif;
                font-size: 1rem;
                text-align: left;
                text-transform: capitalize;
              }
              p {
                color: $white;
                font-family: "is_book", sans-serif;
                font-size: 0.9rem;
                text-transform: capitalize;
              }
            }
          }
          .bg-gradient-dark {
            width: 100%;
            height: 100%;
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0) 10%,
              rgba(0, 0, 0, 0.9)
            );
            border-radius: 1.5rem;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            flex-direction: column;
            position: absolute;
            text-align: left;
            padding: 1rem;
            transition: 0.2s ease-in-out;
          }
        }
      }
    }
  }
}

.card-worldpride:hover .card-worldpride-content img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
}
// .card-worldpride:hover {
//   background: #fff;
//   box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.50);
//   -webkit-transition: all 0.2s ease-in;
//   -moz-transition: all 0.2s ease-in;
//   -ms-transition: all 0.2s ease-in;
//   -o-transition: all 0.2s ease-in;
//   transition: all 0.2s ease-in;
//   margin-top: 50px;
// }
