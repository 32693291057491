@import "../../assets/theme/vars";

.register-services {
  &-container {}
  &-aside {
    width: 100%;
    background: $white;
    height: max-content;
    padding: 2rem;
    border-radius: 1rem;
    .icon {
      width: 50px;
      height: 50px;
      margin: 0 auto .5rem auto;
      display: flex;
      border-radius: 1rem;
      justify-content: center;
      align-items: center;
      border: .1rem solid $green;
      svg {
        font-size: 5rem;
        color: $green;
      }
    }
    .paragraph {
      width: 100%;
      height: max-content;
      padding: 1rem 1rem;
      text-align: left;
      background: linear-gradient(to bottom, rgba(0,173,66,.2), rgba(0,212,254,.2));
      border-radius: 1rem;
      p { font-size: 1rem; }
      span { font-family: "is_bold", sans-serif;}
    }
    p {
      width: 100%;
      text-align: center;
      font-size: .9rem;
      padding: 1rem 0;
    }
  }
  &-content {
    &-steps {
      width: 100%;
      display: flex;
      justify-content: space-around;
      background: $gradient-green;
      padding: 2rem;
      border-radius: 1.5rem;
      transition: .3s ease-in-out;
      .steps-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        ul {
          list-style: none;
          li {
            color: $white;
            margin: 0.5rem;
            input[type='radio'] {
              --active: $yellow;
              --active-inner: $yellow;
              --focus: 2px rgba(250, 205, 215, .3);
              --border: #BBC1E1;
              --border-hover: $yellow;
              --background: #fff;
              --disabled: #F6F8FF;
              --disabled-inner: #E1E6F9;
              -webkit-appearance: none;
              -moz-appearance: none;
              border-radius: 50%;
              height: 30px;
              outline: none;
              display: inline-block;
              vertical-align: top;
              position: relative;
              margin: 0;
              cursor: pointer;
              border: 2px solid $white;
              --o: 1;
              background: rgba(0,0,0,0);
              transition: background .3s, border-color .3s, box-shadow .2s;
              &:after {
                content: '';
                display: block;
                left: 0;
                top: 0;
                background: $white;
                position: absolute;
                opacity: 0;
                transform: scale(var(--s, .7));
                width: 26px;
                height: 26px;
                background: $white;
                border-radius: 50%;
                transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
              }
              &:checked:after {
                border-color: var(--active);
                background: #F7C246 !important;
                --d-o: .3s;
                --d-t: .6s;
                --s: 1 !important;
                --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
              }
              &:disabled {
                --b: var(--disabled);
                cursor: not-allowed;
                opacity: .9;
                &:checked {
                  --b: var(--disabled-inner);
                  --bc: var(--border);
                }
                & + label {
                  cursor: not-allowed;
                }
              }
              &:hover {
                &:not(:checked) {
                  &:not(:disabled) {
                    --bc: var(--border-hover);
                  }
                }
              }
              &:focus {
                box-shadow: 0 0 0 var(--focus);
              }
              &:not(.switch) {
                width: 30px;
                &:after {
                  opacity: var(--o, 0);
                }
                &:checked {
                  --o: 1;
                }
              }
              & + label {
                font-size: 1rem;
                line-height: 1.9rem;
                display: inline-block;
                vertical-align: top;
                cursor: pointer;
                margin-left: .5rem;
              }
            }
          }
        }
      }
      .steps-circle {
        width: 150px;
        height: auto;
        .rs-progress {}
        .rs-progress-circle-info {
          color: $white;
          font-size: 1.5rem;
          line-height: 2.7rem;
          font-family: "is_book", sans-serif;
        }
        .rs-progress-circle {}
        .rs-progress-svg {
          path {
            stroke: $white;
          }
        }
      }
    }
    &-form {
      width: 100%;
      background: $white;
      padding: 2rem;
      border-radius: 1rem;
      margin-top: 2rem;
    }
  }
}

.badge {
  &-dashed {
    width: max-content;
    margin: 0;
    border: .1rem dashed $gray;
    border-radius: .5rem;
    color: $gray;
    padding: .5rem 1rem;
    line-height: 1rem;
    font-size: .9rem;
    text-align: center;
    margin-top: .5rem;
    &.green {
      border-color: $green;
      color: $green;
    }
  }
}

.modal-img {
  border-radius: .1rem;
  img {
    border-radius: .5rem;
    padding: .2rem;
    background: $white;
  }
}

.form-checkout {
  background: $white;
  padding: 1rem;
  border-radius: 1rem;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    img {
      width: 160px;
      height: auto;
    }
  }
  .paragraph {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 0 2rem 0;
  }
  .red-pay {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      text-transform: uppercase;
      font-size: .9rem;
      text-align: center;
    }
    img {
      width: 120px;
      height: auto;
    }
  }
}

.badge-dashed {
  &-dark {
    width: max-content;
    margin: 0 auto;
    color: $dark;
    font-family: "is_medium", sans-serif;
    border-color: $gray;
  }
}

.card-credit {
  width: 100%;
  height: 250px;
  margin: 1rem 0;
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(to right, rgba(247,194,70,1), rgba(183,75,147,1), rgba(70,175,224,1), rgba(75,175,173,1), rgba(247,194,70,1));
  label {
    width: 100%;
    color: $white;
    font-family: "is_bold", sans-serif;
    font-size: 1.2rem;
  }
  &-number {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: $white;
      margin-right: 1rem;
      font-family: "is_bold", sans-serif;
      font-size: 1.4rem;
      margin-top: 0;
    }
  }
  &-date {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 1.2rem;
      color: $white;
    }
    span {
      width: max-content;
      color: $dark;
      font-size: 1.2rem;
      font-family: "is_bold", sans-serif;
      background: $white;
      padding: .3rem .5rem;
      border-radius: .5rem;
    }
  }
}

.form-register-services {
  .group {
    width: 100%;
    display: flex;
  }
  .group-columns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .columns {
      width: 100%;
      &.mr {
        margin-right: .5rem;
      }
    }
  }
}

.message-finish {
  h2 {
    text-align: center;
  }
  p {
    text-align: center;
  }
  &-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    margin-top: 2rem;
    .btn-back {
      margin-top: 1rem;
    }
  }
}
