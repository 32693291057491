@import "../../assets/theme/vars";

.wrapper_control {
  @include bggrey;
  min-height: calc(100vh - 4rem);
}

.input_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .drop_color {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-right: .4rem;
  }
  .form-group{
    width: 100%;
    height: 100px;
    margin-bottom: 0;
  }
}

input[disabled],
textarea[disabled],
input:disabled {
  opacity: 0.4 !important;
  cursor: not-allowed;
}

.ant-form {
  &.form-pride {
    .ant-btn {
      &.ant-btn-primary.btn-submit {
        min-width: 150px;
        min-height: 50px;
         display: flex;
        justify-content: center;
        align-items: center;
        background: $dark;
        border: .1rem solid $dark;
        color: $white;
        svg {
          margin-left: .5rem;
        }
      }
    }
    input[type=file] {
      display: none;
    }
    .form-item-file {
      width: 100%;
      border: .1rem dashed $lighter-gray;
      border-radius: .5rem;
      background: $white;
      padding: 1rem;
      min-height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .btn-file {
        width: max-content;
        border: .1rem solid $lighter-gray;
        border-radius: .5rem;
        padding: .5rem 1rem;
        background: $dark;
        color: $white;
        transition: .2s ease-in-out;
        &:hover {
          background: $dark;
          cursor: pointer;
          color: $white;
        }
      }
      .btn-trash {
        background: $red;
        border: .1rem solid $red;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        color: $white;
        height: 50px;
        transition: .2s ease-in-out;
        &:hover {
          background: $red;
          color: $white;
          cursor: pointer;
          border: .1rem solid $red;
          transform: scale(1.05);
        }
      }
      p {
        margin-top: .5rem;
        font-size: .8rem;
      }
    }
  }
}
